import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';
import { TaskMemberInvitesListTasks } from '../../../../../../../../taskMemberInvites/components/lists/TaskMemberInvitesList';
import { DashboardActiveTasksListItemsListActiveTask } from './components/DashboardActiveTasksListItemsList';
import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';
import {
  UserBlocked,
  UserID,
  UserNanoID
} from '../../../../../../../usersTypes';

export enum DashboardActiveTasksListTabNames {
  ACTIVE_TASKS = 'ACTIVE_TASKS',
  INVITATIONS = 'INVITATIONS',
  QUALITY_CONTROL = 'QUALITY_CONTROL'
}

export enum DashboardActiveTasksListItemScope {
  TASK = 'task',
  PROJECT = 'project'
}

export interface DashboardActiveTasksListUser {
  id: UserID;
  nanoId: UserNanoID;
  blocked: UserBlocked;
}

export type DashboardActiveTasksListActiveTasks =
  DashboardActiveTasksListItemsListActiveTask[];

export type DashboardActiveTasksListInvitations = TaskMemberInvitesListTasks;

export interface DashboardActiveTasksListSelectedItem {
  nanoId: TaskNanoID | ProjectNanoID;
  scope: DashboardActiveTasksListItemScope;
}
