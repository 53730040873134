import {
  UserCurrentTeamNanoID,
  UserID,
  UserNanoID
} from '../../../../../../../usersTypes';

export interface DashboardTasksItemUser {
  id: UserID;
  nanoId: UserNanoID;
  currentTeam?: {
    nanoId: UserCurrentTeamNanoID;
  };
}

export enum DashboardTasksItemType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}
