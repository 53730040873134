import {
  DeepMap,
  DeepPartial,
  FieldArrayWithId,
  FieldError,
  UseFormRegister
} from 'react-hook-form';

import find from 'lodash/find';
import floor from 'lodash/floor';

import { IsLoading } from '../../../../../types';
import { TaskTrackTimeFields } from '../../../tasksTypes';
import {
  MultiTrackTaskTimeFormData,
  MultiTrackTaskTimeFormTask
} from './MultiTrackTaskTimeForm.types';

import { useMultiTrackTaskTimeFormValidationRule } from './hooks/useMultiTrackTaskTimeForm';

import { MultiTrackTaskTimeFormField } from './components/MultiTrackTaskTimeFormField';

import { Form } from '../../../../../helpers/Form';
import { Translate } from '../../../../../helpers/Translate';
import { PeriodHelper } from '../../../../../helpers/PeriodHelper';

import { tasksKeys } from '../../../../../locales/keys';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

interface MultiTrackTaskTimeFormProps {
  tasks: MultiTrackTaskTimeFormTask[];
  todayTrackedTime: number;
  form: string;
  isLoading?: IsLoading;
  fields: FieldArrayWithId<MultiTrackTaskTimeFormData, 'tasks', 'id'>[];
  register: UseFormRegister<MultiTrackTaskTimeFormData>;
  errors: DeepMap<DeepPartial<MultiTrackTaskTimeFormData>, FieldError>;
}

function MultiTrackTaskTimeForm({
  tasks,
  todayTrackedTime,
  form,
  isLoading,
  errors,
  fields,
  register
}: MultiTrackTaskTimeFormProps) {
  const { registerHoursValidationRule, registerMinutesValidationRule } =
    useMultiTrackTaskTimeFormValidationRule();

  return (
    <Form id={form}>
      <div className="p-4">
        <div className="mb-4 text-center">
          <Translate id={tasksKeys.trackedToday} />

          <span className="font-bold text-md bg-gray-100 dark:bg-gray-700 px-1 rounded">
            <PeriodHelper
              hours={floor(todayTrackedTime / SECONDS_IN_HOUR)}
              minutes={floor(
                (todayTrackedTime % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE
              )}
              nullable
            />
          </span>
        </div>
        <ul className="text-xs">
          {fields.map((field, index) => (
            <MultiTrackTaskTimeFormField
              key={field.id}
              hoursValidationError={errors?.tasks?.[index]?.hours?.message}
              minutesValidationError={errors?.tasks?.[index]?.minutes?.message}
              registerBody={register(
                `tasks.${index}.${TaskTrackTimeFields.BODY}`
              )}
              registerHours={register(
                `tasks.${index}.${TaskTrackTimeFields.HOURS}`,
                registerHoursValidationRule
              )}
              registerMinutes={register(
                `tasks.${index}.${TaskTrackTimeFields.MINUTES}`,
                registerMinutesValidationRule
              )}
              task={find(tasks, { id: field.taskId })}
              isLoading={isLoading}
            />
          ))}
        </ul>
      </div>
    </Form>
  );
}

export default MultiTrackTaskTimeForm;
