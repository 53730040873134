import React, { useMemo } from 'react';

import differenceInDays from 'date-fns/differenceInDays';

import groupBy from 'lodash/groupBy';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';

import {
  InvoiceID,
  InvoiceNanoID,
  InvoiceUUID,
  InvoiceStatus,
  InvoiceAmount,
  InvoiceCreatedAt,
  InvoiceStatuses
} from '../../../../../../../../../../invoices/invoicesTypes';
import {
  DashboardFinanceDebtCategoryIndex,
  DashboardFinanceDebtCategoryName,
  DashboardFinanceDebtItemGroupedByCategoryType
} from '../../DashboardFinanceDept.types';

import { DashboardFinanceDebtItem } from '../DashboardFinanceDebtItem';

import { NextPureLinkHelper } from '../../../../../../../../../../../helpers/links/NextPureLinkHelper';
import { LoadingSkeleton } from '../../../../../../../../../../../helpers/LoadingSkeleton';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';

import { invoicesKeys } from '../../../../../../../../../../../locales/keys';

interface DashboardFinanceDebtAmountItemType {
  id: InvoiceID;
  nanoId: InvoiceNanoID;
  uuid: InvoiceUUID;
  status: InvoiceStatus;
  amount: InvoiceAmount;
  createdAt: InvoiceCreatedAt;
}

interface DashboardFinanceDebtAmountProps {
  items: DashboardFinanceDebtAmountItemType[];
  loaded: boolean;
  href: string;
}

function DashboardFinanceDebtAmount({
  items,
  loaded,
  href
}: DashboardFinanceDebtAmountProps) {
  const itemsAmount = useMemo<number>(() => sumBy(items, 'amount'), [items]);

  const itemsGroupedByCategory = useMemo<
    DashboardFinanceDebtItemGroupedByCategoryType[]
  >(() => {
    const groupedByCategory = groupBy(items, (item) => {
      const days = differenceInDays(Date.now(), new Date(item.createdAt));

      if (item.status === InvoiceStatuses.UNCOLLECTIBLE) {
        return DashboardFinanceDebtCategoryName.UNCOLLECTIBLE;
      }

      if (days < 7) {
        return DashboardFinanceDebtCategoryName.LESS_WEEK;
      }

      if (days < 14) {
        return DashboardFinanceDebtCategoryName.LESS_TWO_WEEK;
      }

      if (days < 30) {
        return DashboardFinanceDebtCategoryName.LESS_MONTH;
      }

      if (days < 60) {
        return DashboardFinanceDebtCategoryName.LESS_TWO_MONTH;
      }

      if (days >= 60) {
        return DashboardFinanceDebtCategoryName.MORE_TWO_MONTH;
      }

      return DashboardFinanceDebtCategoryName.LESS_WEEK;
    });

    return sortBy(
      map(groupedByCategory, (group, groupName) => ({
        categoryName: groupName as DashboardFinanceDebtCategoryName,
        amount: sumBy(group, 'amount'),
        index: DashboardFinanceDebtCategoryIndex[groupName]
      })),
      'index'
    );
  }, [items]);

  const allMultiplier = useMemo<number>(() => {
    const maxGroupAmount = maxBy(itemsGroupedByCategory, 'amount');
    return maxGroupAmount?.amount / itemsAmount;
  }, [itemsGroupedByCategory, itemsAmount]);

  return (
    <div className="grow flex flex-col-reverse 2xl:flex-row justify-between gap-2 2xl:gap-6">
      <LoadingSkeleton loaded={loaded} addClass="w-full" count={3}>
        <div className="flex 2xl:flex-col justify-between gap-4">
          <div>
            <div className="text-3xl font-medium">
              <MoneyHelper value={itemsAmount} />
            </div>
          </div>

          <div className="self-end 2xl:self-start">
            <NextPureLinkHelper
              className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-yellow-500 hover:bg-yellow-600 shadow-sm hover:shadow-md"
              i18nText={invoicesKeys.viewOpenInvoices}
              href={href}
            />
          </div>
        </div>

        <div className="flex-1 max-w-96">
          <div className="grid grid-cols-[auto_auto_1fr] gap-y-0.5 gap-x-2">
            {itemsGroupedByCategory.map(({ amount, categoryName }) => {
              const groupItemsMultiplier = amount / itemsAmount;

              return (
                <DashboardFinanceDebtItem
                  categoryName={categoryName}
                  amount={amount}
                  percent={round(groupItemsMultiplier * 100)}
                  width={round((groupItemsMultiplier / allMultiplier) * 100)}
                  key={categoryName}
                />
              );
            })}
          </div>
        </div>
      </LoadingSkeleton>
    </div>
  );
}

export default DashboardFinanceDebtAmount;
