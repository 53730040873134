import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cl from 'classnames';
import sumBy from 'lodash/sumBy';
import size from 'lodash/size';

import {
  DashboardTasksItemType,
  DashboardTasksItemUser
} from './DashboardTasksItem.types';
import { TaskStatuses } from '../../../../../../../../tasks/tasksTypes';
import {
  ShowDashboardTasksByStatusType,
  dashboardTasksByStatusCacheKey
} from '../../DashboardTasksByStatus.types';

import {
  FETCH_DASHBOARD_TASKS_QUERY,
  FetchDashboardTasksQueryResponse
} from '../../../../../../../../tasks/queries/fetchDashboardTasks.query';
import {
  FETCH_TASK_REPORTS_QUERY,
  FetchTaskReportsQueryResponse
} from '../../../../../../../../taskReports/queries/taskReports.query';

import { useReactQueryState } from '../../../../../../../../common/hooks/base/reactQuery/useReactQueryState';
import { usePaginatedTasks } from '../../../../../../../../tasks/hooks/usePaginatedTasks';
import { useTaskReports } from '../../../../../../../../taskReports/hooks/useTaskReports';
import { useTasksWithItemsAndPrice } from '../../../../../../../../tasks/hooks/useTasksWithItemsAndPrice';

import { DashboardTasksItemTaskReportsColumn } from '../DashboardTasksItemTaskReportsColumn';
import { DashboardTasksItemSelectType } from '../DashboardTasksItemSelectType';

import { NextLinkHelper } from '../../../../../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { MoneyHelper } from '../../../../../../../../../helpers/MoneyHelper';
import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';

import { PercentByPeriod, percentByPeriod } from './utils/percentByPeriod';
import {
  getPeriodDate,
  getTaskReportsCountByPeriodsDate,
  getTaskReportsEndDate,
  getTaskReportsStartDate
} from './utils/getTaskReportsCountByPeriodsDate';
import { dateFnsConvert } from '../../../../../../../../../utils/dateFnsConvert';

import { UsersPermissions } from '../../../../../../../usersConstants';
import { TeamPath } from '../../../../../../../../teams/TeamPath';
import { TaskPath } from '../../../../../../../../tasks/TaskPath';
import { TaskCache } from '../../../../../../../../tasks/TaskCache';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { statusesKeys } from '../../../../../../../../../locales/keys/models/statuses';

interface DashboardTasksItemProps {
  status: TaskStatuses;
  user: DashboardTasksItemUser;
  selfProfile: boolean;
}

const i18nTitleTasksStatuses = {
  [TaskStatuses.IN_PROGRESS]: statusesKeys.types.implementation,
  [TaskStatuses.QUALITY_CONTROL]: statusesKeys.types.qa,
  [TaskStatuses.READY_FOR_ACCEPTANCE]: statusesKeys.types.acceptance,
  [TaskStatuses.PENDING_MORE_INFO]: statusesKeys.types.incompleteSpecification,
  [TaskStatuses.PAUSED]: statusesKeys.types.paused,
  [TaskStatuses.SETTING_TASK]: statusesKeys.types.definition
};

const TitleTasksPointClassName = {
  [TaskStatuses.IN_PROGRESS]: 'w-2 h-2 rounded-full bg-blue-500',
  [TaskStatuses.QUALITY_CONTROL]: 'w-2 h-2 rounded-full bg-purple-500',
  [TaskStatuses.READY_FOR_ACCEPTANCE]: 'w-2 h-2 rounded-full bg-green-500',
  [TaskStatuses.PENDING_MORE_INFO]: 'w-2 h-2 rounded-full bg-yellow-500',
  [TaskStatuses.PAUSED]: 'w-2 h-2 rounded-full bg-pink-500',
  [TaskStatuses.SETTING_TASK]: 'w-2 h-2 rounded-full bg-gray-500'
};

function trendingIcon(trending: number) {
  if (trending > 0) {
    return IconsEnum.TRENDING_UP_SOLID;
  }

  if (trending < 0) {
    return IconsEnum.TRENDING_DOWN_SOLID;
  }

  return IconsEnum.ARROW_NARROW_RIGHT_SOLID;
}

function DashboardTasksItem({
  status,
  user,
  selfProfile
}: DashboardTasksItemProps) {
  const { value, setValue } =
    useReactQueryState<ShowDashboardTasksByStatusType>(
      dashboardTasksByStatusCacheKey
    );

  const [type, setType] = useState<DashboardTasksItemType>(
    DashboardTasksItemType.DAY
  );

  const { tasks, tasksTotalCount, tasksFetched, tasksError } =
    useTasksWithItemsAndPrice<FetchDashboardTasksQueryResponse>({
      query: FETCH_DASHBOARD_TASKS_QUERY,
      cacheKey: TaskCache.indexByStatusCacheKey(status),
      initialFilters: {
        performerIds: [user.id],
        status: { eq: status }
      },
      initialLimit: 100,
      options: {
        withoutPrefetch: true
      }
    });

  const {
    taskReports,
    taskReportsFetched,
    taskReportsError,
    changeTaskReportsFilters
  } = useTaskReports<FetchTaskReportsQueryResponse>({
    query: FETCH_TASK_REPORTS_QUERY,
    cacheKey: TaskCache.reportsByStatusCacheKey(status),
    initialFilters: {
      performerId: { eq: user.id },
      createdAt: {
        gte: dateFnsConvert.toDate(
          getTaskReportsStartDate(DashboardTasksItemType.DAY).toISOString()
        ),
        lt: dateFnsConvert.toDate(
          getTaskReportsEndDate(DashboardTasksItemType.DAY).toISOString()
        )
      },
      status: { eq: status }
    },
    initialLimit: 1000,
    options: {
      withoutPrefetch: true
    }
  });

  useEffect(() => {
    if (tasksFetched && taskReportsFetched) {
      const noEmptyBlock = tasksTotalCount + size(taskReports) > 0;
      !value && noEmptyBlock && setValue(noEmptyBlock);
    }
  }, [
    setValue,
    taskReports,
    taskReportsFetched,
    tasksFetched,
    tasksTotalCount,
    value
  ]);

  const { tasksTotalCount: burningTasksCount } =
    usePaginatedTasks<FetchDashboardTasksQueryResponse>({
      query: FETCH_DASHBOARD_TASKS_QUERY,
      cacheKey: TaskCache.totalCountCacheKey(),
      initialFilters: {
        performerIds: [user.id],
        status: { eq: status },
        burning: true
      },
      initialLimit: 1,
      options: {
        withoutPrefetch: true,
        enabled: status === TaskStatuses.IN_PROGRESS,
        enabledPlaceholder: status === TaskStatuses.IN_PROGRESS
      }
    });

  const handleChangeType = useCallback<(value: DashboardTasksItemType) => void>(
    (value) => {
      setType(value);
      changeTaskReportsFilters({
        createdAt: {
          gte: dateFnsConvert.toDate(
            getTaskReportsStartDate(value).toISOString()
          ),
          lt: dateFnsConvert.toDate(getTaskReportsEndDate(value).toISOString())
        }
      });
    },
    [changeTaskReportsFilters]
  );

  const periodsDate = useMemo<Date[]>(() => getPeriodDate(type), [type]);

  const taskReportsCountByPeriodsDate = useMemo<number[]>(
    () => getTaskReportsCountByPeriodsDate(periodsDate, taskReports, type),
    [periodsDate, taskReports, type]
  );

  const tasksPercentByPeriod = useMemo<PercentByPeriod>(
    () => percentByPeriod(tasksTotalCount, taskReportsCountByPeriodsDate),
    [tasksTotalCount, taskReportsCountByPeriodsDate]
  );

  const tasksTrending = useMemo<number>(
    () => tasksTotalCount - taskReportsCountByPeriodsDate[0],
    [taskReportsCountByPeriodsDate, tasksTotalCount]
  );

  const tasksTotalAmount = useMemo<number>(
    () => sumBy(tasks, 'price') || 0,
    [tasks]
  );

  return (
    <div className="rounded-lg bg-white hover:bg-gray-100 border-gray-200 dark:bg-gray-850 dark:hover:bg-gray-800 dark:border-gray-800 border p-4">
      <LoadingSkeleton loaded={tasksFetched && taskReportsFetched} count={3}>
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center gap-2">
            <div className={TitleTasksPointClassName[status]} />
            <div className="text-md dark:text-gray-300">
              <Translate id={i18nTitleTasksStatuses[status]} />
            </div>
          </div>

          <DashboardTasksItemSelectType
            defaultValue={type}
            onChange={handleChangeType}
          />
        </div>

        <NextLinkHelper
          href={
            selfProfile
              ? TaskPath.userTasksByStatus(user.nanoId, status)
              : TeamPath.companyTasksByStatus(user.currentTeam?.nanoId, status)
          }
        >
          <div className="grid grid-cols-2 h-20">
            <div className="flex flex-col">
              <div className="flex gap-2 items-baseline">
                <div className="text-5xl">{tasksTotalCount}</div>
                <div className="flex gap-1 items-center">
                  <Icon
                    icon={trendingIcon(tasksTrending)}
                    className={cl('h-5 w-5', {
                      'text-green-700': tasksTrending > 0,
                      'text-yellow-700': tasksTrending < 0,
                      'text-gray-500': tasksTrending === 0
                    })}
                  />

                  <div className="dark:text-gray-400">{tasksTrending}</div>
                </div>
              </div>

              <CheckPermissions
                action={
                  selfProfile
                    ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_BY_STATUS_TOTAL_AMOUNT
                    : UsersPermissions.READ_USER_DASHBOARD_TASKS_BY_STATUS_TOTAL_AMOUNT
                }
              >
                {tasksTotalCount > 0 && (
                  <div className="text-xs">
                    <MoneyHelper value={tasksTotalAmount} />
                  </div>
                )}
              </CheckPermissions>

              {burningTasksCount > 0 && status === TaskStatuses.IN_PROGRESS && (
                <div className="flex gap-1 items-center">
                  <div className="text-red-600">
                    <Icon icon={IconsEnum.FIRE_SOLID} className="h-4 w-4" />
                  </div>
                  <div className="text-xs text-gray-500">
                    {burningTasksCount}
                  </div>
                </div>
              )}
            </div>

            <div>
              <div className="flex flex-row-reverse items-end gap-1 md:gap-2 h-full">
                <DashboardTasksItemTaskReportsColumn
                  isCurrent
                  tasksCount={tasksTotalCount}
                  startDate={getTaskReportsEndDate(type)}
                  endDate={new Date()}
                  type={type}
                  height={tasksPercentByPeriod.currentDateTotalCountPercent}
                />

                {tasksPercentByPeriod.taskReportsCountPercentByPeriodsDate.map(
                  (percent, index) => (
                    <DashboardTasksItemTaskReportsColumn
                      tasksCount={taskReportsCountByPeriodsDate[index]}
                      startDate={periodsDate[index]}
                      endDate={
                        index === 0
                          ? getTaskReportsEndDate(type)
                          : periodsDate[index - 1]
                      }
                      type={type}
                      height={percent}
                      key={index}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </NextLinkHelper>

        <AlertMessage message={tasksError || taskReportsError} />
      </LoadingSkeleton>
    </div>
  );
}

export default DashboardTasksItem;
