import React, { useCallback, useState } from 'react';
import size from 'lodash/size';

import { I18nText } from '../../types';
import { ItemsListAccordionHelperItems } from './ItemsListAccordionHelper.types';
import { IconsEnum } from '../../assets/icons/types';

import { Icon } from '../Icon';
import { Translate } from '../Translate';

import { PureIconButtonHelper } from '../buttons/PureIconButtonHelper';

import { stringsKeys, words } from '../../locales/keys';

interface ItemsListAccordionHelperProps {
  items: ItemsListAccordionHelperItems;
  itemsListIsOpen?: boolean;
  i18nModelName: I18nText;
}

function ItemsListAccordionHelper({
  items,
  itemsListIsOpen,
  i18nModelName
}: ItemsListAccordionHelperProps) {
  const [isOpen, setIsOpen] = useState<boolean>(itemsListIsOpen);

  const toggleIsOpen = useCallback<() => void>(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  return (
    <div className="bg-opacity-30 bg-yellow-300 p-2 rounded-md text-sm">
      <div className="flex px-1">
        <div className="shrink-0">
          <Icon
            className="h-5 w-5 text-yellow-500 dark:text-yellow-400"
            icon={IconsEnum.EXCLAMATION_SOLID}
          />
        </div>
        <div className="ml-3">
          <p className="text-yellow-600 dark:text-yellow-200">
            <strong>
              <Translate id={words.caution} />
            </strong>{' '}
            <Translate id={stringsKeys.thisActionWillAffect} />{' '}
            <strong>{size(items)}</strong> <Translate id={i18nModelName} />
          </p>
        </div>
        <div className="ml-auto mt-auto pl-4">
          <PureIconButtonHelper
            icon={isOpen ? IconsEnum.CHEVRON_UP : IconsEnum.CHEVRON_DOWN_SOLID}
            className="space-x-1 flex flex-row-reverse items-center focus:ring-base focus:ring-offset-0 rounded px-1 whitespace-nowrap"
            i18nText={isOpen ? words.hideList : words.showList}
            iconClassName="h-4 w-4"
            onClick={toggleIsOpen}
          />
        </div>
      </div>
      {isOpen && (
        <ol
          className="bg-white dark:bg-gray-850 list-decimal list-inside mt-2 p-2 rounded-md"
          data-toggle-item="batch-task-list"
        >
          {items.map((item) => (
            <li key={item.id}>{item.fullName || item.name}</li>
          ))}
        </ol>
      )}
    </div>
  );
}

export default ItemsListAccordionHelper;
