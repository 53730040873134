import {
  TaskID,
  UpdateTrackTasksTimeGqlQuery,
  UpdateTrackTasksTimeCacheKeys,
  UpdateTrackTasksTimeGqlError,
  UpdateTrackTasksTimeGqlStatus
} from '../../tasksTypes';

import { MessageHours, MessageMinutes } from '../../../messages/messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface TrackTasksTimeOptions {
  query: UpdateTrackTasksTimeGqlQuery;
  cacheKeys: UpdateTrackTasksTimeCacheKeys;
}

interface TrackTasksTimeResponse {
  trackTasksTime: {
    status: UpdateTrackTasksTimeGqlStatus;
    errors: TrackTasksTimeError;
  };
}

interface TrackTasksTimeInput {
  trackedTime: {
    hours: MessageHours;
    minutes: MessageMinutes;
    taskId: TaskID;
  }[];
}

interface TrackTasksTimeError {
  fullMessages: UpdateTrackTasksTimeGqlError;
}

const action = 'trackTasksTime';

function useTrackTasksTime({ query, cacheKeys }: TrackTasksTimeOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    TrackTasksTimeInput,
    TrackTasksTimeResponse,
    TrackTasksTimeError,
    undefined
  >({ action, cacheKeys, query });

  return {
    trackTasksTimeData: updateQueryData,
    trackTasksTimeError: updateQueryError,
    trackTasksTimeLoading: updateQueryLoading,
    trackTasksTimeErrorMessage: updateQueryErrorMessage,
    trackTasksTime: updateQuery,
    trackTasksTimeReset: updateQueryReset
  };
}

export default useTrackTasksTime;
