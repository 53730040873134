import React from 'react';

import { UserID, UserNanoID } from '../../../../../../../usersTypes';

import { FETCH_TASKS_TOTAL_COUNT_QUERY } from '../../../../../../../../tasks/queries/fetchTasksTotalCount.query';

import { useTotalsQuery } from '../../../../../../../../common/hooks/base/reactQuery/useTotalsQuery';

import { DashboardStatisticItem } from '../DashboardStatisticItem';

import { TaskPath } from '../../../../../../../../tasks/TaskPath';
import { TaskCache } from '../../../../../../../../tasks/TaskCache';
import { profilesKeys } from '../../../../../../../../../locales/keys';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

type TasksResultsTotalCountQueryKey = 'tasks';

interface DashboardStatisticMyResultsProps {
  userId: UserID;
  userNanoId: UserNanoID;
  selfProfile: boolean;
}

function DashboardStatisticMyResults({
  userId,
  selfProfile,
  userNanoId
}: DashboardStatisticMyResultsProps) {
  const { data: tasksResultsData, isFetched } =
    useTotalsQuery<TasksResultsTotalCountQueryKey>({
      query: FETCH_TASKS_TOTAL_COUNT_QUERY,
      filters: {
        tasksFilters: {
          visibleResultsCount: { gt: 0 },
          performerIds: [userId]
        }
      },
      cacheKey: TaskCache.userResultsCountCacheKey(userId)
    });

  return (
    <DashboardStatisticItem
      i18nTitle={profilesKeys.myResults}
      icon={IconsEnum.ROCKET_OUTLINE}
      href={
        selfProfile ? TaskPath.myResults() : TaskPath.userResults(userNanoId)
      }
      loaded={isFetched}
      value={tasksResultsData?.tasks?.paginationInfo?.totalCount || 0}
    />
  );
}

export default DashboardStatisticMyResults;
