import max from 'lodash/max';
import round from 'lodash/round';
import sum from 'lodash/sum';

export type PercentByPeriod = {
  currentDateTotalCountPercent: number;
  taskReportsCountPercentByPeriodsDate?: number[];
};

function percentByPeriod(
  currentDateTotalCount: number,
  taskReportsCountByPeriodsDate: number[] = []
): PercentByPeriod {
  const allTasksCount =
    currentDateTotalCount + sum(taskReportsCountByPeriodsDate);

  const allTasksCountByPeriodsDate = [
    currentDateTotalCount,
    ...taskReportsCountByPeriodsDate
  ];

  if (allTasksCount === 0) {
    return {
      currentDateTotalCountPercent: 0,
      taskReportsCountPercentByPeriodsDate: taskReportsCountByPeriodsDate
    };
  }

  const allMultiplier = max(allTasksCountByPeriodsDate) / allTasksCount;

  return {
    currentDateTotalCountPercent: round(
      (currentDateTotalCount / allTasksCount / allMultiplier) * 100
    ),
    taskReportsCountPercentByPeriodsDate: taskReportsCountByPeriodsDate.map(
      (tasksCount) => round((tasksCount / allTasksCount / allMultiplier) * 100)
    )
  };
}

export default percentByPeriod;
