import React from 'react';
import map from 'lodash/map';

import { ClassName, I18nText, UUID } from '../../../../../types';
import {
  SmartContractShareIteration,
  SmartContractShareStatus,
  SmartContractShareStatuses,
  UpdateSmartContractSharesCacheKeys
} from '../../../smartContractSharesTypes';
import { TaskID } from '../../../../tasks/tasksTypes';
import { UserClient, UserID } from '../../../../users/usersTypes';

import { IconsEnum } from '../../../../../assets/icons/types';
import { CreateSmartContractShareFormView } from '../../forms/CreateSmartContractShareForm/CreateSmartContractShareForm.types';

import { useBatchCreateSmartContractSharesForm } from '../../forms/CreateSmartContractShareForm/hooks/useBatchCreateSmartContractSharesForm';
import { CreateSmartContractShareForm } from '../../forms/CreateSmartContractShareForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';

import { usersKeys, words } from '../../../../../locales/keys';

interface BatchCreateSmartContractSharesModalButtonProps {
  invitedUserUuids?: UUID[];
  iteration?: SmartContractShareIteration;
  tasks: { id: TaskID }[];
  status?: SmartContractShareStatus;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  i18nTitle?: I18nText;
  tooltipI18nText?: I18nText;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  defaultUsers?: {
    id: UserID;
    client?: UserClient;
  }[];
  disabled?: boolean;
  afterCreate?: () => void;
}

const CREATE_SMART_CONTRACT_SHARE_FORM = 'create-smart-contract-share-form';

function BatchCreateSmartContractSharesModalButton({
  invitedUserUuids,
  iteration,
  tasks,
  status = SmartContractShareStatuses.CHECKED_IN,
  className,
  icon,
  iconClassName,
  i18nText,
  i18nTextClassName,
  i18nTitle = usersKeys.invite,
  tooltipI18nText,
  cacheKeys,
  defaultUsers = [],
  disabled,
  afterCreate
}: BatchCreateSmartContractSharesModalButtonProps) {
  const {
    control,
    batchCreateSmartContractSharesErrorMessage,
    batchCreateSmartContractSharesLoading,
    handleBatchCreateSmartContractShares,
    handleResetBatchCreateSmartContractSharesForm,
    setValue,
    formView,
    goToChangeShareView,
    goToSelectUsersView,
    disabledSubmit,
    oneFormView,
    getValues
  } = useBatchCreateSmartContractSharesForm({
    iteration,
    taskIds: map(tasks, 'id'),
    status,
    cacheKeys,
    defaultUsers,
    afterCreate
  });

  return (
    <SimpleModalButton
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      icon={icon}
      iconClassName={iconClassName}
      i18nSubmitText={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE
          ? words.submit
          : words.next
      }
      i18nCancelText={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE &&
        !oneFormView
          ? words.back
          : words.cancel
      }
      i18nTitle={
        formView === CreateSmartContractShareFormView.SELECT_USERS && i18nTitle
      }
      tooltipI18nText={tooltipI18nText}
      isLoading={batchCreateSmartContractSharesLoading}
      onClose={handleResetBatchCreateSmartContractSharesForm}
      closeOnCancel={
        formView === CreateSmartContractShareFormView.SELECT_USERS ||
        oneFormView
      }
      onCancel={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE
          ? goToSelectUsersView
          : undefined
      }
      cancelButtonPosition={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE &&
        !oneFormView
          ? 'start'
          : 'end'
      }
      submitDisabled={disabledSubmit}
      onSubmit={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE
          ? handleBatchCreateSmartContractShares
          : goToChangeShareView
      }
      notHideOnSubmit={
        formView === CreateSmartContractShareFormView.SELECT_USERS
      }
      childrenClassName="overflow-y-auto relative"
      disabled={disabled}
    >
      <CreateSmartContractShareForm
        form={CREATE_SMART_CONTRACT_SHARE_FORM}
        isLoading={batchCreateSmartContractSharesLoading}
        control={control}
        formView={formView}
        goToSelectUsersView={goToSelectUsersView}
        i18nTitle={i18nTitle}
        setValue={setValue}
        invitedUserUuids={invitedUserUuids}
        withoutEditUsers={oneFormView}
        getValues={getValues}
      />

      <div className="px-4">
        <AlertMessage message={batchCreateSmartContractSharesErrorMessage} />
      </div>
    </SimpleModalButton>
  );
}

export default BatchCreateSmartContractSharesModalButton;
