import * as yup from 'yup';
import map from 'lodash/map';

import { CheckInTasksCacheKeys, TaskID } from '../../../../../tasksTypes';
import {
  CheckInTasksFormData,
  CheckInTasksFormFields
} from '../../CheckInTasksForm.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCheckInTasks } from '../../../../../hooks/useCheckInTasks';

import { formsErrors } from '../../../../../../../locales/keys';

export const checkInTasksFormSchema = yup.object({
  [CheckInTasksFormFields.CONTRACTOR_COMPETENCY_ID]: yup
    .number()
    .required(formsErrors.required)
});

const defaultValues = {};

interface CheckInTasksFormOptionsTask {
  id: TaskID;
}

interface CheckInTasksFormOptions {
  cacheKeys?: CheckInTasksCacheKeys;
  tasks: CheckInTasksFormOptionsTask[];
}

function useCheckInTasksForm({ tasks, cacheKeys }: CheckInTasksFormOptions) {
  const currentUser = useCurrentUser();

  const { control, errors, handleSubmitReactHookForm, register, resetForm } =
    useReactHookForm<CheckInTasksFormData>({
      defaultValues,
      isModalForm: true,
      schema: checkInTasksFormSchema
    });

  const {
    checkInTasks,
    checkInTasksErrorMessage,
    checkInTasksLoading,
    checkInTasksReset
  } = useCheckInTasks({ cacheKeys });

  return {
    control,
    currentUserNanoId: currentUser.nanoId,
    errors,
    contractorCompetencyIdValidationErrorMessage:
      errors?.contractorCompetencyId?.message,
    checkInTasksErrorMessage,
    checkInTasksLoading,
    checkInTasksReset,
    handleCheckInTasks: handleSubmitReactHookForm({
      onSubmit: async (data: CheckInTasksFormData) =>
        checkInTasks({
          taskIds: map(tasks, 'id'),
          contractorCompetencyId: data.contractorCompetencyId.toString()
        }),
      dirtyFieldsOnly: false
    }),
    register,
    resetForm
  };
}

export default useCheckInTasksForm;
