import {
  FetchGroupedItemsFiltersType,
  FetchGroupedItemsCacheKeyType,
  FetchGroupedItemsLimitType,
  FetchGroupedItemsPageType,
  FetchGroupedItemsSortType,
  FetchGroupedItemsGqlQuery,
  GroupedItemsGroupBy
} from '../../groupedItemsTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_GROUPED_ITEMS_FILTERS,
  INITIAL_GROUPED_ITEMS_LIMIT,
  INITIAL_GROUPED_ITEMS_PAGE,
  INITIAL_GROUPED_ITEMS_SORT
} from '../../groupedItemsConstants';

type GroupedItemAdditionalParams = {
  convertTo?: string;
  groupBy?: GroupedItemsGroupBy[];
  groupItemsByType?: boolean;
  showTotal?: boolean;
};

interface GroupedItemsOptions {
  cacheKey: FetchGroupedItemsCacheKeyType;
  initialFilters?: FetchGroupedItemsFiltersType;
  initialSort?: FetchGroupedItemsSortType;
  initialPage?: FetchGroupedItemsPageType;
  initialLimit?: FetchGroupedItemsLimitType;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  query: FetchGroupedItemsGqlQuery;
  additionalParams?: GroupedItemAdditionalParams;
}

const scope = 'groupedItems';

function useFinPaginatedGroupedItems<FetchGroupedItemsItemType>({
  cacheKey,
  initialFilters = INITIAL_GROUPED_ITEMS_FILTERS,
  initialSort = INITIAL_GROUPED_ITEMS_SORT,
  initialPage = INITIAL_GROUPED_ITEMS_PAGE,
  initialLimit = INITIAL_GROUPED_ITEMS_LIMIT,
  options,
  query,
  additionalParams = {
    groupBy: [GroupedItemsGroupBy.ITEM],
    groupItemsByType: false,
    showTotal: true
  }
}: GroupedItemsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    updateItemsCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    clearItemsFiltersPersistInitial
  } = useFinIndexQuery<FetchGroupedItemsItemType>({
    cacheKey,
    scope,
    query,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    additionalParams
  });

  return {
    groupedItemsData: data,
    groupedItems: items,
    groupedItemsError: itemsError,
    groupedItemsErrorMessage: itemsErrorMessage,
    groupedItemsTotalCount: itemsTotalCount,
    groupedItemsFetched: isFetched,
    groupedItemsLoading: isLoading,
    groupedItemsIsPlaceholderData: isPlaceholderData,
    groupedItemsFilters: currentFilters,
    groupedItemsSort: currentSort,
    groupedItemsPage: currentPage,
    groupedItemsLimit: currentLimit,
    updateGroupedItemCache: updateItemCache,
    updateGroupedItemsCache: updateItemsCache,
    filterGroupedItems: filterItems,
    changeGroupedItemsFilters: changeItemsFilters,
    clearGroupedItemsFilters: clearItemsFilters,
    clearGrouedItemsPersistInitialFilters: clearItemsFiltersPersistInitial,
    sortGroupedItems: sortItems,
    paginateGroupedItems: paginateItems,
    limitGroupedItems: limitItems,
    prefetchGroupedItems: prefetchItems
  };
}

export default useFinPaginatedGroupedItems;
