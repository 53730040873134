import React, { useCallback } from 'react';
import cl from 'classnames';

import { DashboardActiveTasksListItemsListItemType } from './DashboardActiveTasksListItemsListItem.types';
import { DashboardActiveTasksListSelectedItem } from '../../../../DashboardActiveTasksList.types';

import { DashboardActiveTasksListItemsListItemStatus } from '../DashboardActiveTasksListItemsListItemStatus';

import { UserAvatar } from '../../../../../../../../../../../../common/helpers/UserAvatar';
import { ProjectTaskLink } from '../../../../../../../../../../../../common/helpers/ProjectTaskLink';
import { DateHelper } from '../../../../../../../../../../../../../helpers/DateHelper';

import { getCustomDateFormat } from '../../../../../../../../../../../../../utils/getCustomDateFormat';

interface DashboardActiveTasksListItemsListItemProps {
  item: DashboardActiveTasksListItemsListItemType;
  selected: boolean;
  onSelected: (value: DashboardActiveTasksListSelectedItem) => void;
}

function DashboardActiveTasksListItemsListItem({
  item,
  selected,
  onSelected
}: DashboardActiveTasksListItemsListItemProps) {
  const handleSelected = useCallback<() => void>(() => {
    onSelected({ nanoId: item.nanoId, scope: item.scope });
  }, [onSelected, item]);

  return (
    <li
      onClick={handleSelected}
      className={cl(
        'cursor-pointer relative flex items-center space-x-4 py-2 px-3 focus-within:ring-2 ring-inset focus-within:z-5 focus-within:ring-gray-850 dark:focus-within:ring-gray-100',
        selected
          ? 'bg-gray-100 dark:bg-gray-500/30'
          : 'hover:bg-gray-50 bg-white dark:bg-gray-850 dark:hover:bg-gray-800'
      )}
    >
      <div className="min-w-0 flex-auto">
        <div className="text-xs leading-5">
          <div className="flex gap-x-2">
            <div className="flex-1 flex gap-x-2 truncate text-sm text-black dark:text-white">
              <ProjectTaskLink
                className="hover:underline font-medium truncate"
                project={item.scope === 'project' ? item : undefined}
                task={item.scope === 'task' ? item : undefined}
              />
            </div>
            {item.scope === 'project' ? null : (
              <div className="shrink-0 flex gap-x-2 self-end items-center">
                <DashboardActiveTasksListItemsListItemStatus
                  status={item.status}
                />
              </div>
            )}
          </div>

          {item.lastMessage && (
            <div className="flex gap-x-2 items-center">
              <UserAvatar
                user={item.lastMessage.user}
                className="w-4 h-4 rounded-full flex items-center justify-center overflow-hidden relative self-center"
              />
              <div className="truncate flex-1 font-medium text-gray-600 dark:text-gray-400">
                {item.lastMessage.user?.fullName} : {item.lastMessage.body}
              </div>

              <div className="shrink-0 text-2xs text-gray-600 dark:text-gray-400 relative z-5">
                <DateHelper
                  date={item.lastMessage.createdAt}
                  customFormat={getCustomDateFormat(item.lastMessage.createdAt)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

export default DashboardActiveTasksListItemsListItem;
