import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TeamNanoID } from '../../../../../../../../teams/teamsTypes';
import { DashboardFinanceBalancesAccountTypeChartNumber } from './DashboardFinanceBalancesChart.types';

import {
  FETCH_ACCOUNTS_QUERY,
  FetchAccountsQueryResponse
} from '../../../../../../../../accounts/queries/fetchAccounts.query';

import { useFinPaginatedAccounts } from '../../../../../../../../accounts/hooks/useFinPaginatedAccounts';

import { AccountBalancesChart } from './components/AccountBalancesChart';

import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';

import { AccountCache } from '../../../../../../../../accounts/AccountCache';

interface DashboardFinanceBalancesChartProps {
  teamNanoId: TeamNanoID;
  client: boolean;
}

const clientAccountTypeChartNumber = [
  DashboardFinanceBalancesAccountTypeChartNumber.CASH,
  DashboardFinanceBalancesAccountTypeChartNumber.CREDIT_1,
  DashboardFinanceBalancesAccountTypeChartNumber.CREDIT_2
];

const workerAccountTypeChartNumber = [
  DashboardFinanceBalancesAccountTypeChartNumber.CASH,
  DashboardFinanceBalancesAccountTypeChartNumber.RECEIVABLE
];

function DashboardFinanceBalancesChart({
  teamNanoId,
  client
}: DashboardFinanceBalancesChartProps) {
  const { accounts, accountsErrorMessage, accountsFetched } =
    useFinPaginatedAccounts<FetchAccountsQueryResponse>({
      cacheKey: AccountCache.companyDashboardAccountsCacheKey(teamNanoId),
      query: FETCH_ACCOUNTS_QUERY,
      initialFilters: {
        accountTypeChartNumber: {
          in: client
            ? clientAccountTypeChartNumber
            : workerAccountTypeChartNumber
        },
        companyNanoId: { eq: teamNanoId }
      }
    });

  if (accountsFetched && isEmpty(accounts)) {
    return null;
  }

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={accountsErrorMessage} />

      <LoadingSkeleton
        loaded={accountsFetched}
        className="mb-4 rounded-lg bg-white border-gray-200 p-4 dark:bg-gray-850 dark:border-gray-800 border h-80"
      >
        <AccountBalancesChart accounts={accounts} client={client} />
      </LoadingSkeleton>
    </Fragment>
  );
}

export default DashboardFinanceBalancesChart;
