import { CheckInTasksSmartContractRuleModalButtonTasks } from './CheckInTasksSmartContractRuleModalButton.types';
import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { CheckInTasksCacheKeys } from '../../../tasksTypes';

import { useCheckInTasksForm } from '../../forms/CheckInTasksForm/hooks/useCheckInTasksForm';

import { CheckInTasksForm } from '../../forms/CheckInTasksForm';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { ItemsListAccordionHelper } from '../../../../../helpers/ItemsListAccordionHelper';

import {
  smartContractsKeys,
  tasksKeys,
  words
} from '../../../../../locales/keys';

interface CheckInTasksSmartContractRuleModalButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tasks: CheckInTasksSmartContractRuleModalButtonTasks;
  cacheKeys?: CheckInTasksCacheKeys;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
}

const CHECK_IN_TASKS_FORM = 'check-in-tasks-form';

function CheckInTasksSmartContractRuleModalButton({
  className,
  i18nText,
  icon,
  iconClassName,
  tasks,
  cacheKeys,
  tooltipI18nText,
  tooltipSingleton
}: CheckInTasksSmartContractRuleModalButtonProps) {
  const {
    checkInTasksErrorMessage,
    checkInTasksLoading,
    checkInTasksReset,
    control,
    contractorCompetencyIdValidationErrorMessage,
    currentUserNanoId,
    handleCheckInTasks,
    resetForm
  } = useCheckInTasksForm({
    tasks,
    cacheKeys
  });

  return (
    <FormModalButton
      className={className}
      form={CHECK_IN_TASKS_FORM}
      i18nSubmitText={words.submit}
      i18nText={i18nText}
      i18nTitle={smartContractsKeys.checkIn}
      icon={icon}
      iconClassName={iconClassName}
      isLoading={false}
      onClose={checkInTasksReset}
      onOpen={resetForm}
      onSubmit={handleCheckInTasks}
      submitDisabled={checkInTasksLoading}
      tooltipI18nText={tooltipI18nText}
      tooltipSingleton={tooltipSingleton}
    >
      <div className="flex-1 overflow-y-auto px-2">
        <CheckInTasksForm
          contractorCompetencyIdValidationErrorMessage={
            contractorCompetencyIdValidationErrorMessage
          }
          control={control}
          form={CHECK_IN_TASKS_FORM}
          isLoading={checkInTasksLoading}
          userNanoId={currentUserNanoId}
        />
        <div className="p-4">
          <ItemsListAccordionHelper
            items={tasks}
            i18nModelName={tasksKeys.pluralLowerCase}
          />
        </div>
      </div>
      <AlertMessage addClassName="mx-4" message={checkInTasksErrorMessage} />
    </FormModalButton>
  );
}

export default CheckInTasksSmartContractRuleModalButton;
