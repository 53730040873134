import { BillUUID } from './billsTypes';

export class BillCache {
  static indexCacheKey() {
    return 'bills';
  }

  static showCacheKey(uuid: BillUUID) {
    return `bill-${uuid}`;
  }

  static showPaymentCacheKey(billUuid: BillUUID) {
    return `bill-payment-${billUuid}`;
  }

  static messagePaymentCacheKey(billUuid: BillUUID) {
    return `bill-message-payment-${billUuid}`;
  }

  static payoneerCheckoutResultCacheKey(uuid: BillUUID) {
    return `bill-payoneer-checkout-result-${uuid}`;
  }

  static showQueryKey(billUuid: BillUUID) {
    return ['bill', billUuid];
  }
}
