import { gql } from 'graphql-request';

import { MessageBody, MessageUUID } from '../messagesTypes';

export interface UpdateMessageVersionRecordType {
  body: MessageBody;
  uuid: MessageUUID;
}

export const UPDATE_MESSAGE_VERSION_QUERY = gql`
  mutation UpdateMessageVersion($uuid: ID!, $body: String!) {
    updateMessageVersion(input: { uuid: $uuid, body: $body }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        uuid
        body
      }
    }
  }
`;
