import {
  DeclineTaskMemberInviteCacheKeys,
  DeclineTaskMemberInviteGqlQuery,
  TaskMemberInviteUUID
} from '../../taskMemberInvitesTypes';
import {
  DeclineTaskMemberInviteError,
  DeclineTaskMemberInviteResponse
} from './useDeclineTaskMemberInvite.types';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

interface DeclineTaskMemberInviteInput {
  uuid: TaskMemberInviteUUID;
}

interface DeclineTaskMemberInviteOptions {
  query: DeclineTaskMemberInviteGqlQuery;
  cacheKeys?: DeclineTaskMemberInviteCacheKeys;
}

const action = 'declineTaskMemberInvite';

function useDeclineTaskMemberInvite({
  query,
  cacheKeys
}: DeclineTaskMemberInviteOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeclineTaskMemberInviteInput,
    DeclineTaskMemberInviteResponse,
    DeclineTaskMemberInviteError
  >({
    action,
    query,
    cacheKeys
  });

  return {
    declineTaskMemberInviteData: deleteQueryData,
    declineTaskMemberInviteError: deleteQueryError,
    declineTaskMemberInviteLoading: deleteQueryLoading,
    declineTaskMemberInviteErrorMessage: deleteQueryErrorMessage,
    declineTaskMemberInvite: deleteQuery,
    declineTaskMemberInviteReset: deleteQueryReset
  };
}

export default useDeclineTaskMemberInvite;
