import React, { useEffect, useMemo } from 'react';

import { UserID, UserNanoID } from '../../../../../usersTypes';
import {
  ShowDashboardTasksByStatusType,
  dashboardTasksByStatusCacheKey
} from './DashboardTasksByStatus.types';

import { useReactQueryState } from '../../../../../../common/hooks/base/reactQuery/useReactQueryState';

import { DashboardTasksItem } from './components/DashboardTasksItem';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../../../helpers/Translate';

import { UsersPermissions } from '../../../../../usersConstants';
import { TaskStatuses } from '../../../../../../tasks/tasksTypes';
import { profilesKeys } from '../../../../../../../locales/keys';

interface DashboardTasksByStatusUser {
  id: UserID;
  nanoId: UserNanoID;
}

interface DashboardTasksByStatusProps {
  user: DashboardTasksByStatusUser;
  selfProfile: boolean;
}

type DashboardTasksByStatusStatusesType = {
  status: TaskStatuses;
  action: string;
};

function DashboardTasksByStatus({
  selfProfile,
  user
}: DashboardTasksByStatusProps) {
  const { value, clearValue } =
    useReactQueryState<ShowDashboardTasksByStatusType>(
      dashboardTasksByStatusCacheKey
    );

  useEffect(() => {
    return () => {
      clearValue();
    };
  }, [clearValue]);

  const dashboardTasksStatuses = useMemo<DashboardTasksByStatusStatusesType[]>(
    () => [
      {
        status: TaskStatuses.IN_PROGRESS,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_IN_PROGRESS_FIELD
          : UsersPermissions.READ_USER_DASHBOARD_TASKS_IN_PROGRESS_FIELD
      },
      {
        status: TaskStatuses.QUALITY_CONTROL,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_QUALITY_CONTROL_FIELD
          : UsersPermissions.READ_USER_DASHBOARD_TASKS_QUALITY_CONTROL_FIELD
      },
      {
        status: TaskStatuses.READY_FOR_ACCEPTANCE,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_READY_FOR_ACCEPTANCE_FIELD
          : UsersPermissions.READ_USER_DASHBOARD_TASKS_READY_FOR_ACCEPTANCE_FIELD
      },
      {
        status: TaskStatuses.PENDING_MORE_INFO,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_PENDING_MORE_INFO_FIELD
          : UsersPermissions.READ_USER_DASHBOARD_TASKS_PENDING_MORE_INFO_FIELD
      },
      {
        status: TaskStatuses.PAUSED,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_PAUSED_FIELD
          : UsersPermissions.READ_USER_DASHBOARD_TASKS_PAUSED_FIELD
      },
      {
        status: TaskStatuses.SETTING_TASK,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_SETTING_TASK_FIELD
          : UsersPermissions.READ_USER_DASHBOARD_TASKS_SETTING_TASK_FIELD
      }
    ],
    [selfProfile]
  );

  return (
    <div className={!value ? 'hidden' : undefined}>
      <div className="flex gap-2 mb-4">
        <div className="uppercase dark:text-gray-300">
          <Translate id={profilesKeys.tasksByStatus} />
        </div>

        <div className="flex-1 relative flex items-center">
          <hr className="w-full dark:border-gray-800" />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {dashboardTasksStatuses.map(({ action, status }) => (
          <CheckPermissions key={status} action={action}>
            <DashboardTasksItem
              status={status}
              user={user}
              selfProfile={selfProfile}
            />
          </CheckPermissions>
        ))}
      </div>
    </div>
  );
}

export default DashboardTasksByStatus;
