import React, { useMemo, useState } from 'react';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import size from 'lodash/size';
import pickBy from 'lodash/pickBy';

import { ClassName, I18nText } from '../../../../types';
import { InvoicesPaymentsOverviewInvoiceMeta } from './InvoicesPaymentsOverview.types';
import { InvoiceStatuses } from '../../invoicesTypes';

import { InvoicesPaymentsParts } from './components/InvoicesPaymentsParts';
import { InvoicesPaymentsPartsName } from './components/InvoicesPaymentsPartsName';

import { MoneyHelper } from '../../../../helpers/MoneyHelper';
import { Tooltip } from '../../../../helpers/tooltips/Tooltip';
import { Translate } from '../../../../helpers/Translate';

type PaymentPartsType = {
  [key: string]: number;
};

interface InvoicesPaymentsOverviewProps {
  invoicesMeta: InvoicesPaymentsOverviewInvoiceMeta;
  refundInvoicesTotalAmount: number;
  chargebackInvoicesTotalAmount: number;
  className?: ClassName;
  popupWithDetails?: boolean;
  i18nTitle?: I18nText;
}

function InvoicesPaymentsOverview({
  invoicesMeta,
  refundInvoicesTotalAmount,
  chargebackInvoicesTotalAmount,
  className,
  popupWithDetails = false,
  i18nTitle
}: InvoicesPaymentsOverviewProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const totalAmount = useMemo<number>(
    () =>
      invoicesMeta.totalAmount +
      refundInvoicesTotalAmount +
      chargebackInvoicesTotalAmount,
    [
      chargebackInvoicesTotalAmount,
      invoicesMeta.totalAmount,
      refundInvoicesTotalAmount
    ]
  );

  const paymentByStatus = useMemo<PaymentPartsType>(() => {
    const mapGroupInvoicesStatuses = pickBy(
      invoicesMeta.amountByStatus,
      (value) => value > 0
    );

    return merge(
      mapGroupInvoicesStatuses,
      chargebackInvoicesTotalAmount
        ? { chargeback: chargebackInvoicesTotalAmount }
        : {},
      refundInvoicesTotalAmount ? { refund: refundInvoicesTotalAmount } : {}
    );
  }, [
    chargebackInvoicesTotalAmount,
    invoicesMeta.amountByStatus,
    refundInvoicesTotalAmount
  ]);

  const invoicesStatuses = useMemo<string[]>(
    () => keys(paymentByStatus),
    [paymentByStatus]
  );

  return (
    <div ref={setReferenceTooltipElement} className={className}>
      <div>
        {i18nTitle && (
          <div className="text-2xs text-gray-500">
            <Translate id={i18nTitle} />
          </div>
        )}
        <div className="flex gap-4 items-center">
          <MoneyHelper value={totalAmount} />
        </div>
      </div>

      <div className="flex space-x-2 items-center">
        <div className="relative w-full h-1.5 rounded overflow-hidden flex gap-0.5">
          {invoicesStatuses.map((status) => (
            <InvoicesPaymentsParts
              key={status}
              status={status as InvoiceStatuses}
              width={(paymentByStatus[status] / totalAmount) * 100}
            />
          ))}
        </div>
      </div>

      {popupWithDetails && size(invoicesStatuses) > 0 ? (
        <Tooltip withArrow referenceElement={referenceTooltipElement}>
          <div className="flex flex-col">
            {invoicesStatuses.map((status) => (
              <InvoicesPaymentsPartsName
                key={status}
                amount={paymentByStatus[status]}
                status={status as InvoiceStatuses}
              />
            ))}
          </div>
        </Tooltip>
      ) : (
        <div className="flex flex-col">
          {invoicesStatuses.map((status) => (
            <InvoicesPaymentsPartsName
              key={status}
              amount={paymentByStatus[status]}
              status={status as InvoiceStatuses}
              className="md:px-4 text-gray-500"
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default InvoicesPaymentsOverview;
