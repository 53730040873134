import React, { useCallback, useEffect } from 'react';
import every from 'lodash/every';

import { TaskNanoID } from '../../../../tasks/tasksTypes';
import { UpdateItemsCacheKeys } from '../../../../../types/updateItemsTypes';
import { TaskMemberInvitesListTasks } from './TaskMemberInvitesList.types';
import {
  DashboardActiveTasksListItemScope,
  DashboardActiveTasksListSelectedItem
} from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardActiveTasks/components/DashboardActiveTasksList';

import { TaskMemberInvitesListItem } from './components/TaskMemberInvitesListItem';

interface TaskMemberInvitesListProps {
  taskMemberInvites: TaskMemberInvitesListTasks;
  withActions: boolean;
  selectedItem: DashboardActiveTasksListSelectedItem;
  onSelectedItem: (value: DashboardActiveTasksListSelectedItem) => void;
  cacheKeys?: UpdateItemsCacheKeys;
}

function TaskMemberInvitesList({
  taskMemberInvites,
  withActions,
  onSelectedItem,
  selectedItem,
  cacheKeys
}: TaskMemberInvitesListProps) {
  const handleSelect = useCallback<(value: TaskNanoID) => void>(
    (value) => {
      onSelectedItem({
        nanoId: value,
        scope: DashboardActiveTasksListItemScope.TASK
      });
    },
    [onSelectedItem]
  );

  useEffect(() => {
    if (selectedItem) {
      every(
        taskMemberInvites,
        (taskMemberInvite) =>
          taskMemberInvite.bitTask?.nanoId !== selectedItem.nanoId
      ) && onSelectedItem(null);
    }
  }, [onSelectedItem, selectedItem, taskMemberInvites]);

  return (
    <ul className="divide-y divide-gray-500/10">
      {taskMemberInvites?.map((taskMemberInvite) => (
        <TaskMemberInvitesListItem
          key={taskMemberInvite.uuid}
          taskMemberInvite={taskMemberInvite}
          selected={
            selectedItem?.scope === DashboardActiveTasksListItemScope.TASK &&
            selectedItem?.nanoId === taskMemberInvite.bitTask?.nanoId
          }
          onSelected={handleSelect}
          cacheKeys={cacheKeys}
          withActions={withActions}
        />
      ))}
    </ul>
  );
}

export default TaskMemberInvitesList;
