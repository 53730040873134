import {
  CreatedAt,
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IDFilter,
  ItemGqlError,
  NanoIDFilter,
  UUID
} from '../../types';
import {
  ProjectID,
  ProjectName,
  ProjectNanoID
} from '../projects/projectsTypes';
import {
  TaskID,
  TaskName,
  TaskNanoID,
  TaskProjectName,
  TaskProjectNanoID
} from '../tasks/tasksTypes';

export type TrackedTimeIntervalID = ID;
export type TrackedTimeIntervalCreatedAt = CreatedAt;
export type TrackedTimeIntervalFullTime = number;
export type TrackedTimeIntervalHours = number;
export type TrackedTimeIntervalMinutes = number;
export type TrackedTimeIntervalProjectID = ProjectID;
export type TrackedTimeIntervalTaskID = TaskID;

export type TrackedTimeIntervalProjectNanoID = ProjectNanoID;
export type TrackedTimeIntervalProjectName = ProjectName;

export type TrackedTimeIntervalTaskNanoID = TaskNanoID;
export type TrackedTimeIntervalTaskName = TaskName;
export type TrackedTimeIntervalTaskProjectNanoID = TaskProjectNanoID;
export type TrackedTimeIntervalTaskProjectName = TaskProjectName;

export interface FetchTrackedTimeIntervalsFilters {
  id?: IDFilter;
  createdAt?: DateFilterType;
  updatedAt?: DateFilterType;
  userId?: IDFilter;
  projectId?: IDFilter;
  taskId?: IDFilter;
  messageId?: IDFilter;
  userUuid?: UUID;
  taskUuid?: UUID;
  userNanoId?: NanoIDFilter;
  taskNanoId?: NanoIDFilter;
}

export type FetchTrackedTimeIntervalsGqlQuery = FetchItemsGqlQuery;

export type TrackedTimeIntervalGqlError = ItemGqlError;

export type FetchTrackedTimeIntervalsCacheKey = FetchItemsCacheKey;

export type FetchTrackedTimeIntervalsFetched = FetchItemsFetched;
export type FetchTrackedTimeIntervalsErrorMessage = FetchItemsErrorMessage;
export type FetchTrackedTimeIntervalsSort = FetchItemsSort;
export type FetchTrackedTimeIntervalsSortTrackedTimeIntervals =
  FetchItemsSortItems;
export type FetchTrackedTimeIntervalsPage = FetchItemsPage;
export type FetchTrackedTimeIntervalsLimit = FetchItemsLimit;
export type FetchTrackedTimeIntervalsTotalCount = FetchItemsTotalCount;
export type FetchTrackedTimeIntervalsIsPlaceholderData =
  FetchItemsIsPlaceholderData;
export type FetchTrackedTimeIntervalsPaginateTrackedTimeIntervals =
  FetchItemsPaginateItems;
export type FetchTrackedTimeIntervalsPrefetchTrackedTimeIntervals =
  FetchItemsPrefetchItems;

export type FetchTrackedTimeIntervalsFiltersFunc = (
  nextFilters: FetchTrackedTimeIntervalsFilters
) => void;
export type FetchTrackedTimeIntervalsFilterTrackedTimeIntervals =
  FetchItemsFilterItems<FetchTrackedTimeIntervalsFilters>;
export type FetchTrackedTimeIntervalsClearTrackedTimeIntervalsFilters =
  FetchItemsClearItemsFilters;

export enum FetchTrackedTimeIntervalsFilterFields {
  CREATED_AT = 'createdAt'
}

export enum FetchTrackedTimeIntervalsSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export enum FetchTrackedTimeIntervalsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}
