import {
  FetchTrackedTimeIntervalsGqlQuery,
  FetchTrackedTimeIntervalsCacheKey,
  FetchTrackedTimeIntervalsFilters,
  FetchTrackedTimeIntervalsSort,
  FetchTrackedTimeIntervalsPage,
  FetchTrackedTimeIntervalsLimit
} from '../../trackedTimeIntervalsTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_TRACKED_TIME_INTERVALS_FILTERS,
  INITIAL_TRACKED_TIME_INTERVALS_LIMIT,
  INITIAL_TRACKED_TIME_INTERVALS_PAGE,
  INITIAL_TRACKED_TIME_INTERVALS_SORT
} from '../../trackedTimeIntervalsConstants';

interface TrackedTimeIntervalsOptions {
  cacheKey: FetchTrackedTimeIntervalsCacheKey;
  query: FetchTrackedTimeIntervalsGqlQuery;
  initialFilters?: FetchTrackedTimeIntervalsFilters;
  initialSort?: FetchTrackedTimeIntervalsSort;
  initialPage?: FetchTrackedTimeIntervalsPage;
  initialLimit?: FetchTrackedTimeIntervalsLimit;
  options?: {
    cacheTime?: number;
  };
}

const scope = 'trackedTimeIntervals';

function usePaginatedTrackedTimeIntervals<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TRACKED_TIME_INTERVALS_FILTERS,
  initialSort = INITIAL_TRACKED_TIME_INTERVALS_SORT,
  initialPage = INITIAL_TRACKED_TIME_INTERVALS_PAGE,
  initialLimit = INITIAL_TRACKED_TIME_INTERVALS_LIMIT,
  options = {}
}: TrackedTimeIntervalsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options
  });

  return {
    trackedTimeIntervalsData: data,
    trackedTimeIntervals: items,
    trackedTimeIntervalsError: itemsError,
    trackedTimeIntervalsTotalCount: itemsTotalCount,
    trackedTimeIntervalsFetched: isFetched,
    trackedTimeIntervalsLoading: isLoading,
    trackedTimeIntervalsIsPlaceholderData: isPlaceholderData,
    trackedTimeIntervalsFilters: currentFilters,
    trackedTimeIntervalsSort: currentSort,
    trackedTimeIntervalsPage: currentPage,
    trackedTimeIntervalsLimit: currentLimit,
    filterTrackedTimeIntervals: filterItems,
    changeTrackedTimeIntervalsFilters: changeItemsFilters,
    clearTrackedTimeIntervalsFilters: clearItemsFilters,
    sortTrackedTimeIntervals: sortItems,
    paginateTrackedTimeIntervals: paginateItems,
    limitTrackedTimeIntervals: limitItems,
    prefetchTrackedTimeIntervals: prefetchItems,
    prefetchCategory: prefetchItem
  };
}

export default usePaginatedTrackedTimeIntervals;
