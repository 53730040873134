import { ClassName, I18nText, UpdateItemCacheKeys } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { DECLINE_ALL_TASK_MEMBER_INVITES_QUERY } from '../../../queries/declineAllTaskMemberInvites.query';

import { useDeclineAllTaskMemberInvites } from '../../../hooks/useDeclineAllTaskMemberInvites';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import { taskMemberInvitesKeys } from '../../../../../locales/keys';
import { TaskMemberInviteCache } from '../../../TaskMemberInviteCache';

interface DeclineAllTaskMemberInvitesButtonProps {
  afterSubmit?: () => void;
  className?: ClassName;
  i18nButtonText?: I18nText;
  tooltipI18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  cacheKeys?: UpdateItemCacheKeys;
}

function DeclineAllTaskMemberInvitesButton({
  afterSubmit,
  className,
  i18nButtonText,
  tooltipI18nText,
  icon,
  iconClassName,
  cacheKeys
}: DeclineAllTaskMemberInvitesButtonProps) {
  const {
    declineAllTaskMemberInvites,
    declineAllTaskMemberInvitesErrorMessage,
    declineAllTaskMemberInvitesLoading
  } = useDeclineAllTaskMemberInvites({
    query: DECLINE_ALL_TASK_MEMBER_INVITES_QUERY,
    cacheKeys: cacheKeys ? cacheKeys : [TaskMemberInviteCache.indexCacheKey()]
  });

  return (
    <ConfirmModalButton
      afterSubmit={afterSubmit}
      errorMessage={declineAllTaskMemberInvitesErrorMessage}
      i18nSubmitText={taskMemberInvitesKeys.declineAll}
      i18nButtonText={i18nButtonText}
      tooltipI18nText={tooltipI18nText}
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={
        taskMemberInvitesKeys.areTouSureYouWantToDeclineAllTaskInvitationsThisActionCannotBeUndone
      }
      i18nTitle={taskMemberInvitesKeys.declineAll}
      isLoading={declineAllTaskMemberInvitesLoading}
      onSubmit={declineAllTaskMemberInvites}
    />
  );
}

export default DeclineAllTaskMemberInvitesButton;
