import { gql } from 'graphql-request';

import {
  TaskID,
  TaskUUID,
  TaskName,
  TaskStatus,
  TaskProjectUUID,
  TaskProjectName,
  TaskNanoID,
  TaskProjectNanoID,
  TaskProjectTeamName,
  TaskProjectTeamNanoID,
  TaskVisibleForClient,
  TaskMark,
  TaskSupportMark,
  TaskMembersUUID
} from '../tasksTypes';

export interface FetchShowTaskDashboardQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  status: TaskStatus;
  mark: TaskMark;
  supportMark: TaskSupportMark;
  name: TaskName;
  project: {
    uuid: TaskProjectUUID;
    nanoId: TaskProjectNanoID;
    name: TaskProjectName;
    team: {
      name: TaskProjectTeamName;
      nanoId: TaskProjectTeamNanoID;
    };
  };
  visibleForClient: TaskVisibleForClient;
  members: {
    uuid: TaskMembersUUID;
  }[];
}

export const FETCH_SHOW_TASK_DASHBOARD_QUERY = gql`
  query ShowTaskDashboard($uuid: ID!) {
    task(uuid: $uuid) {
      id
      uuid
      nanoId
      status
      mark
      supportMark
      name
      project {
        uuid
        nanoId
        name
        team {
          name
          nanoId
        }
      }
      visibleForClient
      members {
        uuid
      }
    }
  }
`;
