import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import maxBy from 'lodash/maxBy';
import round from 'lodash/round';

import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import isSameDay from 'date-fns/isSameDay';

import { FetchTrackedTimeIntervalQueryResponse } from '../../../../../../../../trackedTimeIntervals/queries/fetchTrackedTimeIntervals.query';
import { DashboardTrackedTimeFilterType } from '../../DashboardTrackedTime.types';

const dayPeriods = [1, 2, 3, 4];
const periods = [0, 1, 2, 3];

function getTrackedTimeEndDate(type: DashboardTrackedTimeFilterType): Date {
  if (type === DashboardTrackedTimeFilterType.DAY) {
    return startOfDay(new Date());
  }

  if (type === DashboardTrackedTimeFilterType.WEEK) {
    return startOfWeek(new Date(), { weekStartsOn: 1 });
  }

  if (type === DashboardTrackedTimeFilterType.MONTH) {
    return startOfMonth(new Date());
  }
}

export function getTrackedTimeStartDate(
  type: DashboardTrackedTimeFilterType
): Date {
  if (type === DashboardTrackedTimeFilterType.DAY) {
    return addDays(getTrackedTimeEndDate(type), -size(dayPeriods));
  }

  if (type === DashboardTrackedTimeFilterType.WEEK) {
    return addWeeks(getTrackedTimeEndDate(type), -size(periods));
  }

  if (type === DashboardTrackedTimeFilterType.MONTH) {
    return addMonths(getTrackedTimeEndDate(type), -size(periods));
  }
}

function getPeriodDate(type: DashboardTrackedTimeFilterType): Date[] {
  if (type === DashboardTrackedTimeFilterType.DAY) {
    return dayPeriods.map((dayPeriod) =>
      addDays(getTrackedTimeEndDate(type), -dayPeriod)
    );
  }

  if (type === DashboardTrackedTimeFilterType.WEEK) {
    return periods.map((period) =>
      addWeeks(getTrackedTimeEndDate(type), -period)
    );
  }

  if (type === DashboardTrackedTimeFilterType.MONTH) {
    return periods.map((period) =>
      addMonths(getTrackedTimeEndDate(type), -period)
    );
  }
}

function getPeriodDateInterval(
  type: DashboardTrackedTimeFilterType,
  periodDate: Date,
  prevPeriodDate: Date
): {
  start: Date;
  end: Date;
} {
  if (type === DashboardTrackedTimeFilterType.DAY) {
    return { start: periodDate, end: endOfDay(periodDate) };
  }

  if (type === DashboardTrackedTimeFilterType.WEEK) {
    return {
      start: periodDate,
      end: isSameDay(periodDate, prevPeriodDate)
        ? prevPeriodDate
        : addDays(prevPeriodDate, -1)
    };
  }

  if (type === DashboardTrackedTimeFilterType.MONTH) {
    return { start: periodDate, end: endOfMonth(periodDate) };
  }
}

export type TrackedTimeCountByPeriodsDateType = {
  period: {
    startDate: Date;
    endDate: Date;
  };
  fullTime: number;
  percent: number;
};

function getTrackedTimeCountByPeriodsDate(
  trackedTimeIntervals: FetchTrackedTimeIntervalQueryResponse[] = [],
  type: DashboardTrackedTimeFilterType
): TrackedTimeCountByPeriodsDateType[] {
  const periodsDate = getPeriodDate(type);

  const trackedTimeByPeriodsDate = periodsDate.map((periodDate, index) => {
    const interval = getPeriodDateInterval(
      type,
      periodDate,
      index === 0 ? endOfDay(new Date()) : periodsDate[index - 1]
    );

    return {
      period: {
        startDate: interval.start,
        endDate: interval.end
      },
      fullTime: sumBy(
        trackedTimeIntervals.filter((trackedTimeInterval) =>
          isWithinInterval(new Date(trackedTimeInterval.createdAt), interval)
        ),
        'fullTime'
      )
    };
  });

  const sumTrackedTime = sumBy(trackedTimeByPeriodsDate, 'fullTime');

  const allMultiplier =
    maxBy(trackedTimeByPeriodsDate, 'fullTime')?.fullTime / sumTrackedTime;

  return trackedTimeByPeriodsDate.map((trackedTimeByPeriodDate) => ({
    ...trackedTimeByPeriodDate,
    percent: round(
      (trackedTimeByPeriodDate.fullTime / sumTrackedTime / allMultiplier) * 100
    )
  }));
}

export default getTrackedTimeCountByPeriodsDate;
