import { gql } from 'graphql-request';

import {
  TaskAmount,
  TaskBurning,
  TaskCreatedAt,
  TaskID,
  TaskImplementationDate,
  TaskImplementedAt,
  TaskMembersID,
  TaskName,
  TaskNanoID,
  TaskSetDate,
  TaskStartDate,
  TaskStatus,
  TaskUUID
} from '../tasksTypes';

export interface FetchDashboardTasksQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  amount?: TaskAmount;
  name: TaskName;
  status: TaskStatus;
  implementationDate: TaskImplementationDate;
  implementedAt: TaskImplementedAt;
  setDate: TaskSetDate;
  startDate: TaskStartDate;
  burning: TaskBurning;
  createdAt: TaskCreatedAt;
  members: {
    id: TaskMembersID;
  }[];
}

export const FETCH_DASHBOARD_TASKS_QUERY = gql`
  query DashboardTasks(
    $filters: TasksFilters
    $sort: [TasksSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    tasks(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        amount
        uuid
        nanoId
        name
        status
        statusNumber
        statusNumberAsc
        statusNumberDesc
        implementationDate
        implementedAt
        setDate
        startDate
        burning
        createdAt
        members {
          id
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
