import React, { ReactNode } from 'react';

import { TeamAvatarLinkTeam } from './TeamAvatarLink.types';

import { TeamAvatar } from '../TeamAvatar';
import { TeamLink } from '../TeamLink';

interface TeamAvatarLinkDefaultProps {
  team: TeamAvatarLinkTeam;
  newLabel?: ReactNode;
}

interface TeamAvatarLinkWithClassNameProps {
  className: string;
  avatarType?: never;
  width?: number;
  height?: number;
}

interface TeamAvatarLinkWithAvatarTypeProps {
  className?: never;
  avatarType: 'main' | 'xl' | 'xs';
  width?: number;
  height?: number;
}

type TeamAvatarLinkProps = TeamAvatarLinkDefaultProps &
  (TeamAvatarLinkWithClassNameProps | TeamAvatarLinkWithAvatarTypeProps);

function TeamAvatarLink({
  team,
  newLabel,
  avatarType,
  className,
  width,
  height
}: TeamAvatarLinkProps) {
  if (!team) {
    return null;
  }

  return (
    <TeamLink team={team} className="h-fit w-fit">
      <TeamAvatar
        team={team}
        type={avatarType}
        className={className}
        width={width}
        height={height}
      />
      {newLabel}
    </TeamLink>
  );
}

export default TeamAvatarLink;
