import { gql } from 'graphql-request';

import { ProjectID } from '../projectsTypes';

export interface FetchProjectsCountQueryResponse {
  id: ProjectID;
}

export const FETCH_PROJECTS_COUNT_QUERY = gql`
  query ProjectsCount($filters: ProjectsFilters, $limit: Int, $offset: Int) {
    projects(filters: $filters, limit: $limit, offset: $offset) {
      nodes {
        id
      }
      paginationInfo {
        totalCount
      }
    }
  }
`;
