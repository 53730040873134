import React, { Fragment, useCallback } from 'react';

import { FetchBillErrorMessage, FetchBillFetched } from '../../../billsTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { PayBillModalButtonBillItem } from './PayBillModalButton.types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { PayoneerCheckoutPaymentContainer } from '../../containers/PayoneerCheckoutPaymentContainer';
import { BillCard } from '../../cards/BillCard';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

import {
  SimpleModalButton,
  SimpleModalButtonDefaultProps
} from '../../../../../helpers/buttons/SimpleModalButton';

import { invoicesKeys, words, stringsKeys } from '../../../../../locales/keys';

interface PayBillModalButtonProps {
  bill: PayBillModalButtonBillItem;
  billError?: FetchBillErrorMessage;
  billFetched: FetchBillFetched;
  projectNanoId?: ProjectNanoID;
}

function generateHtmlForm(str: string) {
  const iOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  return iOS && isSafari && str ? str.replace(/target="_blank"/gi, '') : str;
}

function PayBillModalButton({
  bill,
  className,
  disabled,
  i18nText,
  icon,
  iconClassName,
  onMouseEnter,
  billError,
  billFetched,
  projectNanoId
}: PayBillModalButtonProps & SimpleModalButtonDefaultProps) {
  const { t } = useTranslate();

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (bill?.paymentUrl) {
      window.open(bill.paymentUrl);
      return;
    }

    if (bill?.paymentForm) {
      const paymentFormElement = document.getElementById(
        '2checkout'
      ) as HTMLFormElement;
      paymentFormElement.setAttribute('target', '_self');
      paymentFormElement.submit();
      return;
    }
  }, [bill]);

  const isPayoneerCheckoutForm = bill?.account === 'payoneer_checkout';
  const isStripeForm = bill?.buttonType === 'stripe_button';

  return (
    <SimpleModalButton
      className={className}
      disabled={disabled}
      i18nText={i18nText}
      i18nSubmitText={words.pay}
      childrenClassName="flex-1 overflow-y-auto px-2"
      buttonsContainerClassName="flex p-4 justify-center"
      submitClass="py-2 pl-2 pr-4 space-x-1 rounded-md flex m-auto items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:bg-blue-500 hover:shadow-md w-72 max-w-full justify-center"
      submitIcon={IconsEnum.CASH}
      submitIconClassName="h-6 w-6 p-0.5"
      modalSize="sm"
      title={
        bill
          ? `${t(invoicesKeys.singular)} #${bill.id}`
          : t(invoicesKeys.singular)
      }
      icon={icon}
      iconClassName={iconClassName}
      modalIcon={IconsEnum.CASH}
      withoutCancelButton
      withoutButtons={isPayoneerCheckoutForm || isStripeForm ? true : false}
      onMouseEnter={onMouseEnter}
      onSubmit={handleSubmit}
      submitDisabled={!billFetched}
      allowPropagation={isPayoneerCheckoutForm}
    >
      <div className="p-4">
        <LoadingSkeleton loaded={billFetched}>
          {bill ? (
            <Fragment>
              <BillCard
                withoutTitle
                bill={bill}
                projectNanoId={projectNanoId}
              />
              {bill.paymentForm ? (
                <span
                  className="hidden"
                  dangerouslySetInnerHTML={{
                    __html: generateHtmlForm(bill.paymentForm)
                  }}
                />
              ) : null}
              {isPayoneerCheckoutForm && bill.payer?.payoneerCheckoutAddress ? (
                <PayoneerCheckoutPaymentContainer bill={bill} />
              ) : null}
            </Fragment>
          ) : null}
        </LoadingSkeleton>
        {isPayoneerCheckoutForm && !bill.payer?.payoneerCheckoutAddress ? (
          <AlertMessage
            addClassName="mt-2"
            i18nMessage={stringsKeys.pleaseProvideYourAddress}
          />
        ) : null}
        <AlertMessage addClassName="mt-2" message={billError} />
      </div>
    </SimpleModalButton>
  );
}

export default PayBillModalButton;
