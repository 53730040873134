import { gql } from 'graphql-request';

import {
  ProjectBurning,
  ProjectID,
  ProjectName,
  ProjectStatus,
  ProjectUUID,
  ProjectNanoID,
  ProjectTeamNanoID,
  ProjectTeamName,
  ProjectTeamImageFile
} from '../projectsTypes';

export interface FetchShowProjectQueryResponse {
  id: ProjectID;
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  name: ProjectName;
  status: ProjectStatus;
  burning: ProjectBurning;
  team: {
    nanoId: ProjectTeamNanoID;
    name: ProjectTeamName;
    image: {
      file: ProjectTeamImageFile;
    };
  };
}

export const FETCH_SHOW_PROJECT_QUERY = gql`
  query ShowProject($uuid: ID!) {
    project(uuid: $uuid) {
      id
      uuid
      nanoId
      name
      status
      burning
      owner {
        id
        client
      }
      team {
        nanoId
        name
        image {
          file
        }
      }
    }
  }
`;
