import {
  TaskGqlError,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus
} from '../../tasksTypes';
import { UpdateItemCacheKeys } from '../../../../types';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface AcceptInviteToTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface AcceptInviteToTaskResponse<AcceptInviteToTaskRecordType> {
  acceptInviteToTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: AcceptInviteToTaskRecordType;
    errors: AcceptInviteToTaskError;
  };
}

export interface AcceptInviteToTaskInput {
  uuid: TaskUUID | TaskNanoID;
}

export interface AcceptInviteToTaskError {
  fullMessages: TaskGqlError;
}

const action = 'acceptInviteToTask';

const scope = 'task';

const pluralScope = 'tasks';

function useAcceptInviteToTask<AcceptInviteToTaskRecordType>({
  query,
  cacheKeys
}: AcceptInviteToTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    AcceptInviteToTaskInput,
    AcceptInviteToTaskResponse<AcceptInviteToTaskRecordType>,
    AcceptInviteToTaskError,
    AcceptInviteToTaskRecordType
  >({ action, pluralScope, scope, query, cacheKeys });

  return {
    acceptInviteToTaskData: updateQueryData,
    acceptInviteToTaskError: updateQueryError,
    acceptInviteToTaskLoading: updateQueryLoading,
    acceptInviteToTaskErrorMessage: updateQueryErrorMessage,
    acceptInviteToTask: updateQuery,
    acceptInviteToTaskReset: updateQueryReset
  };
}

export default useAcceptInviteToTask;
