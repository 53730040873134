import React, { Fragment } from 'react';
import cl from 'classnames';

import { Translate } from '../../../../Translate';

import { DropzoneHelper } from '../../../../dropzone/DropzoneHelper';

import { DropzoneFieldControlRequiredProps } from './DropzoneFieldControl.types';

type DropzoneFieldControlProps = DropzoneFieldControlRequiredProps;

function DropzoneFieldControl({
  accept,
  type,
  disabled,
  error,
  errorClassName,
  labelFor,
  i18nLabel,
  label,
  labelClassName,
  withoutTabs,
  withoutDownload,
  withoutDropzoneArea,
  withoutRemoveButton,
  withoutStatus,
  maxFiles,
  preventMaxFilesOverload,
  initialFiles,
  initialFileIds,
  onChange,
  onRemoveFile,
  onChangeFiles
}: DropzoneFieldControlProps) {
  return (
    <Fragment>
      {i18nLabel || label ? (
        <label
          htmlFor={labelFor}
          className={cl(
            labelClassName ||
              'block text-sm font-medium text-gray-700 dark:text-gray-300'
          )}
        >
          {i18nLabel ? <Translate id={i18nLabel} /> : label}
        </label>
      ) : null}

      <DropzoneHelper
        type={type}
        disabled={disabled}
        withoutTabs={withoutTabs}
        withoutDownload={withoutDownload}
        withoutDropzoneArea={withoutDropzoneArea}
        withoutRemoveButton={withoutRemoveButton}
        withoutStatus={withoutStatus}
        maxFiles={maxFiles}
        preventMaxFilesOverload={preventMaxFilesOverload}
        onChange={onChange}
        initialFiles={initialFiles}
        initialFileIds={initialFileIds}
        onRemoveFile={onRemoveFile}
        accept={accept}
        onChangeFiles={onChangeFiles}
      />

      {error && (
        <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
          {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
        </p>
      )}
    </Fragment>
  );
}

export default DropzoneFieldControl;
