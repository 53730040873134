import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import {
  BillUUID,
  FetchBillPaymentCacheKey,
  FetchBillGqlQuery
} from '../../billsTypes';

import { fetchItem } from '../../../common/hooks/base/reactQuery/baseActions/fetchItem';

import { graphqlErrorsMessages } from '../../../../utils/graphqlErrorsMessages';

interface BillResponse<ItemType> {
  bill: ItemType | null;
}

interface BillOptions {
  uuid: BillUUID;
  cacheKey: FetchBillPaymentCacheKey;
  query: FetchBillGqlQuery;
}

interface UseBillItem {
  uuid: BillUUID;
}

const itemKey = 'bill';
const options = {
  staleTime: 1000 * 60 * 60
};

function useBillPayment<ItemType extends UseBillItem>({
  uuid,
  query,
  cacheKey
}: BillOptions) {
  const [billPaymentEnabled, setBillPaymentEnabled] = useState<boolean>(false);

  const { data, isLoading, error, isFetched } = useQuery<
    BillResponse<ItemType>,
    ItemType
  >(
    cacheKey,
    async () =>
      await fetchItem({
        query,
        uuid
      }),
    { enabled: billPaymentEnabled, staleTime: options.staleTime }
  );

  const toggleBillPaymentEnabled = useCallback<() => void>(
    () => setBillPaymentEnabled((prevState) => !prevState),
    [setBillPaymentEnabled]
  );

  const handleBillPaymentEnabled = useCallback<() => void>(
    () => setBillPaymentEnabled(true),
    [setBillPaymentEnabled]
  );

  const billPayment: ItemType | null = billPaymentEnabled
    ? data?.[itemKey]
    : null;

  return {
    billPayment,
    billPaymentError: graphqlErrorsMessages(error),
    billPaymentLoading: isLoading,
    billPaymentFetched: isFetched,
    toggleBillPaymentEnabled,
    handleBillPaymentEnabled
  };
}

export default useBillPayment;
