import { gql } from 'graphql-request';

import {
  UserAverageTasksMark,
  UserTaskRoleDebt,
  UserUUID
} from '../usersTypes';

export interface FetchUserProfileSlowFieldsQueryResponse {
  uuid: UserUUID;
  averageTasksMark: UserAverageTasksMark;
  tasksRoleDebt: UserTaskRoleDebt;
}

export const FETCH_USER_PROFILE_SLOW_FIELDS = gql`
  query UserProfileSlowFields($uuid: ID!) {
    user(uuid: $uuid) {
      uuid
      averageTasksMark
      tasksRoleDebt
    }
  }
`;
