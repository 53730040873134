import React from 'react';

import { UserID, UserNanoID } from '../../../../../../../usersTypes';

import {
  FETCH_PROJECTS_COUNT_QUERY,
  FetchProjectsCountQueryResponse
} from '../../../../../../../../projects/queries/fetchProjectsCount.query';

import { usePaginatedProjects } from '../../../../../../../../projects/hooks/usePaginatedProjects';

import { DashboardStatisticItem } from '../DashboardStatisticItem';

import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { ProjectPath } from '../../../../../../../../projects/ProjectPath';
import { profilesKeys } from '../../../../../../../../../locales/keys';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface DashboardStatisticMyProjectsProps {
  userId: UserID;
  userNanoId: UserNanoID;
  selfProfile: boolean;
}

function DashboardStatisticMyProjects({
  userId,
  selfProfile,
  userNanoId
}: DashboardStatisticMyProjectsProps) {
  const { projectsTotalCount, projectsFetched, projectsIsPlaceholderData } =
    usePaginatedProjects<FetchProjectsCountQueryResponse>({
      cacheKey: ProjectCache.userProjectsCountCacheKey(userId),
      query: FETCH_PROJECTS_COUNT_QUERY,
      initialFilters: {
        performerIds: [userId]
      }
    });

  return (
    <DashboardStatisticItem
      i18nTitle={profilesKeys.myProjects}
      icon={IconsEnum.PROJECTS}
      href={
        selfProfile
          ? ProjectPath.myProjects()
          : ProjectPath.userProjects(userNanoId)
      }
      loaded={projectsIsPlaceholderData || projectsFetched}
      value={projectsTotalCount || 0}
    />
  );
}

export default DashboardStatisticMyProjects;
