import React, { Fragment, useState } from 'react';

import { DashboardTasksItemType } from '../DashboardTasksItem/DashboardTasksItem.types';

import { Tooltip } from '../../../../../../../../../helpers/tooltips/Tooltip';
import { Translate } from '../../../../../../../../../helpers/Translate';
import { DateHelper } from '../../../../../../../../../helpers/DateHelper';

import { profilesKeys } from '../../../../../../../../../locales/keys';

interface DashboardTasksItemTaskReportsColumnProps {
  height: number;
  isCurrent?: boolean;
  tasksCount: number;
  startDate: Date;
  endDate: Date;
  type: DashboardTasksItemType;
}

function DashboardTasksItemTaskReportsColumn({
  height,
  isCurrent,
  tasksCount = 0,
  endDate,
  startDate,
  type
}: DashboardTasksItemTaskReportsColumnProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  return (
    <div className="flex items-end h-full" ref={setReferenceTooltipElement}>
      <div
        className={
          isCurrent
            ? 'rounded max-w-full w-4 bg-blue-500 dark:bg-blue-600'
            : 'rounded max-w-full w-4 bg-gray-400 dark:bg-gray-700'
        }
        style={{ height: `${height}%`, minHeight: '8px' }}
      />
      <Tooltip referenceElement={referenceTooltipElement} withArrow={true}>
        <div className="text-center text-2xs">
          <div>
            {type === DashboardTasksItemType.DAY && (
              <DateHelper date={startDate} customFormat="dd MMM" />
            )}

            {type === DashboardTasksItemType.MONTH && (
              <DateHelper date={startDate} customFormat="MMM yyyy" />
            )}

            {type === DashboardTasksItemType.WEEK && (
              <Fragment>
                <DateHelper date={startDate} customFormat="dd MMM" />
                {'-'}
                <DateHelper date={endDate} customFormat="dd MMM" />
              </Fragment>
            )}
          </div>
          <div>
            {tasksCount} <Translate id={profilesKeys.tasks} />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default DashboardTasksItemTaskReportsColumn;
