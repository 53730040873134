import { gql } from 'graphql-request';

import {
  TeamID,
  TeamName,
  TeamNanoID,
  TeamTimeZone,
  TeamUUID
} from '../teamsTypes';

export interface FetchTeamTimeZoneQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  name: TeamName;
  timeZone: TeamTimeZone;
}

export const FETCH_TEAM_TIME_ZONE_QUERY = gql`
  query TeamTimeZone($uuid: ID!) {
    team(uuid: $uuid) {
      id
      uuid
      nanoId
      name
      timeZone
    }
  }
`;
