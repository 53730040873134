import { TaskNanoID } from '../../../../tasks/tasksTypes';
import { MessageUUID } from '../../../messagesTypes';
import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';

import { useUpdateMessageVersionForm } from '../../forms/UpdateMessageVersionForm/hooks/useUpdateMessageVersionForm';

import { UpdateMessageVersionForm } from '../../forms/UpdateMessageVersionForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { tasksKeys, words } from '../../../../../locales/keys';

interface UpdateMessageVersionModalButtonProps {
  versionName: string;
  messageUuid: MessageUUID;
  taskNanoId: TaskNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
  disabled?: IsDisabled;
}

const EDIT_VERSION = 'edit-version';

function UpdateMessageVersionModalButton({
  versionName,
  messageUuid,
  taskNanoId,
  className,
  icon,
  i18nText,
  i18nTextClassName,
  tooltipSingleton,
  tooltipI18nText,
  iconClassName,
  disabled
}: UpdateMessageVersionModalButtonProps) {
  const {
    updateMessageVersionErrorMessage,
    updateMessageVersionLoading,
    updateMessageVersionReset,
    resetUpdateMessageVersionForm,
    handleUpdateMessageVersion,
    registerVersionName,
    validationErrors
  } = useUpdateMessageVersionForm({
    messageUuid,
    taskNanoId,
    versionName
  });

  return (
    <FormModalButton
      id={EDIT_VERSION}
      className={className}
      form={EDIT_VERSION}
      i18nSubmitText={words.confirm}
      i18nTitle={tasksKeys.editVersion}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      isLoading={updateMessageVersionLoading}
      onOpen={resetUpdateMessageVersionForm}
      onClose={updateMessageVersionReset}
      onSubmit={handleUpdateMessageVersion}
      icon={icon}
      iconClassName={iconClassName || 'h-5 w-5'}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      tooltipSingleton={tooltipSingleton}
      disabled={disabled}
    >
      <UpdateMessageVersionForm
        form={EDIT_VERSION}
        isLoading={updateMessageVersionLoading}
        registerVersionName={registerVersionName}
        versionNameValidationError={validationErrors.versionNameValidationError}
      />

      <div className="px-4">
        <AlertMessage message={updateMessageVersionErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateMessageVersionModalButton;
