import { useCallback } from 'react';

import { MessageUUID } from '../../../../../messagesTypes';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';

import {
  UPDATE_MESSAGE_VERSION_QUERY,
  UpdateMessageVersionRecordType
} from '../../../../../queries/updateMessageVersion.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateMessageVersion } from '../../../../../hooks/useUpdateMessageVersion';

import { formsErrors } from '../../../../../../../locales/keys';

import { TaskCache } from '../../../../../../tasks/TaskCache';

interface UpdateMessageVersionFormData {
  versionName?: string;
}

interface UpdateMessageVersionFormOptions {
  versionName: string;
  messageUuid: MessageUUID;
  taskNanoId: TaskNanoID;
}

function useUpdateMessageVersionForm({
  versionName,
  messageUuid,
  taskNanoId
}: UpdateMessageVersionFormOptions) {
  const { handleSubmitReactHookForm, isDirty, register, resetForm, errors } =
    useReactHookForm<UpdateMessageVersionFormData>({
      defaultValues: {
        versionName
      },
      isModalForm: true
    });

  const resetUpdateMessageVersionForm = useCallback(
    () => resetForm({ versionName }),
    [versionName, resetForm]
  );

  const {
    updateMessageVersion,
    updateMessageVersionLoading,
    updateMessageVersionErrorMessage,
    updateMessageVersionReset
  } = useUpdateMessageVersion<UpdateMessageVersionRecordType>({
    query: UPDATE_MESSAGE_VERSION_QUERY,
    cacheKeys: [
      TaskCache.showCacheKey(),
      TaskCache.indexCacheKey(),
      TaskCache.messagesCacheKey(taskNanoId)
    ]
  });

  return {
    updateMessageVersion,
    updateMessageVersionLoading,
    updateMessageVersionErrorMessage,
    updateMessageVersionReset,
    resetUpdateMessageVersionForm,
    handleUpdateMessageVersion: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateMessageVersionFormData) =>
        updateMessageVersion({
          uuid: messageUuid,
          body: data.versionName
        }),
      dirtyFieldsOnly: false
    }),
    isDirty,
    registerVersionName: register('versionName', {
      required: formsErrors.required
    }),
    validationErrors: {
      versionNameValidationError: errors?.versionName?.message
    }
  };
}

export default useUpdateMessageVersionForm;
