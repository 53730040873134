import React from 'react';

import { UserID, UserNanoID } from '../../../../../usersTypes';
import {
  FetchTasksSortTypes,
  TaskStatuses
} from '../../../../../../tasks/tasksTypes';

import {
  FETCH_TASKS_RESULTS_QUERY,
  FetchTasksResultsQueryResponse
} from '../../../../../../tasks/queries/fetchTasksResults.query';

import { useTasks } from '../../../../../../tasks/hooks/useTasks';

import { DashboardTasksResultsList } from '../../../../../../tasks/components/lists/DashboardTasksResultsList';

import { Translate } from '../../../../../../../helpers/Translate';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { TaskCache } from '../../../../../../tasks/TaskCache';
import { TaskPath } from '../../../../../../tasks/TaskPath';

import { profilesKeys, words } from '../../../../../../../locales/keys';

const initialLimit = 24;

interface DashboardLatestResultsUser {
  id: UserID;
  nanoId: UserNanoID;
}

interface DashboardLatestResultsProps {
  selfProfile: boolean;
  user: DashboardLatestResultsUser;
}

function DashboardLatestResults({
  selfProfile,
  user
}: DashboardLatestResultsProps) {
  const {
    tasks,
    tasksError,
    tasksTotalCount,
    tasksFetched,
    tasksIsPlaceholderData
  } = useTasks<FetchTasksResultsQueryResponse>({
    cacheKey: TaskCache.userResultsCacheKey(),
    query: FETCH_TASKS_RESULTS_QUERY,
    initialLimit,
    initialSort: [FetchTasksSortTypes.RESULTS_UPLOADED_AT_DESC],
    initialFilters: {
      status: { eq: TaskStatuses.READY_FOR_ACCEPTANCE },
      visibleResultsCount: { gt: 0 },
      performerIds: [user.id]
    }
  });

  if ((tasksIsPlaceholderData || tasksFetched) && tasksTotalCount === 0) {
    return null;
  }

  return (
    <div>
      <div className="flex gap-2 mb-4">
        <div className="flex items-center gap-2">
          <div className="uppercase dark:text-gray-300">
            <Translate id={profilesKeys.latestResults} />
          </div>
          <div className="inline-block">
            <NextPureLinkHelper
              className="py-0.5 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 border-gray-400 bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 border"
              href={
                selfProfile
                  ? TaskPath.myResults()
                  : TaskPath.userResults(user.nanoId)
              }
              i18nText={words.showAll}
            />
          </div>
        </div>

        <div className="flex-1 relative flex items-center">
          <hr className="w-full dark:border-gray-800" />
        </div>
      </div>

      <AlertMessage addClassName="m-4" message={tasksError} />
      <LoadingSkeleton loaded={tasksIsPlaceholderData || tasksFetched}>
        <DashboardTasksResultsList tasks={tasks} />
      </LoadingSkeleton>
    </div>
  );
}

export default DashboardLatestResults;
