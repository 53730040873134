import { useEffect, useMemo, useState } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';

import { GroupedItemsGroupBy } from '../../../groupedItems/groupedItemsTypes';
import { InvoiceStatuses } from '../../../invoices/invoicesTypes';
import { TaskNanoID } from '../../tasksTypes';
import {
  TasksWithItemsAndPriceBaseType,
  TasksWithItemsAndPriceOptions,
  TasksWithItemsAndPriceType
} from './useTasksWithItemsAndPrice.types';

import {
  FETCH_TASKS_GROUPED_ITEMS_QUERY,
  FetchTasksGroupedItemsQueryResponse
} from '../../queries/fetchTasksGroupedItems.query';
import {
  FETCH_PAGINATED_TASKS_PRICE_QUERY,
  FetchPaginatedTasksPriceQueryResponse
} from '../../queries/fetchPaginatedTasksPrice.query';

import { useFinPaginatedGroupedItems } from '../../../groupedItems/hooks/useFinPaginatedGroupedItems';
import { useFinPaginatedTasksPrice } from '../../../tasksPrice/hooks/useFinPaginatedTasksPrice';
import { usePaginatedTasks } from '../usePaginatedTasks';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

import { TaskCache } from '../../TaskCache';

const groupedItemsInitialLimit = 1000;

function useTasksWithItemsAndPrice<
  TaskType extends TasksWithItemsAndPriceBaseType
>({
  cacheKey,
  query,
  fetchItemCacheKey,
  fetchItemQuery,
  initialFilters,
  initialLimit,
  initialPage,
  initialSort,
  options
}: TasksWithItemsAndPriceOptions) {
  const {
    tasks,
    tasksError,
    tasksTotalCount,
    tasksFetched,
    tasksLoading,
    tasksIsPlaceholderData,
    tasksFilters,
    tasksSort,
    tasksPage,
    tasksLimit,
    updateTaskCache,
    filterTasks,
    changeTasksFilters,
    clearTasksFiltersPersistInitial,
    clearTasksFilters,
    sortTasks,
    paginateTasks,
    limitTasks,
    prefetchTasks,
    prefetchTask,
    handleResetFilters
  } = usePaginatedTasks<TaskType>({
    cacheKey,
    query,
    fetchItemCacheKey,
    fetchItemQuery,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options
  });

  const [withGroupedItemsAndPrice, setWithGroupedItemsAndPrice] =
    useState<boolean>(false);

  const taskNanoIds = useMemo<TaskNanoID[]>(
    () => map(tasks, 'nanoId'),
    [tasks]
  );

  const {
    tasksPrice,
    tasksPriceFetched,
    tasksPriceLoading,
    tasksPriceError,
    filterTasksPrice
  } = useFinPaginatedTasksPrice<FetchPaginatedTasksPriceQueryResponse>({
    cacheKey: TaskCache.tasksPriceCacheKey(taskNanoIds),
    query: FETCH_PAGINATED_TASKS_PRICE_QUERY,
    initialFilters: {
      nanoId: { in: taskNanoIds }
    },
    options: {
      enabled: withGroupedItemsAndPrice,
      enabledPlaceholder: withGroupedItemsAndPrice,
      withoutPrefetch: true
    }
  });

  useEffect(() => {
    if (isEmpty(taskNanoIds)) {
      return;
    }

    filterTasksPrice({
      nanoId: { in: taskNanoIds }
    });
  }, [taskNanoIds, filterTasksPrice]);

  const additionalParams = useMemo(
    () => ({
      groupBy: ['item', 'task'] as GroupedItemsGroupBy[],
      groupItemsByType: true,
      showTotal: true
    }),
    []
  );

  const {
    groupedItems,
    groupedItemsFetched,
    groupedItemsFilters,
    groupedItemsIsPlaceholderData,
    filterGroupedItems
  } = useFinPaginatedGroupedItems<FetchTasksGroupedItemsQueryResponse>({
    query: FETCH_TASKS_GROUPED_ITEMS_QUERY,
    cacheKey: TaskCache.tasksItemsCacheKey(taskNanoIds),
    initialFilters: {
      invoiceStatus: {
        notIn: [InvoiceStatuses.CANCELED, InvoiceStatuses.VOID]
      },
      taskNanoId: isEmpty(taskNanoIds) ? undefined : { in: taskNanoIds }
      // withoutParentInvoice: true
      // currentOnly: true
      // smartContractStatus: { eq: SmartContractStatuses.OPEN }
    },
    options: {
      enabled: withGroupedItemsAndPrice,
      enabledPlaceholder: withGroupedItemsAndPrice,
      withoutPrefetch: true
    },
    initialLimit: groupedItemsInitialLimit,
    additionalParams
  });

  const prevTaskNanoIds = usePreviousValue(taskNanoIds);

  useEffect(() => {
    if (isEmpty(taskNanoIds)) {
      return;
    }

    if (prevTaskNanoIds !== taskNanoIds) {
      filterGroupedItems({
        ...groupedItemsFilters,
        taskNanoId: {
          in: taskNanoIds
        }
      });
      setWithGroupedItemsAndPrice(true);
    }
  }, [
    filterGroupedItems,
    groupedItems,
    groupedItemsFilters,
    prevTaskNanoIds,
    taskNanoIds
  ]);

  const tasksWithPrice = useMemo<TasksWithItemsAndPriceType<TaskType>[]>(() => {
    if (isEmpty(taskNanoIds)) {
      return tasks;
    }

    return map(tasks, (task) => {
      const taskPrice = task.nanoId
        ? find(tasksPrice, ['taskNanoId', task.nanoId])
        : null;

      const taskItems = uniqBy(
        map(filter(groupedItems, ['taskNanoId', task.nanoId]), (item) => ({
          id: item.itemId || head(item.itemsIds),
          name: item.itemName
        })),
        'name'
      );

      const newTask = {
        ...task,
        items: taskItems
      };

      if (taskPrice) {
        return {
          ...newTask,
          openRegular: taskPrice.openRegular,
          paidRegular: taskPrice.paidRegular,
          price: taskPrice.price,
          sentRegular: taskPrice.sentRegular,
          smartContractLastStatusChangeAt:
            taskPrice.smartContractLastStatusChangeAt
        };
      }

      return newTask;
    });
  }, [taskNanoIds, tasks, tasksPrice, groupedItems]);

  return {
    changeTasksFilters,
    clearTasksFilters,
    clearTasksFiltersPersistInitial,
    filterTasks,
    groupedItemsFetched: groupedItemsFetched || groupedItemsIsPlaceholderData,
    handleResetFilters,
    limitTasks,
    paginateTasks,
    prefetchTask,
    prefetchTasks,
    sortTasks,
    tasks: tasksWithPrice,
    tasksError: tasksError || tasksPriceError,
    tasksFetched: isEmpty(taskNanoIds)
      ? tasksFetched
      : tasksFetched && tasksPriceFetched && groupedItemsFetched,
    tasksFilters,
    tasksIsPlaceholderData,
    tasksLimit,
    tasksLoading: isEmpty(taskNanoIds)
      ? tasksLoading
      : tasksLoading && tasksPriceLoading && groupedItemsFetched,
    tasksPage,
    tasksSort,
    tasksTotalCount: tasksTotalCount,
    updateTaskCache
  };
}

export default useTasksWithItemsAndPrice;
