import { useCallback } from 'react';

import { ProjectNanoID } from '../../../../main/projects/projectsTypes';
import { TaskNanoID } from '../../../../main/tasks/tasksTypes';

import { ImageEditorOnSubmit } from '../../ImageEditor.types';

import {
  CreateMessageWithProjectNanoIdQueryResponse,
  CREATE_MESSAGE_WITH_PROJECT_NANO_ID
} from '../../../../main/messages/queries/createMessageWithProjectNanoId.query';

import {
  CreateMessageWithTaskNanoIdQueryResponse,
  CREATE_MESSAGE_WITH_TASK_NANO_ID
} from '../../../../main/messages/queries/createMessageWithTaskNanoId.query';

import { useCreateMessageWithProjectNanoId } from '../../../../main/messages/hooks/useCreateMessageWithProjectNanoId';
import { useCreateMessageWithTaskNanoId } from '../../../../main/messages/hooks/useCreateMessageWithTaskNanoId';

interface ImageEditorSubmitWithProjectNanoIdOptions {
  projectNanoId: ProjectNanoID;
  taskNanoId?: never;
}

interface ImageEditorSubmitWithTaskNanoIdOptions {
  projectNanoId?: never;
  taskNanoId: TaskNanoID;
}

type ImageEditorSubmitWithItemNanoIdOptions =
  | ImageEditorSubmitWithProjectNanoIdOptions
  | ImageEditorSubmitWithTaskNanoIdOptions;

function useImageEditorSubmitWithItemNanoId({
  projectNanoId,
  taskNanoId
}: ImageEditorSubmitWithItemNanoIdOptions) {
  const { createMessageWithTaskNanoId } =
    useCreateMessageWithTaskNanoId<CreateMessageWithTaskNanoIdQueryResponse>({
      taskNanoId,
      query: CREATE_MESSAGE_WITH_TASK_NANO_ID
    });

  const { createMessageWithProjectNanoId } =
    useCreateMessageWithProjectNanoId<CreateMessageWithProjectNanoIdQueryResponse>(
      {
        projectNanoId,
        query: CREATE_MESSAGE_WITH_PROJECT_NANO_ID
      }
    );

  const handleImageEditorSubmitWithItemNanoId =
    useCallback<ImageEditorOnSubmit>(
      (data) => {
        if (taskNanoId) {
          return createMessageWithTaskNanoId({
            ...data,
            taskNanoId
          });
        }
        if (projectNanoId) {
          return createMessageWithProjectNanoId({
            ...data,
            projectNanoId
          });
        }
      },
      [
        taskNanoId,
        projectNanoId,
        createMessageWithTaskNanoId,
        createMessageWithProjectNanoId
      ]
    );

  return {
    handleImageEditorSubmitWithItemNanoId:
      projectNanoId || taskNanoId
        ? handleImageEditorSubmitWithItemNanoId
        : undefined
  };
}

export default useImageEditorSubmitWithItemNanoId;
