import { gql } from 'graphql-request';

export const BATCH_CHECK_IN_TO_TASKS = gql`
  mutation BatchCheckInToTasks($taskIds: [ID!]!, $contractorCompetencyId: ID!) {
    batchCheckInToTasks(
      input: {
        taskIds: $taskIds
        contractorCompetencyId: $contractorCompetencyId
      }
    ) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
