import { useCallback } from 'react';

import { ID } from '../../../../types';

import {
  TaskMemberInviteGqlError,
  UpdateTaskMemberInviteCacheKeys,
  UpdateTaskMemberInviteGqlQuery,
  UpdateTaskMemberInviteGqlStatus
} from '../../taskMemberInvitesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { generateNanoId } from '../../../../utils/generateNanoId';

interface AcceptAllTaskMemberInvitesOptions {
  query: UpdateTaskMemberInviteGqlQuery;
  cacheKeys: UpdateTaskMemberInviteCacheKeys;
}

interface AcceptTaskMemberInvitesInput {
  clientMutationId: ID;
}

interface AcceptAllTaskMemberInvitesError {
  fullMessages: TaskMemberInviteGqlError;
}

interface AcceptAllTaskMemberInvitesResponse {
  acceptAllTaskMemberInvites: {
    status: UpdateTaskMemberInviteGqlStatus;
    errors: AcceptAllTaskMemberInvitesError;
  };
}

const action = 'acceptAllTaskMemberInvites';

function useAcceptAllTaskMemberInvites({
  query,
  cacheKeys
}: AcceptAllTaskMemberInvitesOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    AcceptTaskMemberInvitesInput,
    AcceptAllTaskMemberInvitesResponse,
    AcceptAllTaskMemberInvitesError,
    undefined
  >({ action, query, cacheKeys });

  return {
    acceptAllTaskMemberInvitesData: updateQueryData,
    acceptAllTaskMemberInvitesError: updateQueryError,
    acceptAllTaskMemberInvitesLoading: updateQueryLoading,
    acceptAllTaskMemberInvitesErrorMessage: updateQueryErrorMessage,
    acceptAllTaskMemberInvites: useCallback<
      () => Promise<AcceptAllTaskMemberInvitesResponse>
    >(() => updateQuery({ clientMutationId: generateNanoId() }), [updateQuery]),
    acceptAllTaskMemberInvitesReset: updateQueryReset
  };
}

export default useAcceptAllTaskMemberInvites;
