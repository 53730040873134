import React, { Fragment, useMemo } from 'react';
import find from 'lodash/find';
import some from 'lodash/some';

import { TaskNanoID } from '../../../../../../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../../../../../../assets/icons/types';
import {
  SmartContractSmartContractTypes,
  SmartContractStatuses
} from '../../../../../../../../../../../smartContracts/smartContractsTypes';

import {
  FETCH_TASK_SMART_CONTRACTS_QUERY,
  FetchTaskSmartContractsQueryResponse
} from '../../../../../../../../../../../smartContracts/queries/fetchTaskSmartContracts.query';

import { useFinPaginatedSmartContracts } from '../../../../../../../../../../../smartContracts/hooks/useFinPaginatedSmartContracts';
import { useCurrentUser } from '../../../../../../../../../../../../auth/hooks/useAuth';

import { TaskCheckInWithAcceptInviteButton } from '../../../../../../../../../../../tasks/components/modalButtons/TaskCheckInWithAcceptInviteButton';
import { TaskCheckInToSmartContractShareButton } from '../../../../../../../../../../../tasks/components/modalButtons/TaskCheckInToSmartContractShareButton';

import { CheckPermissions } from '../../../../../../../../../../../../helpers/CheckPermissions';

import { SmartContractSharesPermissions } from '../../../../../../../../../../../smartContractShares/smartContractSharesConstants';

import { SmartContractCache } from '../../../../../../../../../../../smartContracts/SmartContractCache';
import { TaskMemberInviteCache } from '../../../../../../../../../../../taskMemberInvites/TaskMemberInviteCache';

import { SmartContractShareStatuses } from '../../../../../../../../../../../smartContractShares/smartContractSharesTypes';
import { tasksKeys } from '../../../../../../../../../../../../locales/keys';

interface DashboardActiveTaskMessageHeaderTaskCheckInWrapperProps {
  taskNanoId: TaskNanoID;
  onlyInvitations?: boolean;
}

function DashboardActiveTaskMessageHeaderTaskCheckInWrapper({
  taskNanoId,
  onlyInvitations
}: DashboardActiveTaskMessageHeaderTaskCheckInWrapperProps) {
  const currentUser = useCurrentUser();

  const { smartContracts } =
    useFinPaginatedSmartContracts<FetchTaskSmartContractsQueryResponse>({
      query: FETCH_TASK_SMART_CONTRACTS_QUERY,
      cacheKey: SmartContractCache.taskSmartContractsCacheKey(taskNanoId),
      initialFilters: { taskNanoId: { eq: taskNanoId } },
      options: {
        withoutPrefetch: true,
        enabled: !!taskNanoId,
        enabledPlaceholder: !!taskNanoId
      }
    });

  const draftSmartContract = useMemo(
    () =>
      find(
        smartContracts,
        (smartContract) => smartContract?.status === SmartContractStatuses.DRAFT
      ),
    [smartContracts]
  );

  const draftSmartContractWithInvited = useMemo(
    () =>
      some(
        draftSmartContract?.smartContractShares,
        (smartContractShare) =>
          smartContractShare.status === SmartContractShareStatuses.INVITED &&
          smartContractShare.generalLedger?.company?.owner?.id ===
            currentUser.id
      )
        ? draftSmartContract
        : undefined,
    [currentUser.id, draftSmartContract]
  );

  const smartContractShareId = useMemo(
    () =>
      draftSmartContractWithInvited?.smartContractShares?.find(
        (smartContractShare) =>
          draftSmartContractWithInvited?.status ===
            SmartContractStatuses.DRAFT &&
          smartContractShare?.generalLedger?.company?.owner?.id ===
            currentUser.id
      )?.id,
    [draftSmartContractWithInvited, currentUser.id]
  );

  return (
    <Fragment>
      {draftSmartContract?.smartContractType ===
        SmartContractSmartContractTypes.RULES && (
        <CheckPermissions
          action={
            SmartContractSharesPermissions.READ_CHECK_IN_SMART_CONTRACT_RULE_BUTTON
          }
        >
          <TaskCheckInWithAcceptInviteButton
            taskNanoId={taskNanoId}
            smartContractNanoId={draftSmartContract?.nanoId}
            icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
            className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            iconClassName="h-6 w-6"
            cacheKeys={[
              TaskMemberInviteCache.userTaskMemberInvitesCacheKey(
                currentUser.nanoId
              )
            ]}
          />
        </CheckPermissions>
      )}

      {!onlyInvitations &&
        draftSmartContract?.smartContractType ===
          SmartContractSmartContractTypes.SHARES && (
          <CheckPermissions
            action={
              SmartContractSharesPermissions.READ_CHECK_IN_SMART_CONTRACT_SHARE_BUTTON
            }
          >
            <TaskCheckInToSmartContractShareButton
              smartContractId={draftSmartContract?.id}
              cacheKeys={[
                TaskMemberInviteCache.userTaskMemberInvitesCacheKey(
                  currentUser.nanoId
                )
              ]}
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              tooltipI18nText={tasksKeys.buttons.checkIn}
              icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
              iconClassName="h-6 w-6"
            />
          </CheckPermissions>
        )}

      {onlyInvitations &&
        draftSmartContractWithInvited?.smartContractType ===
          SmartContractSmartContractTypes.SHARES &&
        smartContractShareId && (
          <CheckPermissions
            action={
              SmartContractSharesPermissions.READ_CHECK_IN_SMART_CONTRACT_SHARE_BUTTON
            }
          >
            <TaskCheckInToSmartContractShareButton
              checkInSmartContractShareId={smartContractShareId}
              smartContractId={draftSmartContractWithInvited?.id}
              cacheKeys={[
                TaskMemberInviteCache.userTaskMemberInvitesCacheKey(
                  currentUser.nanoId
                )
              ]}
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              tooltipI18nText={tasksKeys.buttons.checkIn}
              icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
              iconClassName="h-6 w-6"
            />
          </CheckPermissions>
        )}
    </Fragment>
  );
}

export default DashboardActiveTaskMessageHeaderTaskCheckInWrapper;
