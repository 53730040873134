import { gql } from 'graphql-request';

export const TRACK_TASKS_TIME_QUERY = gql`
  mutation TrackTasksTime($trackedTime: [TrackTasksTimeInput!]!) {
    trackTasksTime(input: { trackedTime: $trackedTime }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
