import Markdown from 'react-markdown';
import { PluggableList } from 'unified';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { ClassName } from '../../../../types';

const components = {
  a: ({ node, ...props }) => <a className="underline" {...props} />
};

const rehypeSanitizeSchema = {
  ...defaultSchema,
  protocols: {
    src: [...(defaultSchema.protocols?.src ?? []), 'data']
  }
};

const rehypePlugins = [rehypeRaw, [rehypeSanitize, rehypeSanitizeSchema]];

const remarkPlugins = [remarkBreaks, remarkGfm];

interface ReactMarkdownProps {
  allowedElements?: string[];
  children: string;
  className?: ClassName;
  linkTarget?: '_blank';
  unwrapDisallowed?: boolean;
}

function ReactMarkdown({
  allowedElements,
  children,
  className,
  linkTarget,
  unwrapDisallowed
}: ReactMarkdownProps) {
  return (
    <Markdown
      allowedElements={allowedElements}
      className={className}
      components={components}
      linkTarget={linkTarget}
      rehypePlugins={rehypePlugins as PluggableList}
      remarkPlugins={remarkPlugins}
      unwrapDisallowed={unwrapDisallowed}
    >
      {children}
    </Markdown>
  );
}

export default ReactMarkdown;
