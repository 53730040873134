export enum TypeDataEnum {
  QUANTITY = 'quantity',
  AMOUNT = 'amount'
}

export const enum DashboardFinanceDebtCategoryName {
  LESS_WEEK = 'lessWeek',
  LESS_TWO_WEEK = 'lessTwoWeek',
  LESS_MONTH = 'lessMonth',
  LESS_TWO_MONTH = 'lessTwoMonth',
  MORE_TWO_MONTH = 'moreTwoMonth',
  UNCOLLECTIBLE = 'uncollectible'
}

export const DashboardFinanceDebtCategoryIndex = {
  [DashboardFinanceDebtCategoryName.LESS_WEEK]: 1,
  [DashboardFinanceDebtCategoryName.LESS_TWO_WEEK]: 2,
  [DashboardFinanceDebtCategoryName.LESS_MONTH]: 3,
  [DashboardFinanceDebtCategoryName.LESS_TWO_MONTH]: 4,
  [DashboardFinanceDebtCategoryName.MORE_TWO_MONTH]: 5,
  [DashboardFinanceDebtCategoryName.UNCOLLECTIBLE]: 6
};

interface ItemGroupedByCategoryQuantityType {
  categoryName: DashboardFinanceDebtCategoryName;
  amount?: never;
  quantity: number;
  index: number;
}

interface ItemGroupedByCategoryAmountType {
  categoryName: DashboardFinanceDebtCategoryName;
  amount: number;
  quantity?: never;
  index: number;
}

export type DashboardFinanceDebtItemGroupedByCategoryType =
  | ItemGroupedByCategoryQuantityType
  | ItemGroupedByCategoryAmountType;
