import React, { Fragment, useCallback, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import {
  DashboardActiveTasksListActiveTasks,
  DashboardActiveTasksListInvitations,
  DashboardActiveTasksListItemScope,
  DashboardActiveTasksListSelectedItem,
  DashboardActiveTasksListTabNames,
  DashboardActiveTasksListUser
} from './DashboardActiveTasksList.types';

import { DashboardActiveTasksListItemsList } from './components/DashboardActiveTasksListItemsList';
import { DashboardActiveTasksListTabs } from './components/DashboardActiveTasksListTabs';
import { TaskMemberInvitesList } from '../../../../../../../../taskMemberInvites/components/lists/TaskMemberInvitesList';

import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { TaskMemberInviteCache } from '../../../../../../../../taskMemberInvites/TaskMemberInviteCache';

import { words } from '../../../../../../../../../locales/keys';

interface DashboardActiveTasksListProps {
  selectTab: DashboardActiveTasksListTabNames;
  setSelectTab: (tab: DashboardActiveTasksListTabNames) => void;

  user: DashboardActiveTasksListUser;
  activeTasks: DashboardActiveTasksListActiveTasks;
  qualityControlTasks: DashboardActiveTasksListActiveTasks;
  taskMemberInvites: DashboardActiveTasksListInvitations;
  activeTasksCount: number;
  qualityControlTasksTotalCount: number;
  invitationsCount: number;
  selfProfile: boolean;
  selectedItem: DashboardActiveTasksListSelectedItem;
  onSelectedItem: (value: DashboardActiveTasksListSelectedItem) => void;
  activeTasksError: string;
  hasNextActiveTasksPage: boolean;
  loadMoreActiveTasks: () => void;

  qualityControlTasksError: string;
  hasNextQualityControlTasksPage: boolean;
  loadMoreQualityControlTasks: () => void;

  taskMemberInvitesError: string;
  hasNextTaskMemberInvitesPage: boolean;
  loadMoreTaskMemberInvites: () => void;

  isLoading: boolean;
}

function DashboardActiveTasksList({
  selectTab,
  setSelectTab,
  user,
  activeTasks,
  qualityControlTasks,
  qualityControlTasksTotalCount,
  taskMemberInvites,
  activeTasksCount,
  invitationsCount,
  selfProfile,
  onSelectedItem,
  selectedItem,
  activeTasksError,
  hasNextActiveTasksPage,
  loadMoreActiveTasks,
  qualityControlTasksError,
  hasNextQualityControlTasksPage,
  loadMoreQualityControlTasks,
  taskMemberInvitesError,
  hasNextTaskMemberInvitesPage,
  loadMoreTaskMemberInvites,
  isLoading
}: DashboardActiveTasksListProps) {
  const handleChangeTab = useCallback<
    (tab: DashboardActiveTasksListTabNames) => void
  >(
    (tab) => {
      setSelectTab(tab);
      const newSelectedItem: DashboardActiveTasksListSelectedItem =
        (tab === DashboardActiveTasksListTabNames.INVITATIONS && {
          nanoId: taskMemberInvites[0]?.bitTask.nanoId,
          scope: DashboardActiveTasksListItemScope.TASK
        }) ||
        (tab === DashboardActiveTasksListTabNames.ACTIVE_TASKS && {
          nanoId: activeTasks[0]?.nanoId,
          scope: activeTasks[0]?.scope
        }) ||
        (tab === DashboardActiveTasksListTabNames.QUALITY_CONTROL && {
          nanoId: qualityControlTasks[0]?.nanoId,
          scope: qualityControlTasks[0]?.scope
        });

      onSelectedItem(newSelectedItem);
    },
    [
      setSelectTab,
      taskMemberInvites,
      activeTasks,
      qualityControlTasks,
      onSelectedItem
    ]
  );

  useEffect(() => {
    selectTab === DashboardActiveTasksListTabNames.INVITATIONS &&
      isEmpty(taskMemberInvites) &&
      handleChangeTab(DashboardActiveTasksListTabNames.ACTIVE_TASKS);
  }, [selectTab, taskMemberInvites, handleChangeTab]);

  useEffect(() => {
    selectTab === DashboardActiveTasksListTabNames.QUALITY_CONTROL &&
      isEmpty(qualityControlTasks) &&
      handleChangeTab(DashboardActiveTasksListTabNames.ACTIVE_TASKS);
  }, [handleChangeTab, qualityControlTasks, selectTab]);

  return (
    <div className="w-full h-full flex flex-col absolute inset-0 lg:static">
      <div className="py-2 px-3">
        <DashboardActiveTasksListTabs
          activeTab={selectTab}
          changeTab={handleChangeTab}
          activeTasksCount={activeTasksCount}
          invitationsCount={invitationsCount}
          invitationsTasks={map(taskMemberInvites, 'task')}
          qualityControlTasksTotalCount={qualityControlTasksTotalCount}
          selfProfile={selfProfile}
          cacheKeys={[
            TaskMemberInviteCache.userTaskMemberInvitesCacheKey(user.nanoId)
          ]}
        />
      </div>

      <div className="lg:max-h-[444px] flex-1 overflow-y-auto">
        {selectTab === DashboardActiveTasksListTabNames.ACTIVE_TASKS && (
          <Fragment>
            <DashboardActiveTasksListItemsList
              activeTasks={activeTasks}
              selectedItem={selectedItem}
              onSelectedItem={onSelectedItem}
            />

            <FetchMoreInfiniteButtonHelper
              errorMessage={activeTasksError}
              isLoading={isLoading}
              hasNextPage={hasNextActiveTasksPage}
              i18nText={words.loadMore}
              className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              wrapperClassName="text-center mt-8"
              placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
              onFetchMore={loadMoreActiveTasks}
            />
          </Fragment>
        )}

        {selectTab === DashboardActiveTasksListTabNames.INVITATIONS && (
          <Fragment>
            <TaskMemberInvitesList
              taskMemberInvites={taskMemberInvites}
              selectedItem={selectedItem}
              onSelectedItem={onSelectedItem}
              withActions={selfProfile}
              cacheKeys={[
                TaskMemberInviteCache.userTaskMemberInvitesCacheKey(user.nanoId)
              ]}
            />

            <FetchMoreInfiniteButtonHelper
              errorMessage={taskMemberInvitesError}
              isLoading={isLoading}
              hasNextPage={hasNextTaskMemberInvitesPage}
              i18nText={words.loadMore}
              className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              wrapperClassName="text-center mt-8"
              placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
              onFetchMore={loadMoreTaskMemberInvites}
            />
          </Fragment>
        )}

        {selectTab === DashboardActiveTasksListTabNames.QUALITY_CONTROL && (
          <Fragment>
            <DashboardActiveTasksListItemsList
              activeTasks={qualityControlTasks}
              selectedItem={selectedItem}
              onSelectedItem={onSelectedItem}
            />

            <FetchMoreInfiniteButtonHelper
              errorMessage={qualityControlTasksError}
              isLoading={isLoading}
              hasNextPage={hasNextQualityControlTasksPage}
              i18nText={words.loadMore}
              className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              wrapperClassName="text-center mt-8"
              placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
              onFetchMore={loadMoreQualityControlTasks}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default DashboardActiveTasksList;
