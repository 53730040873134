import React from 'react';

import { ClassName } from '../../../../../../types';
import {
  InvoiceReturnReasons,
  InvoiceStatuses
} from '../../../../invoicesTypes';

import { Translate } from '../../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../../helpers/MoneyHelper';

import { invoicesKeys } from '../../../../../../locales/keys';

interface InvoicesPaymentsPartsNameProps {
  status?: InvoiceStatuses;
  amount: number;
  className?: ClassName;
}

const getInvoiceStatusColor = (status?: string) => {
  switch (status) {
    case InvoiceStatuses.CANCELED:
      return 'bg-red-50 border-red-50';
    case InvoiceStatuses.DRAFT:
      return 'bg-gray-500 border-gray-500';
    case InvoiceStatuses.OPEN:
      return 'bg-yellow-500 border-yellow-500';
    case InvoiceStatuses.PAID:
      return 'bg-green-500 border-green-500';
    case InvoiceStatuses.SENT:
      return 'bg-blue-400 border-blue-400';
    case InvoiceStatuses.UNCOLLECTIBLE:
      return 'bg-red-500 border-red-500';
    case InvoiceStatuses.VOID:
      return 'bg-gray-500 border-gray-500';
    case InvoiceStatuses.NONE:
      return 'bg-gray-500 border-gray-500';
    case InvoiceReturnReasons.REFUND:
      return 'bg-red-500 border-red-500';
    case InvoiceReturnReasons.CHARGEBACK:
      return 'bg-red-500 border-red-500';
    default:
      return 'bg-gray-500 border-gray-500 opacity-50';
  }
};

const getInvoiceStatusText = (status?: string) => {
  switch (status) {
    case InvoiceStatuses.CANCELED:
      return invoicesKeys.statuses.canceled;
    case InvoiceStatuses.DRAFT:
      return invoicesKeys.statuses.draft;
    case InvoiceStatuses.OPEN:
      return invoicesKeys.statuses.open;
    case InvoiceStatuses.PAID:
      return invoicesKeys.statuses.paid;
    case InvoiceStatuses.SENT:
      return invoicesKeys.statuses.sent;
    case InvoiceStatuses.UNCOLLECTIBLE:
      return invoicesKeys.statuses.uncollectible;
    case InvoiceStatuses.VOID:
      return invoicesKeys.statuses.void;
    case InvoiceStatuses.NONE:
      return invoicesKeys.statuses.none;
    case InvoiceReturnReasons.REFUND:
      return invoicesKeys.refund;
    case InvoiceReturnReasons.CHARGEBACK:
      return invoicesKeys.chargeback;
    default:
      return invoicesKeys.statuses.upcoming;
  }
};

function InvoicesPaymentsPartsName({
  status,
  amount,
  className
}: InvoicesPaymentsPartsNameProps) {
  return (
    <div className={className}>
      <div className="text-xs w-full">
        <div className="flex gap-1 items-center">
          <div className="flex items-center">
            <div
              className={`rounded-full border-2 w-2 h-2 ${getInvoiceStatusColor(
                status
              )}`}
            />
          </div>

          <div className="w-24">
            <Translate id={getInvoiceStatusText(status)} />
          </div>
          <div className="w-full text-right">
            <MoneyHelper value={amount} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoicesPaymentsPartsName;
