import React from 'react';

import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import { DashboardFinanceSmartContracts } from './components/DashboardFinanceSmartContracts';
import { DashboardFinanceDept } from './components/DashboardFinanceDept';
import { DashboardFinanceBalancesChart } from './components/DashboardFinanceBalancesChart';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../../../helpers/Translate';

import { UsersPermissions } from '../../../../../usersConstants';
import { profilesKeys } from '../../../../../../../locales/keys';

interface DashboardFinanceProps {
  selfProfile: boolean;
  client: boolean;
  teamNanoId: TeamNanoID;
}

function DashboardFinance({
  selfProfile,
  teamNanoId,
  client
}: DashboardFinanceProps) {
  return (
    <div>
      <div className="flex gap-2 mb-4">
        <div className="uppercase dark:text-gray-300">
          <Translate id={profilesKeys.finance} />
        </div>

        <div className="flex-1 relative flex items-center">
          <hr className="w-full dark:border-gray-800" />
        </div>
      </div>

      <CheckPermissions
        action={
          selfProfile
            ? UsersPermissions.READ_SELF_DASHBOARD_BALANCE_CHART_FIELD
            : UsersPermissions.READ_USER_DASHBOARD_BALANCE_CHART_FIELD
        }
      >
        <DashboardFinanceBalancesChart
          teamNanoId={teamNanoId}
          client={client}
        />
      </CheckPermissions>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_DEBT_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_DEBT_FIELD
          }
        >
          <DashboardFinanceDept
            selfProfile={selfProfile}
            teamNanoId={teamNanoId}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_SMART_CONTRACTS_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_SMART_CONTRACTS_FIELD
          }
        >
          <DashboardFinanceSmartContracts
            selfProfile={selfProfile}
            teamNanoId={teamNanoId}
          />
        </CheckPermissions>
      </div>
    </div>
  );
}

export default DashboardFinance;
