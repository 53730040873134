import React from 'react';

import { UserNanoID, UserUUID } from '../../../../../../../usersTypes';

import {
  FETCH_TRACKED_TIME_INTERVALS_QUERY,
  FetchTrackedTimeIntervalQueryResponse
} from '../../../../../../../../trackedTimeIntervals/queries/fetchTrackedTimeIntervals.query';

import { usePaginatedTrackedTimeIntervals } from '../../../../../../../../trackedTimeIntervals/hooks/usePaginatedTrackedTimeIntervals';

import { PeriodHelper } from '../../../../../../../../../helpers/PeriodHelper';

import { DashboardStatisticItem } from '../DashboardStatisticItem';

import { getTotalTime } from '../../../../../../../../trackedTimeIntervals/utils/getTotalTime';
import { DateFilterTypeConverter } from '../../../../../../../../../utils/DateFilterTypeConverter';

import { MessagePath } from '../../../../../../../../messages/MessagePath';
import { TrackedTimeIntervalCache } from '../../../../../../../../trackedTimeIntervals/TrackedTimeIntervalCache';
import { profilesKeys } from '../../../../../../../../../locales/keys';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface DashboardStatisticTotalHoursDayProps {
  userUuid: UserUUID;
  userNanoId: UserNanoID;
  selfProfile: boolean;
}

function DashboardStatisticTotalHoursDay({
  userUuid,
  selfProfile,
  userNanoId
}: DashboardStatisticTotalHoursDayProps) {
  const {
    trackedTimeIntervals,
    trackedTimeIntervalsIsPlaceholderData,
    trackedTimeIntervalsFetched
  } = usePaginatedTrackedTimeIntervals<FetchTrackedTimeIntervalQueryResponse>({
    cacheKey:
      TrackedTimeIntervalCache.userTrackedTimeIntervalsTodayCacheKey(userUuid),
    query: FETCH_TRACKED_TIME_INTERVALS_QUERY,
    initialFilters: {
      userUuid,
      createdAt: DateFilterTypeConverter.toISODateTimeRange({
        gte: new Date().toString(),
        lte: new Date().toString()
      })
    }
  });

  const { hours, minutes } = getTotalTime(trackedTimeIntervals);

  return (
    <DashboardStatisticItem
      i18nTitle={profilesKeys.totalHoursDay}
      icon={IconsEnum.CLOCK_OUTLINE}
      href={
        selfProfile
          ? MessagePath.myTotalHours()
          : MessagePath.userTotalHours(userNanoId)
      }
      loaded={
        trackedTimeIntervalsIsPlaceholderData || trackedTimeIntervalsFetched
      }
      value={
        hours || minutes ? <PeriodHelper hours={hours} minutes={minutes} /> : 0
      }
    />
  );
}

export default DashboardStatisticTotalHoursDay;
