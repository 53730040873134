import { gql } from 'graphql-request';

import {
  InvoiceAmount,
  InvoiceCreatedAt,
  InvoiceID,
  InvoiceInvoiceType,
  InvoiceNanoID,
  InvoiceStatus,
  InvoiceUUID
} from '../invoicesTypes';

export interface FetchDashboardInvoicesQueryResponse {
  id: InvoiceID;
  nanoId: InvoiceNanoID;
  uuid: InvoiceUUID;
  status: InvoiceStatus;
  amount: InvoiceAmount;
  createdAt: InvoiceCreatedAt;
  invoiceType: InvoiceInvoiceType;
}

export const FETCH_DASHBOARD_INVOICES_QUERY = gql`
  query Invoices(
    $filters: InvoicesFilters
    $sort: [InvoicesSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    invoices(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      appVersion
      nodes {
        id
        nanoId
        uuid
        status
        amount
        createdAt
        invoiceType
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
