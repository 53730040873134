import { Control } from 'react-hook-form';

import {
  CheckInTasksFormData,
  CheckInTasksFormFields
} from './CheckInTasksForm.types';
import { ErrorMessage, IsLoading } from '../../../../../types';
import { UserNanoID } from '../../../../users/usersTypes';

import { UserCompetenciesSelectField } from '../../../../userCompetencies/components/helpers/UserCompetenciesSelectField';

import { Form } from '../../../../../helpers/Form';

import { revenueShareItemsKeys, tasksKeys } from '../../../../../locales/keys';

interface CheckInTasksFormProps {
  contractorCompetencyIdValidationErrorMessage?: ErrorMessage;
  control: Control<CheckInTasksFormData>;
  form: string;
  isLoading?: IsLoading;
  userNanoId: UserNanoID;
}
function CheckInTasksForm({
  contractorCompetencyIdValidationErrorMessage,
  control,
  form,
  isLoading,
  userNanoId
}: CheckInTasksFormProps) {
  return (
    <Form id={form}>
      <div className="p-4">
        <UserCompetenciesSelectField<CheckInTasksFormData>
          control={control}
          disabled={isLoading}
          error={contractorCompetencyIdValidationErrorMessage}
          i18nLabel={revenueShareItemsKeys.selectShare}
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          name={CheckInTasksFormFields.CONTRACTOR_COMPETENCY_ID}
          noCompetenciesI18nMessage={
            tasksKeys.cannotCheckInTheSmartContractWithNoShares
          }
          userNanoId={userNanoId}
          withCompetency
        />
      </div>
    </Form>
  );
}

export default CheckInTasksForm;
