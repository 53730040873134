import React, { useMemo } from 'react';
import round from 'lodash/round';

import {
  TeamGeneralLedgerID,
  TeamNanoID,
  TeamRevenue,
  TeamRevenueReverse
} from '../../../../../../../../teams/teamsTypes';
import { IsFetched } from '../../../../../../../../../types';

import { DashboardStatisticItem } from '../DashboardStatisticItem';

import { TeamPath } from '../../../../../../../../teams/TeamPath';
import { AccountPath } from '../../../../../../../../accounts/AccountPath';

import { profilesKeys } from '../../../../../../../../../locales/keys';

interface DashboardStatisticRevenueTeam {
  nanoId: TeamNanoID;
  generalLedgerId: TeamGeneralLedgerID;
  revenue: TeamRevenue;
  revenueReverse: TeamRevenueReverse;
}

interface DashboardStatisticRevenueProps {
  team: DashboardStatisticRevenueTeam;
  teamFetched: IsFetched;
  selfProfile: boolean;
}

function DashboardStatisticRevenue({
  team,
  teamFetched,
  selfProfile
}: DashboardStatisticRevenueProps) {
  const revenueHref = useMemo<string>(() => {
    const companyRevenueHref = team?.nanoId
      ? TeamPath.companyPaymentsBalanceSheet(team?.nanoId)
      : undefined;

    return selfProfile
      ? AccountPath.paymentsBalanceSheet()
      : companyRevenueHref;
  }, [selfProfile, team?.nanoId]);

  return (
    <DashboardStatisticItem
      href={revenueHref}
      i18nTitle={profilesKeys.revenue}
      loaded={teamFetched}
      value={round(team?.revenue - team?.revenueReverse || 0)}
    />
  );
}

export default DashboardStatisticRevenue;
