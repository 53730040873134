import React, { useMemo, useState } from 'react';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import map from 'lodash/map';

import { TeamNanoID } from '../../../../../../../../teams/teamsTypes';
import {
  SmartContractItemID,
  SmartContractItemItemCategoryName,
  SmartContractItemPrice,
  SmartContractStatuses
} from '../../../../../../../../smartContracts/smartContractsTypes';

import {
  FETCH_DASHBOARD_SMART_CONTRACTS_QUERY,
  FetchDashboardSmartContractsQueryResponse
} from '../../../../../../../../smartContracts/queries/fetchDashboardSmartContracts.query';

import { useFinPaginatedSmartContracts } from '../../../../../../../../smartContracts/hooks/useFinPaginatedSmartContracts';

import { DashboardFinanceSmartContractsQuantity } from './components/DashboardFinanceSmartContractsQuantity';
import { DashboardFinanceSmartContractsAmount } from './components/DashboardFinanceSmartContractsAmount';

import { Translate } from '../../../../../../../../../helpers/Translate';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { MultiSelect } from '../../../../../../../../../helpers/MultiSelect';

import { SmartContractCache } from '../../../../../../../../smartContracts/SmartContractCache';
import { profilesKeys, words } from '../../../../../../../../../locales/keys';
import { PaymentPath } from '../../../../../../../../payments/PaymentPath';
import { TeamPath } from '../../../../../../../../teams/TeamPath';

enum TypeDataEnum {
  QUANTITY = 'quantity',
  COST = 'cost'
}

const selectTypeData = [
  {
    value: TypeDataEnum.QUANTITY,
    label: <Translate id={words.quantity} />
  },
  { value: TypeDataEnum.COST, label: <Translate id={words.cost} /> }
];

interface DashboardFinanceSmartContractsItemType {
  id: SmartContractItemID;
  price: SmartContractItemPrice;
  itemCategoryName: SmartContractItemItemCategoryName;
}

interface DashboardFinanceSmartContractsProps {
  teamNanoId: TeamNanoID;
  selfProfile: boolean;
}

function DashboardFinanceSmartContracts({
  teamNanoId,
  selfProfile
}: DashboardFinanceSmartContractsProps) {
  const { smartContracts, smartContractsFetched, smartContractsError } =
    useFinPaginatedSmartContracts<FetchDashboardSmartContractsQueryResponse>({
      cacheKey: SmartContractCache.dashboardSmartContractsCacheKey(),
      query: FETCH_DASHBOARD_SMART_CONTRACTS_QUERY,
      initialFilters: {
        companyNanoId: { eq: teamNanoId },
        status: { eq: SmartContractStatuses.OPEN }
      },
      options: {
        enabled: !!teamNanoId,
        enabledPlaceholder: !!teamNanoId,
        withoutPrefetch: true
      }
    });

  const [typeData, setTypeData] = useState<TypeDataEnum>(TypeDataEnum.QUANTITY);

  const items = useMemo<DashboardFinanceSmartContractsItemType[]>(
    () =>
      flatten(
        map(smartContracts || [], (smartContract) => smartContract.items)
      ),
    [smartContracts]
  );

  if (!teamNanoId) {
    return null;
  }

  return (
    <div className="flex flex-col rounded-lg bg-white border-gray-200 dark:bg-gray-850 p-4 dark:border-gray-800 border">
      <div className="flex justify-between items-start mb-2">
        <div className="flex items-center gap-2 text-md dark:text-gray-300">
          <Translate id={profilesKeys.openSmartContracts} />
        </div>

        <MultiSelect
          data={selectTypeData}
          multi={false}
          onChange={(v) =>
            setTypeData(isArray(v) ? null : (v.value as TypeDataEnum))
          }
          value={find(selectTypeData, ['value', typeData])}
          classNamePrefix="av_select"
          inputWrapperClassName="w-28 text-sm font-normal"
        />
      </div>

      {typeData === TypeDataEnum.QUANTITY && (
        <DashboardFinanceSmartContractsQuantity
          items={items}
          loaded={smartContractsFetched}
          href={
            selfProfile
              ? PaymentPath.smartContracts()
              : TeamPath.companyPaymentsSmartContracts(teamNanoId)
          }
        />
      )}

      {typeData === TypeDataEnum.COST && (
        <DashboardFinanceSmartContractsAmount
          items={items}
          loaded={smartContractsFetched}
          href={
            selfProfile
              ? PaymentPath.smartContracts()
              : TeamPath.companyPaymentsSmartContracts(teamNanoId)
          }
        />
      )}

      <AlertMessage message={smartContractsError} />
    </div>
  );
}

export default DashboardFinanceSmartContracts;
