import { useCallback, useState } from 'react';
import filter from 'lodash/filter';
import uniqWith from 'lodash/uniqWith';

import { CheckableUsersListItemUserType } from '../../components/InviteUsersModalBody/components/CheckableUsersListItem';

function useSelectedUsers() {
  const [selectedUsers, setSelectedUsers] = useState<
    CheckableUsersListItemUserType[]
  >([]);

  const handleDeselectAllUsers = useCallback(
    () => setSelectedUsers([]),
    [setSelectedUsers]
  );

  const handleDeselectUser = useCallback<
    (selectedUser: CheckableUsersListItemUserType) => void
  >(
    (selectedUser: CheckableUsersListItemUserType) =>
      setSelectedUsers((prevSelectedUsers) =>
        filter(prevSelectedUsers, (m) => m.id !== selectedUser.id)
      ),
    [setSelectedUsers]
  );

  const handleSelectUser = useCallback<
    (selectedUser: CheckableUsersListItemUserType) => void
  >(
    (selectedUser: CheckableUsersListItemUserType) =>
      setSelectedUsers((prevSelectedUsers) =>
        uniqWith(
          [...prevSelectedUsers, selectedUser],
          (prevSelectedUser, m) => prevSelectedUser.id === m.id
        )
      ),
    [setSelectedUsers]
  );

  return {
    selectedUsers,
    selectedUserIds: selectedUsers?.map(({ id }) => id),
    handleDeselectAllUsers,
    handleDeselectUser,
    handleSelectUser
  };
}

export default useSelectedUsers;
