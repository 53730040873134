import React from 'react';

import { DashboardActiveTaskProjectMessagesHeaderProjectPayments } from '../DashboardActiveTaskProjectMessagesHeaderProjectPayments';

import { NextPureLinkHelper } from '../../../../../../../../../../../helpers/links/NextPureLinkHelper';
import { CheckPermissions } from '../../../../../../../../../../../helpers/CheckPermissions';
import { Status } from '../../../../../../../../../../../helpers/Status';

import { ProjectPath } from '../../../../../../../../../../projects/ProjectPath';
import { ProjectsPermissions } from '../../../../../../../../../../projects/projectsConstants';
import { DashboardActiveTaskProjectMessagesHeaderProject } from './DashboardActiveTaskProjectMessagesHeader.types';

interface DashboardActiveTaskProjectMessagesHeaderProps {
  project: DashboardActiveTaskProjectMessagesHeaderProject;
}

function DashboardActiveTaskProjectMessagesHeader({
  project
}: DashboardActiveTaskProjectMessagesHeaderProps) {
  if (!project) {
    return null;
  }

  return (
    <div className="px-2 pb-2 pt-1 flex items-center border-b dark:border-gray-800">
      <div className="flex-1">
        <NextPureLinkHelper
          text={project.name}
          href={ProjectPath.show(project.nanoId)}
          className="text-sm mb-1 hover:underline"
        />

        <div className="flex gap-2">
          <Status status={project.status} />

          <CheckPermissions
            action={
              ProjectsPermissions.READ_PROJECT_ACTIVE_TASK_FIN_PAYMENTS_BLOCK
            }
          >
            <DashboardActiveTaskProjectMessagesHeaderProjectPayments
              projectNanoId={project.nanoId}
            />
          </CheckPermissions>
        </div>
      </div>
    </div>
  );
}

export default DashboardActiveTaskProjectMessagesHeader;
