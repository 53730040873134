import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import find from 'lodash/find';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

import { MultiSelectDataType } from '../../../../../helpers/MultiSelect/types';
import { UserCompetenciesSelectFieldControlProps } from './UserCompetenciesSelectField.types';
import { UserCompetencyID } from '../../../userCompetenciesTypes';

import {
  FETCH_USER_COMPETENCIES_QUERY,
  FetchUserCompetenciesQueryResponse
} from '../../../queries/fetchUserCompetencies';

import { useFinPaginatedUserCompetencies } from '../../../hooks/useFinPaginatedUserCompetencies';

import { UserCompetenciesSelectFieldAlertMessage } from './components/UserCompetenciesSelectFieldAlertMessage';

import {
  MultiSelectFieldProps,
  ProfileMultiSelectField,
  ProfileMultiSelectFieldChangeCallbackType
} from '../../../../teams/helpers/ProfileMultiSelectField';

import { UserCompetencyCache } from '../../../UserCompetencyCache';

function UserCompetenciesSelectField<T>({
  afterChange,
  control,
  disabled,
  error,
  i18nLabel,
  i18nPlaceholder,
  isClearable,
  labelClassName,
  name,
  noCompetenciesI18nMessage,
  userNanoId,
  withCompetency
}: UserCompetenciesSelectFieldControlProps<T> & MultiSelectFieldProps<T>) {
  const {
    field: { onChange }
  } = useController({
    name,
    control
  });

  const {
    userCompetencies,
    userCompetenciesError,
    filterUserCompetencies,
    userCompetenciesFetched
  } = useFinPaginatedUserCompetencies<FetchUserCompetenciesQueryResponse>({
    query: FETCH_USER_COMPETENCIES_QUERY,
    cacheKey: UserCompetencyCache.userCompetencies(userNanoId),
    initialFilters: { userNanoId: { eq: userNanoId } }
  });

  const userDefaultCompetencyId = useMemo<UserCompetencyID>(
    () => find(userCompetencies, 'defaultAt')?.id ?? head(userCompetencies)?.id,
    [userCompetencies]
  );

  useEffect(() => {
    if (userDefaultCompetencyId) {
      onChange(userDefaultCompetencyId);

      const selectedItem = find(
        userCompetencies,
        (item) => item.id === userDefaultCompetencyId
      );
      afterChange?.(selectedItem);
    }
  }, [afterChange, onChange, userCompetencies, userDefaultCompetencyId]);

  useEffect(() => {
    filterUserCompetencies({ userNanoId: { eq: userNanoId } });
  }, [filterUserCompetencies, userNanoId]);

  const userCompetenciesSelectData = useMemo<MultiSelectDataType[]>(
    () =>
      userCompetencies?.map((userCompetency) => ({
        value: userCompetency.id,
        label: `${
          withCompetency ? `${userCompetency.competency?.name} – ` : ''
        }${userCompetency.value ?? 0}%`
      })),
    [userCompetencies, withCompetency]
  );

  const handleChange = useCallback<ProfileMultiSelectFieldChangeCallbackType>(
    (value) => {
      const selectedItem = find(userCompetencies, (item) => item.id === value);

      afterChange?.(selectedItem);
    },
    [afterChange, userCompetencies]
  );

  return (
    <Fragment>
      <ProfileMultiSelectField<T>
        classNamePrefix="av"
        control={control}
        data={userCompetenciesSelectData}
        disabled={disabled}
        error={userCompetenciesError || error}
        i18nLabel={i18nLabel}
        i18nPlaceholder={i18nPlaceholder}
        inputWrapperClassName="w-full mt-1"
        isClearable={isClearable}
        isSearchable
        labelClassName={labelClassName}
        menuPosition="fixed"
        multi={false}
        name={name}
        onChange={handleChange}
      />

      {noCompetenciesI18nMessage &&
        userCompetenciesFetched &&
        isEmpty(userCompetencies) && (
          <UserCompetenciesSelectFieldAlertMessage userNanoId={userNanoId} />
        )}
    </Fragment>
  );
}

export default UserCompetenciesSelectField;
