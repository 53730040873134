import React, { ReactNode } from 'react';

import { NextLinkHelper } from '../../../../../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface DashboardStatisticItemProps {
  href?: string;
  i18nTitle: string;
  value?: string | number | ReactNode;
  icon?: IconsEnum;
  loaded?: boolean;
}

function DashboardStatisticItem({
  i18nTitle,
  icon,
  href,
  value,
  loaded = true
}: DashboardStatisticItemProps) {
  const content = (
    <div className="flex gap-4 justify-between">
      <div>
        <div className="text-md dark:text-gray-300">
          <Translate id={i18nTitle} />
        </div>
        <LoadingSkeleton
          loaded={loaded}
          count={1}
          className="text-3xl font-medium"
        >
          <div className="text-3xl font-medium">{value}</div>
        </LoadingSkeleton>
      </div>

      <div className="text-gray-600 dark:text-gray-400">
        {icon && <Icon icon={icon} className="h-8 w-8 stroke-1" />}
      </div>
    </div>
  );

  return href ? (
    <NextLinkHelper
      href={href}
      className="rounded-lg bg-white hover:bg-gray-100 border-gray-200 dark:bg-gray-850 p-4 dark:hover:bg-gray-800 dark:border-gray-800 border"
    >
      {content}
    </NextLinkHelper>
  ) : (
    <div className="rounded-lg bg-white border-gray-200 dark:bg-gray-850 p-4 dark:border-gray-800 border">
      {content}
    </div>
  );
}

export default DashboardStatisticItem;
