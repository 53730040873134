import React, { useMemo } from 'react';
import filter from 'lodash/filter';
import size from 'lodash/size';
import compact from 'lodash/compact';

import { ProjectNanoID } from '../../../../../../../../../../projects/projectsTypes';
import {
  FetchFinInvoicesFilters,
  InvoiceReturnReasons,
  InvoiceStatuses,
  InvoiceTypes
} from '../../../../../../../../../../invoices/invoicesTypes';

import {
  FETCH_PROJECT_SIDEBAR_INVOICES_QUERY,
  FetchProjectSidebarInvoicesQueryMetaResponse
} from '../../../../../../../../../../invoices/queries/fetchProjectSidebarInvoices.query';

import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';
import { useFinPaginatedInvoices } from '../../../../../../../../../../invoices/hooks/useFinPaginatedInvoices';

import { InvoicesPaymentsOverview } from '../../../../../../../../../../invoices/helpers/InvoicesPaymentsOverview';

import { LoadingSkeleton } from '../../../../../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../../../../../helpers/AlertMessage';

import { ProjectsPermissions } from '../../../../../../../../../../projects/projectsConstants';
import { InvoiceCache } from '../../../../../../../../../../invoices/InvoiceCache';

const initialLimit = 1000;

const invoiceStatusesPermissions = [
  {
    status: InvoiceStatuses.SENT,
    permission:
      ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_SEND_INVOICES
  },
  {
    status: InvoiceStatuses.PAID,
    permission:
      ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_PAID_INVOICES
  },
  {
    status: InvoiceStatuses.OPEN,
    permission:
      ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_OPEN_INVOICES
  },
  {
    status: InvoiceStatuses.CANCELED,
    permission:
      ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_CANCELED_INVOICES
  },
  {
    status: InvoiceStatuses.UNCOLLECTIBLE,
    permission:
      ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_UNCOLLECTIBLE_INVOICES
  },
  {
    status: InvoiceStatuses.VOID,
    permission:
      ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_VOID_INVOICES
  },
  {
    status: InvoiceStatuses.DRAFT,
    permission:
      ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_DRAFT_INVOICES
  }
];

interface DashboardActiveTaskProjectMessagesHeaderProjectPaymentsProps {
  projectNanoId: ProjectNanoID;
}

function DashboardActiveTaskProjectMessagesHeaderProjectPayments({
  projectNanoId
}: DashboardActiveTaskProjectMessagesHeaderProjectPaymentsProps) {
  const currentUser = useCurrentUser();

  const statusInvoices = useMemo<InvoiceStatuses[]>(
    () =>
      filter(invoiceStatusesPermissions, ({ permission }) =>
        currentUser?.hasPermissions(permission)
      ).map(({ status }) => status),
    [currentUser]
  );

  const invoiceTypes = useMemo<InvoiceTypes[]>(
    () =>
      compact([
        InvoiceTypes.REGULAR,
        currentUser?.hasPermissions(
          ProjectsPermissions.READ_PROJECT_SIDEBAR_FIN_PAYMENTS_BLOCK_WITH_REGULAR_NET_INVOICES
        )
          ? InvoiceTypes.REGULAR_NET
          : null
      ]),
    [currentUser]
  );

  const initialFilters = useMemo<FetchFinInvoicesFilters>(
    () => ({
      projectNanoIdAndTasks: { eq: projectNanoId },
      invoiceType: { in: invoiceTypes },
      status:
        size(statusInvoices) === 0
          ? { eq: InvoiceStatuses.NONE }
          : { in: statusInvoices }
    }),
    [projectNanoId, invoiceTypes, statusInvoices]
  );

  const { invoicesMeta, invoicesError, invoicesFetched } =
    useFinPaginatedInvoices<
      undefined,
      FetchProjectSidebarInvoicesQueryMetaResponse
    >({
      cacheKey:
        InvoiceCache.projectSidebarRegularInvoicesCacheKey(projectNanoId),
      initialLimit,
      initialFilters: {
        ...initialFilters,
        returnReason: {
          notIn: [InvoiceReturnReasons.REFUND, InvoiceReturnReasons.CHARGEBACK]
        }
      },
      query: FETCH_PROJECT_SIDEBAR_INVOICES_QUERY,
      additionalParams: {
        withTotalAmount: true
      },
      options: {
        withoutPrefetch: true
      }
    });

  const {
    invoicesMeta: refundInvoicesMeta,
    invoicesError: refundInvoicesError,
    invoicesFetched: refundInvoicesFetched
  } = useFinPaginatedInvoices<
    undefined,
    FetchProjectSidebarInvoicesQueryMetaResponse
  >({
    cacheKey:
      InvoiceCache.projectSidebarRefundRegularInvoicesCacheKey(projectNanoId),
    initialLimit,
    initialFilters: {
      ...initialFilters,
      returnReason: {
        eq: InvoiceReturnReasons.REFUND
      }
    },
    query: FETCH_PROJECT_SIDEBAR_INVOICES_QUERY,
    additionalParams: {
      withTotalAmount: true
    },
    options: {
      withoutPrefetch: true
    }
  });

  const {
    invoicesMeta: chargebackInvoicesMeta,
    invoicesError: chargebackInvoicesError,
    invoicesFetched: chargebackInvoicesFetched
  } = useFinPaginatedInvoices<
    undefined,
    FetchProjectSidebarInvoicesQueryMetaResponse
  >({
    cacheKey:
      InvoiceCache.projectSidebarChargebackRegularInvoicesCacheKey(
        projectNanoId
      ),
    initialLimit,
    initialFilters: {
      ...initialFilters,
      returnReason: {
        eq: InvoiceReturnReasons.CHARGEBACK
      }
    },
    query: FETCH_PROJECT_SIDEBAR_INVOICES_QUERY,
    additionalParams: {
      withTotalAmount: true
    },
    options: {
      withoutPrefetch: true
    }
  });

  return (
    <LoadingSkeleton
      loaded={
        invoicesFetched && refundInvoicesFetched && chargebackInvoicesFetched
      }
      count={1}
      className="w-32"
    >
      <InvoicesPaymentsOverview
        invoicesMeta={invoicesMeta}
        refundInvoicesTotalAmount={refundInvoicesMeta?.totalAmount || 0}
        chargebackInvoicesTotalAmount={chargebackInvoicesMeta?.totalAmount || 0}
        className="w-32 space-y-0.5 text-gray-900 dark:text-gray-200 font-normal text-sm leading-tight"
        popupWithDetails
      />

      <AlertMessage
        message={
          invoicesError || refundInvoicesError || chargebackInvoicesError
        }
      />
    </LoadingSkeleton>
  );
}

export default DashboardActiveTaskProjectMessagesHeaderProjectPayments;
