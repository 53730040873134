import { useQueryClient } from 'react-query';

import toNumber from 'lodash/toNumber';
import map from 'lodash/map';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';

import { useFieldArray } from 'react-hook-form';

import {
  TaskID,
  TaskNanoID,
  TaskTrackTimeFields
} from '../../../../../tasksTypes';
import { MultiTrackTaskTimeFormData } from '../../MultiTrackTaskTimeForm.types';

import { TRACK_TASKS_TIME_QUERY } from '../../../../../queries/trackTasksTime.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useTrackTasksTime } from '../../../../../hooks/useTrackTasksTime';

import { TaskCache } from '../../../../../TaskCache';
import { TrackedTimeIntervalCache } from '../../../../../../trackedTimeIntervals/TrackedTimeIntervalCache';

interface TrackTasksTimeFormOptions {
  initialTasks: {
    id: TaskID;
    nanoId: TaskNanoID;
  }[];
}

function invalidateCacheKeys(taskNanoId: TaskNanoID) {
  return [
    TaskCache.messagesCacheKey(taskNanoId),
    TaskCache.taskMembersCacheKey(taskNanoId)
  ];
}

function useMultiTrackTaskTimeForm({
  initialTasks
}: TrackTasksTimeFormOptions) {
  const queryClient = useQueryClient();

  const defaultTasksValues = initialTasks.map((task) => ({
    [TaskTrackTimeFields.MINUTES]: '',
    [TaskTrackTimeFields.HOURS]: '',
    [TaskTrackTimeFields.BODY]: '',
    taskId: task.id,
    taskNanoId: task.nanoId
  }));

  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch
  } = useReactHookForm<MultiTrackTaskTimeFormData>({
    defaultValues: { tasks: defaultTasksValues },
    isModalForm: true
  });

  const { fields } = useFieldArray<MultiTrackTaskTimeFormData, 'tasks'>({
    name: 'tasks',
    control
  });

  const {
    trackTasksTimeError,
    trackTasksTimeLoading,
    trackTasksTimeErrorMessage,
    trackTasksTime,
    trackTasksTimeReset
  } = useTrackTasksTime({
    query: TRACK_TASKS_TIME_QUERY,
    cacheKeys: [
      TaskCache.indexCacheKey(),
      TrackedTimeIntervalCache.dashboardTrackedTimeIntervalsCacheKey()
    ]
  });

  return {
    formErrors: errors,
    control,
    register,
    fields,
    resetTrackTasksTimeForm: resetForm,
    trackTasksTimeError,
    trackTasksTimeLoading,
    trackTasksTimeErrorMessage,
    trackTasksTime,
    trackTasksTimeReset,
    handleTrackTasksTime: handleSubmitReactHookForm({
      onSubmit: async (data) => {
        const filteredTasks = filter(
          data.tasks,
          (task) => toNumber(task.hours) > 0 || toNumber(task.minutes) > 0
        );

        await trackTasksTime({
          trackedTime: map(filteredTasks, (task) => ({
            hours: toNumber(task.hours) > 0 ? toNumber(task.hours) : undefined,
            minutes:
              toNumber(task.minutes) > 0 ? toNumber(task.minutes) : undefined,
            taskId: task.taskId
          }))
        });

        filteredTasks.map((task) => {
          invalidateCacheKeys(task.taskNanoId as TaskNanoID).map((key) =>
            queryClient.invalidateQueries(key)
          );
        });
      }
    }),
    watchTotalTrackHours: sumBy(watch('tasks'), (field) =>
      toNumber(field[TaskTrackTimeFields.HOURS])
    ),
    watchTotalTrackMinutes: sumBy(watch('tasks'), (field) =>
      toNumber(field[TaskTrackTimeFields.MINUTES])
    )
  };
}

export default useMultiTrackTaskTimeForm;
