import React from 'react';

import {
  ErrorMessage,
  I18nText,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';

interface UpdateMessageVersionFormProps {
  form: string;
  i18nPlaceholder?: I18nText;
  isLoading?: IsLoading;
  registerVersionName: RegisterFormFieldType<HTMLInputElement>;
  versionNameValidationError: ErrorMessage;
}

function UpdateMessageVersionForm({
  form,
  i18nPlaceholder,
  isLoading,
  registerVersionName,
  versionNameValidationError
}: UpdateMessageVersionFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div>
          <div>
            <InputField
              className="relative"
              disabled={isLoading}
              i18nPlaceholder={i18nPlaceholder}
              labelClassName="font-light uppercase"
              name={registerVersionName.name}
              onChange={registerVersionName.onChange}
              ref={registerVersionName.ref}
              error={versionNameValidationError}
              showErrorIcon={false}
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default UpdateMessageVersionForm;
