import cl from 'classnames';
import camelCase from 'lodash/camelCase';

import { ItemStatus } from '../../types';

import { Translate } from '../../helpers/Translate';
import { statusColor } from '../../utils/statusColor';

interface StatusProps {
  status: ItemStatus | null;
}

function Status({ status }: StatusProps) {
  if (!status) {
    return null;
  }

  return (
    <span
      className={cl(
        'inline-flex leading-6 px-2.5 rounded-full text-xs whitespace-nowrap font-medium',
        status
          ? statusColor(status)
          : 'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700 dark:bg-opacity-50'
      )}
    >
      <Translate id={`models.statuses.types.${camelCase(status)}`} />
    </span>
  );
}

export default Status;
