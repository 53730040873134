import { useCallback } from 'react';

import { ID } from '../../../../types';

import {
  TaskMemberInviteGqlError,
  UpdateTaskMemberInviteCacheKeys,
  UpdateTaskMemberInviteGqlQuery,
  UpdateTaskMemberInviteGqlStatus
} from '../../taskMemberInvitesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { generateNanoId } from '../../../../utils/generateNanoId';

interface DeclineAllTaskMemberInvitesOptions {
  query: UpdateTaskMemberInviteGqlQuery;
  cacheKeys: UpdateTaskMemberInviteCacheKeys;
}

interface DeclineTaskMemberInvitesInput {
  clientMutationId: ID;
}

interface DeclineAllTaskMemberInvitesError {
  fullMessages: TaskMemberInviteGqlError;
}

interface DeclineAllTaskMemberInvitesResponse {
  declineAllTaskMemberInvites: {
    status: UpdateTaskMemberInviteGqlStatus;
    errors: DeclineAllTaskMemberInvitesError;
  };
}

const action = 'declineAllTaskMemberInvites';

function useDeclineAllTaskMemberInvites({
  query,
  cacheKeys
}: DeclineAllTaskMemberInvitesOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DeclineTaskMemberInvitesInput,
    DeclineAllTaskMemberInvitesResponse,
    DeclineAllTaskMemberInvitesError,
    undefined
  >({ action, query, cacheKeys });

  return {
    declineAllTaskMemberInvitesData: updateQueryData,
    declineAllTaskMemberInvitesError: updateQueryError,
    declineAllTaskMemberInvitesLoading: updateQueryLoading,
    declineAllTaskMemberInvitesErrorMessage: updateQueryErrorMessage,
    declineAllTaskMemberInvites: useCallback<
      () => Promise<DeclineAllTaskMemberInvitesResponse>
    >(() => updateQuery({ clientMutationId: generateNanoId() }), [updateQuery]),
    declineAllTaskMemberInvitesReset: updateQueryReset
  };
}

export default useDeclineAllTaskMemberInvites;
