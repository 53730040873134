import get from 'lodash/get';

function graphqlErrorsMessages<QueryError>(error: QueryError): string | null {
  return error
    ? get(error, 'response.errors', [])
        ?.map((e) => get(e, 'message'))
        ?.join(', ') || get(error, 'fullMessages')?.join(', ')
    : null;
}

export default graphqlErrorsMessages;
