import { gql } from 'graphql-request';

export const DECLINE_ALL_TASK_MEMBER_INVITES_QUERY = gql`
  mutation DeclineAllTaskMemberInvites($clientMutationId: String) {
    declineAllTaskMemberInvites(
      input: { clientMutationId: $clientMutationId }
    ) {
      status
    }
  }
`;
