import React, { useCallback, useMemo, useState } from 'react';

import floor from 'lodash/floor';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';

import isToday from 'date-fns/isToday';

import { DateFilterType } from '../../../../../../../types';
import { UserID, UserNanoID } from '../../../../../usersTypes';
import { TeamNanoID } from '../../../../../../teams/teamsTypes';
import {
  FetchTasksSortTypes,
  TaskStatuses
} from '../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { DashboardTrackedTimeFilterType } from './DashboardTrackedTime.types';

import {
  FETCH_TRACKED_TIME_INTERVALS_QUERY,
  FetchTrackedTimeIntervalQueryResponse
} from '../../../../../../trackedTimeIntervals/queries/fetchTrackedTimeIntervals.query';
import {
  FETCH_TASKS_DASHBOARD_QUERY,
  FetchTasksDashboardQueryResponse
} from '../../../../../../tasks/queries/fetchTasksDashboard.query';

import { usePaginatedTrackedTimeIntervals } from '../../../../../../trackedTimeIntervals/hooks/usePaginatedTrackedTimeIntervals';
import { useTasks } from '../../../../../../tasks/hooks/useTasks';

import { DashboardTrackedTimeSelectType } from './components/DashboardTrackedTimeSelectType';
import { DashboardTrackedTimePeriod } from './components/DashboardTrackedTimePeriod';
import { MultiTrackTaskTimeModalButton } from '../../../../../../tasks/components/modalButtons/MultiTrackTaskTimeModalButton';

import { Translate } from '../../../../../../../helpers/Translate';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { PeriodHelper } from '../../../../../../../helpers/PeriodHelper';
import { DateHelper } from '../../../../../../../helpers/DateHelper';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  TrackedTimeCountByPeriodsDateType,
  getTrackedTimeByPeriodsDate,
  getTrackedTimeStartDate
} from './utils/getTrackedTimeByPeriodsDate';
import { DateFilterTypeConverter } from '../../../../../../../utils/DateFilterTypeConverter';

import { TaskCache } from '../../../../../../tasks/TaskCache';
import { TrackedTimeIntervalCache } from '../../../../../../trackedTimeIntervals/TrackedTimeIntervalCache';
import { TeamPath } from '../../../../../../teams/TeamPath';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';

import { tasksKeys, words } from '../../../../../../../locales/keys';

interface DashboardTrackedTimeUser {
  id: UserID;
  nanoId: UserNanoID;
}

interface DashboardTrackedTimeProps {
  user: DashboardTrackedTimeUser;
  teamNanoId?: TeamNanoID;
  selfProfile: boolean;
}

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const initialLimit = 1000;

const activeTasksStatuses = [
  TaskStatuses.IN_PROGRESS,
  TaskStatuses.QUALITY_CONTROL,
  TaskStatuses.READY_FOR_ACCEPTANCE,
  TaskStatuses.PENDING_MORE_INFO,
  TaskStatuses.PAUSED
];

function DashboardTrackedTime({
  user,
  selfProfile,
  teamNanoId
}: DashboardTrackedTimeProps) {
  const [type, setType] = useState<DashboardTrackedTimeFilterType>(
    DashboardTrackedTimeFilterType.DAY
  );

  const { tasks, tasksError, tasksFetched } =
    useTasks<FetchTasksDashboardQueryResponse>({
      cacheKey: TaskCache.userActiveTasksCacheKey(),
      query: FETCH_TASKS_DASHBOARD_QUERY,
      initialLimit,
      initialSort: [FetchTasksSortTypes.LAST_ACTIVITY_DESC],
      initialFilters: {
        performerIds: [user.id],
        status: { in: activeTasksStatuses }
      }
    });

  const createdAt = useMemo<DateFilterType>(
    () =>
      DateFilterTypeConverter.toISODateTimeRange({
        gte: getTrackedTimeStartDate(
          DashboardTrackedTimeFilterType.DAY
        ).toISOString(),
        lte: new Date().toISOString()
      }),
    []
  );

  const {
    trackedTimeIntervals,
    trackedTimeIntervalsError,
    trackedTimeIntervalsFetched,
    changeTrackedTimeIntervalsFilters
  } = usePaginatedTrackedTimeIntervals<FetchTrackedTimeIntervalQueryResponse>({
    cacheKey: TrackedTimeIntervalCache.dashboardTrackedTimeIntervalsCacheKey(),
    query: FETCH_TRACKED_TIME_INTERVALS_QUERY,
    initialFilters: {
      userNanoId: user.nanoId,
      createdAt
    },
    initialLimit
  });

  const handleChangeType = useCallback<
    (type: DashboardTrackedTimeFilterType) => void
  >(
    (type) => {
      setType(type);
      changeTrackedTimeIntervalsFilters({
        createdAt: DateFilterTypeConverter.toISODateTimeRange({
          gte: getTrackedTimeStartDate(type).toISOString(),
          lte: new Date().toISOString()
        })
      });
    },
    [changeTrackedTimeIntervalsFilters]
  );

  const trackedTimeByPeriodsDate = useMemo<TrackedTimeCountByPeriodsDateType[]>(
    () => getTrackedTimeByPeriodsDate(trackedTimeIntervals, type),
    [trackedTimeIntervals, type]
  );

  const todayTrackedTime = useMemo<number>(
    () =>
      sumBy(trackedTimeIntervals, (trackedTimeInterval) =>
        isToday(new Date(trackedTimeInterval.createdAt))
          ? trackedTimeInterval.fullTime
          : 0
      ),
    [trackedTimeIntervals]
  );

  return (
    <div className="rounded-lg bg-white border-gray-200 dark:bg-gray-850 p-4 dark:border-gray-800 border">
      <LoadingSkeleton
        loaded={tasksFetched && trackedTimeIntervalsFetched}
        count={3}
      >
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center gap-2 text-md dark:text-gray-300">
            <Translate id={words.trackedTime} />
          </div>

          <DashboardTrackedTimeSelectType
            defaultValue={type}
            onChange={handleChangeType}
          />
        </div>

        <div className="flex flex-col 2xl:flex-row justify-between gap-4 2xl:gap-6">
          <div className="flex 2xl:flex-col justify-between gap-4">
            <div className="flex flex-col">
              <div className="text-3xl font-medium">
                <PeriodHelper
                  hours={floor(todayTrackedTime / SECONDS_IN_HOUR)}
                  minutes={floor(
                    (todayTrackedTime % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE
                  )}
                  nullable
                />
              </div>

              <div className="text-xs text-gray-500">
                <Translate id={words.today} />
                , <DateHelper date={new Date()} customFormat="dd MMM" />
              </div>
            </div>

            <div className="self-start">
              <CheckPermissions
                action={TasksPermissions.READ_TASKS_BATCH_TRACK_TIME_BUTTON}
              >
                <MultiTrackTaskTimeModalButton
                  className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                  tasks={tasks}
                  todayTrackedTime={todayTrackedTime}
                  disabled={!tasksFetched || isEmpty(tasks)}
                  i18nText={tasksKeys.trackTime}
                  icon={IconsEnum.CLOCK}
                  iconClassName="h-6 w-6 p-0.5"
                />
              </CheckPermissions>
            </div>
          </div>

          <div className="flex-1 max-w-96">
            <div className="flex flex-col gap-0.5 items-start">
              {trackedTimeByPeriodsDate.map(
                (trackedTimeByPeriodDate, index) => (
                  <DashboardTrackedTimePeriod
                    key={index}
                    trackedTimeByPeriod={trackedTimeByPeriodDate}
                    type={type}
                  />
                )
              )}
              <div className="text-sm">
                <NextPureLinkHelper
                  href={
                    selfProfile
                      ? TeamPath.currentCompanyReports()
                      : TeamPath.companyReports(teamNanoId)
                  }
                  className="p-0 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline underline"
                  i18nText={words.viewAll}
                />
              </div>
            </div>
          </div>
        </div>

        <AlertMessage message={trackedTimeIntervalsError || tasksError} />
      </LoadingSkeleton>
    </div>
  );
}

export default DashboardTrackedTime;
