import React from 'react';

import { UserID, UserNanoID } from '../../../../../../../usersTypes';

import { FETCH_TASKS_TOTAL_COUNT_QUERY } from '../../../../../../../../tasks/queries/fetchTasksTotalCount.query';

import { useTotalsQuery } from '../../../../../../../../common/hooks/base/reactQuery/useTotalsQuery';

import { DashboardStatisticItem } from '../DashboardStatisticItem';

import { TaskPath } from '../../../../../../../../tasks/TaskPath';
import { TaskCache } from '../../../../../../../../tasks/TaskCache';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { profilesKeys } from '../../../../../../../../../locales/keys';

type MyTasksTotalCountQueryKey = 'tasks';

interface DashboardStatisticMyTasksProps {
  userId: UserID;
  userNanoId: UserNanoID;
  selfProfile: boolean;
}

function DashboardStatisticMyTasks({
  userId,
  selfProfile,
  userNanoId
}: DashboardStatisticMyTasksProps) {
  const { data: myTasksData, isFetched } =
    useTotalsQuery<MyTasksTotalCountQueryKey>({
      query: FETCH_TASKS_TOTAL_COUNT_QUERY,
      filters: {
        tasksFilters: {
          performerIds: [userId]
        }
      },
      cacheKey: TaskCache.userTasksCountCacheKey(userId)
    });

  return (
    <DashboardStatisticItem
      i18nTitle={profilesKeys.myTasks}
      icon={IconsEnum.INBOX_OUTLINE}
      href={selfProfile ? TaskPath.myTasks() : TaskPath.userTasks(userNanoId)}
      loaded={isFetched}
      value={myTasksData?.tasks?.paginationInfo?.totalCount || 0}
    />
  );
}

export default DashboardStatisticMyTasks;
