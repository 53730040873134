import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  FetchTasksPriceCacheKey,
  FetchTasksPriceFilters,
  FetchTasksPriceGqlQuery,
  FetchTasksPriceLimit,
  FetchTasksPricePage,
  FetchTasksPriceSort
} from '../../tasksPriceTypes';

import {
  INITIAL_TASKS_PRICE_FILTERS,
  INITIAL_TASKS_PRICE_LIMIT,
  INITIAL_TASKS_PRICE_PAGE,
  INITIAL_TASKS_PRICE_SORT
} from '../../tasksPriceConstants';

interface PaginatedTasksPriceOptions {
  cacheKey: FetchTasksPriceCacheKey;
  initialSort?: FetchTasksPriceSort;
  initialPage?: FetchTasksPricePage;
  initialLimit?: FetchTasksPriceLimit;
  initialFilters?: FetchTasksPriceFilters;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  query: FetchTasksPriceGqlQuery;
}

const scope = 'tasksPrice';

function useFinPaginatedTasksPrice<ItemType, MetaType = undefined>({
  cacheKey,
  initialFilters = INITIAL_TASKS_PRICE_FILTERS,
  initialSort = INITIAL_TASKS_PRICE_SORT,
  initialPage = INITIAL_TASKS_PRICE_PAGE,
  initialLimit = INITIAL_TASKS_PRICE_LIMIT,
  options,
  query
}: PaginatedTasksPriceOptions) {
  const {
    changeItemsFilters,
    clearItemsFilters,
    currentFilters,
    currentLimit,
    currentPage,
    currentSort,
    data,
    filterItems,
    isFetched,
    isLoading,
    isPlaceholderData,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    limitItems,
    meta,
    paginateItems,
    prefetchItems,
    sortItems,
    updateItemCache
  } = useFinIndexQuery<ItemType, MetaType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    scope,
    query,
    options
  });

  return {
    changeTasksPriceFilters: changeItemsFilters,
    clearTasksPriceFilters: clearItemsFilters,
    filterTasksPrice: filterItems,
    tasksPrice: items,
    tasksPriceData: data,
    tasksPriceError: itemsError,
    tasksPriceErrorMessage: itemsErrorMessage,
    tasksPriceFetched: isFetched,
    tasksPriceFilters: currentFilters,
    tasksPriceIsPlaceholderData: isPlaceholderData,
    tasksPriceLimit: currentLimit,
    tasksPriceLoading: isLoading,
    tasksPriceMeta: meta,
    tasksPricePage: currentPage,
    tasksPriceSort: currentSort,
    tasksPriceTotalCount: itemsTotalCount,
    limitTasksPrice: limitItems,
    paginateTasksPrice: paginateItems,
    prefetchTasksPrice: prefetchItems,
    sortTasksPrice: sortItems,
    updateInvoiceCache: updateItemCache
  };
}

export default useFinPaginatedTasksPrice;
