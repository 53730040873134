import React, { Fragment } from 'react';

import { DashboardFinanceDebtCategoryName } from '../../DashboardFinanceDept.types';

import { PercentHelper } from '../../../../../../../../../../../helpers/PercentHelper';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';
import { Translate } from '../../../../../../../../../../../helpers/Translate';

import {
  invoicesKeys,
  words
} from '../../../../../../../../../../../locales/keys';

const i18nTitleCategoryNames = {
  [DashboardFinanceDebtCategoryName.LESS_WEEK]: words.lessWeek,
  [DashboardFinanceDebtCategoryName.LESS_TWO_WEEK]: words.lessTwoWeek,
  [DashboardFinanceDebtCategoryName.LESS_MONTH]: words.lessMonth,
  [DashboardFinanceDebtCategoryName.LESS_TWO_MONTH]: words.lessTwoMonth,
  [DashboardFinanceDebtCategoryName.MORE_TWO_MONTH]: words.moreTwoMonth,
  [DashboardFinanceDebtCategoryName.UNCOLLECTIBLE]:
    invoicesKeys.statuses.uncollectible
};

interface DashboardFinanceDebtItemDefaultProps {
  categoryName: DashboardFinanceDebtCategoryName;
  percent: number;
  width: number;
}

type DashboardFinanceDebtItemQuantityProps = {
  quantity: number;
  amount?: never;
};

type DashboardFinanceDebtItemAmountProps = {
  quantity?: never;
  amount: number;
};

type DashboardFinanceDebtItemProps = DashboardFinanceDebtItemDefaultProps &
  (DashboardFinanceDebtItemQuantityProps | DashboardFinanceDebtItemAmountProps);

function DashboardFinanceDebtItem({
  categoryName,
  quantity,
  amount,
  percent,
  width
}: DashboardFinanceDebtItemProps) {
  return (
    <Fragment>
      <div className="dark:text-gray-400 whitespace-nowrap w-24">
        <Translate id={i18nTitleCategoryNames[categoryName]} />
      </div>

      <div className="dark:text-gray-400 min-w-12 text-right">
        {amount ? <MoneyHelper value={amount} /> : quantity}
      </div>

      <div className="flex gap-2">
        <div
          className={
            categoryName === DashboardFinanceDebtCategoryName.UNCOLLECTIBLE
              ? 'rounded max-w-full h-4 bg-red-500 dark:opacity-25'
              : 'rounded max-w-full h-4 dark:bg-gray-700 bg-gray-400'
          }
          style={{ width: `${width}%`, minWidth: '8px' }}
        />
        <div className="dark:text-gray-400 w-12 self-end">
          <PercentHelper percent={percent} />
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardFinanceDebtItem;
