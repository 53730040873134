import { gql } from 'graphql-request';

export const DECLINE_TASK_MEMBER_INVITE_QUERY = gql`
  mutation DeclineTaskMemberInvite($uuid: ID!) {
    declineTaskMemberInvite(input: { uuid: $uuid }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
