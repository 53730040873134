import { dateFnsConvert } from '../dateFnsConvert';

function getDueDate(date: Date | string): string {
  const currentDate = new Date(date);
  currentDate.setHours(23);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);

  return dateFnsConvert.toDateTimeLocal(currentDate.toISOString());
}

export default getDueDate;
