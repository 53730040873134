import React, { Fragment } from 'react';
import compact from 'lodash/compact';

import { useTranslate } from '../../common/hooks/useTranslate';

import { words } from '../../locales/keys';

interface PeriodHelperProps {
  hours: number | null;
  minutes: number | null;
  nullable?: boolean;
}

function PeriodHelper({ hours, minutes, nullable }: PeriodHelperProps) {
  const { t } = useTranslate();

  return (
    <Fragment>
      {nullable
        ? `${hours}${t(words.letters.h)} ${minutes}${t(words.letters.m)}`
        : compact([
            hours ? `${hours}${t(words.letters.h)}` : null,
            minutes ? `${minutes}${t(words.letters.m)}` : null
          ]).join(' ')}
    </Fragment>
  );
}

export default PeriodHelper;
