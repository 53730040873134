import { ClassName } from '../../../../types';
import {
  ProjectTaskLinkProject,
  ProjectTaskLinkTask
} from './ProjectTaskLink.types';

import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';

import { ProjectPath } from '../../../projects/ProjectPath';
import { TaskPath } from '../../../tasks/TaskPath';
import { ProjectNanoID } from '../../../projects/projectsTypes';
import { TaskNanoID } from '../../../tasks/tasksTypes';

interface ProjectTaskLinkProps {
  project?: ProjectTaskLinkProject;
  task?: ProjectTaskLinkTask;
  projectClassName?: ClassName;
  className?: ClassName;
  separatorClassName?: ClassName;
  getProjectPath?: (projectNanoId: ProjectNanoID) => string;
  getTaskPath?: (taskNanoId: TaskNanoID) => string;
}

function ProjectTaskLink({
  project,
  task,
  projectClassName,
  className = 'hover:underline font-medium',
  separatorClassName,
  getProjectPath = ProjectPath.show,
  getTaskPath = TaskPath.show
}: ProjectTaskLinkProps) {
  const projectLinkData = project || task?.project;

  return (
    <>
      {projectLinkData && (
        <NextPureLinkHelper
          text={projectLinkData?.name}
          href={getProjectPath(projectLinkData?.nanoId)}
          className={projectClassName || className}
        />
      )}
      {projectLinkData && task && <span className={separatorClassName}>/</span>}
      {task && (
        <NextPureLinkHelper
          text={task.name}
          href={getTaskPath(task.nanoId)}
          className={className}
        />
      )}
    </>
  );
}

export default ProjectTaskLink;
