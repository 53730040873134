import React, { useCallback } from 'react';

import { ClassName, I18nText, UpdateItemCacheKeys } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { TaskNanoID } from '../../../tasksTypes';

import {
  ACCEPT_INVITE_TO_TASK_QUERY,
  AcceptInviteToTaskQueryResponse
} from '../../../queries/acceptInviteToTask.query';

import { useAcceptInviteToTask } from '../../../hooks/useAcceptInviteToTask';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

interface AcceptInviteToTaskButtonProps {
  taskNanoId: TaskNanoID;
  className?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  cacheKeys?: UpdateItemCacheKeys;
}

function AcceptInviteToTaskButton({
  taskNanoId,
  className,
  i18nText,
  tooltipI18nText,
  icon,
  iconClassName,
  cacheKeys
}: AcceptInviteToTaskButtonProps) {
  const {
    acceptInviteToTask,
    acceptInviteToTaskLoading,
    acceptInviteToTaskErrorMessage
  } = useAcceptInviteToTask<AcceptInviteToTaskQueryResponse>({
    query: ACCEPT_INVITE_TO_TASK_QUERY,
    cacheKeys
  });

  useShowToastOnErrorChange({
    error: acceptInviteToTaskErrorMessage
  });

  const handleAcceptInviteToTaskTaskSubmit = useCallback(
    () => acceptInviteToTask({ uuid: taskNanoId }),
    [acceptInviteToTask, taskNanoId]
  );

  return (
    <PureTooltipIconButtonHelper
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tooltipI18nText}
      i18nText={i18nText}
      disabled={acceptInviteToTaskLoading}
      onClick={handleAcceptInviteToTaskTaskSubmit}
    />
  );
}

export default AcceptInviteToTaskButton;
