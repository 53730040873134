import size from 'lodash/size';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';

import { FetchTaskReportsQueryResponse } from '../../../../../../../../../../taskReports/queries/taskReports.query';
import { DashboardTasksItemType } from '../../DashboardTasksItem.types';

const periods = [1, 2, 3, 4, 5];

export function getTaskReportsEndDate(type: DashboardTasksItemType): Date {
  if (type === DashboardTasksItemType.DAY) {
    return new Date();
  }

  if (type === DashboardTasksItemType.WEEK) {
    return startOfWeek(new Date());
  }

  if (type === DashboardTasksItemType.MONTH) {
    return startOfMonth(new Date());
  }
}

export function getTaskReportsStartDate(type: DashboardTasksItemType): Date {
  if (type === DashboardTasksItemType.DAY) {
    return addDays(getTaskReportsEndDate(type), -size(periods));
  }

  if (type === DashboardTasksItemType.WEEK) {
    return addWeeks(getTaskReportsEndDate(type), -size(periods));
  }

  if (type === DashboardTasksItemType.MONTH) {
    return addMonths(getTaskReportsEndDate(type), -size(periods));
  }
}

export function getPeriodDate(type: DashboardTasksItemType): Date[] {
  if (type === DashboardTasksItemType.DAY) {
    return periods.map((period) =>
      startOfDay(addDays(getTaskReportsEndDate(type), -period))
    );
  }

  if (type === DashboardTasksItemType.WEEK) {
    return periods.map((period) =>
      startOfDay(addWeeks(getTaskReportsEndDate(type), -period))
    );
  }

  if (type === DashboardTasksItemType.MONTH) {
    return periods.map((period) =>
      startOfDay(addMonths(getTaskReportsEndDate(type), -period))
    );
  }
}

function getPeriodDateInterval(
  type: DashboardTasksItemType,
  periodDate: Date,
  prevPeriodDate: Date
): {
  start: Date;
  end: Date;
} {
  if (type === DashboardTasksItemType.DAY) {
    return { start: periodDate, end: endOfDay(periodDate) };
  }

  if (type === DashboardTasksItemType.WEEK) {
    return { start: periodDate, end: prevPeriodDate };
  }

  if (type === DashboardTasksItemType.MONTH) {
    return { start: periodDate, end: endOfMonth(periodDate) };
  }
}

function getTaskReportsCountByPeriodsDate(
  periodsDate: Date[],
  taskReports: FetchTaskReportsQueryResponse[] = [],
  type: DashboardTasksItemType
): number[] {
  return periodsDate.map((periodDate, index) =>
    size(
      taskReports.filter((taskReport) =>
        isWithinInterval(
          new Date(taskReport.createdAt),
          getPeriodDateInterval(
            type,
            periodDate,
            index === 0 ? getTaskReportsEndDate(type) : periodsDate[index - 1]
          )
        )
      )
    )
  );
}

export default getTaskReportsCountByPeriodsDate;
