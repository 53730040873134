import React, { Fragment, useCallback, useMemo, useState } from 'react';
import some from 'lodash/some';
import map from 'lodash/map';

import { TaskStatuses } from '../../../../../../../../../../tasks/tasksTypes';
import { CreateMockMessageWithFormDataTask } from '../../../../../../../../../../messages/components/list/ItemMessagesList/hooks/useCreateMockMessageWithFormData';
import { DashboardActiveTaskMessageHeaderTask } from '../DashboardActiveTaskMessageHeader/DashboardActiveTaskMessageHeader.types';
import { ItemMessagesListMessages } from '../../../../../../../../../../messages/components/list/ItemMessagesList';

import { FetchTaskMessagesQueryResponse } from '../../../../../../../../../../messages/queries/fetchTaskMessages.query';

import { useMessagesSettings } from '../../../../../../../../../../messages/hooks/useMessagesSettings';
import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';

import { useTaskMessagesContentCreateMessage } from '../../../../../../../../../../tasks/components/content/TaskMessagesContent/hooks/useTaskMessagesContentCreateMessage';
import { useTaskMessages } from '../../../../../../../../../../taskMessages/hooks/useTaskMessages';

import { DashboardActiveTaskMessageHeader } from '../DashboardActiveTaskMessageHeader';
import { TaskMessagesContentMessagesList } from '../../../../../../../../../../tasks/components/content/TaskMessagesContent/components/TaskMessagesContentMessagesList';
import {
  ItemCreateMessageForm,
  ItemCreateMessageFormStateScope,
  useItemCreateMessageFormState
} from '../../../../../../../../../../messages/components/forms/ItemCreateMessageForm';

import { MessagesPermissions } from '../../../../../../../../../../messages/messagesConstants';
import { TasksPermissions } from '../../../../../../../../../../tasks/tasksConstants';

type DashboardActiveTaskMessageContentTasks =
  CreateMockMessageWithFormDataTask & DashboardActiveTaskMessageHeaderTask;

interface DashboardActiveTaskMessageContentProps {
  task: DashboardActiveTaskMessageContentTasks;
  onlyInvitations?: boolean;
}

function DashboardActiveTaskMessageContent({
  task,
  onlyInvitations
}: DashboardActiveTaskMessageContentProps) {
  const { messagesSettings, fetchedMessageSettings } = useMessagesSettings();

  const [pinnedMessagesView, setPinnedMessagesView] = useState<boolean>(false);

  const togglePinnedMessagesView = useCallback<() => void>(
    () => setPinnedMessagesView((prevState) => !prevState),
    []
  );

  const currentUser = useCurrentUser();

  const isTaskClosed =
    task.status === TaskStatuses.DONE || task.status === TaskStatuses.CANCELED;

  const whiteboardKeyId = messagesSettings.withWhiteboardMessages
    ? undefined
    : { isNull: true };

  const {
    messages,
    messagesError,
    messagesFetched,
    messagesFetchingNextPage,
    messagesFetchingPreviousPage,
    messagesIsPlaceholderData,
    hasNextMessagesPage,
    hasPreviousMessagesPage,
    loadMoreMessages,
    loadMorePreviousMessages,
    changeMessagesFilters,
    messagesFilters,
    filterMessages,
    addMessageCache
  } = useTaskMessages({
    taskNanoId: task.nanoId,
    whiteboardKeyId
  });

  const messagesWithTask = useMemo<ItemMessagesListMessages>(
    () =>
      map(messages, (message) => ({
        ...message,
        task
      })),
    [messages, task]
  );

  const {
    createMessageStateKey,
    messageInitialValues,
    handleAfterCreateMessage,
    handleCreateMessageFormStateChange,
    handleSetRepliedMessageId
  } = useItemCreateMessageFormState({
    scope: ItemCreateMessageFormStateScope.TASK_OR_PROJECT
  });

  const onAfterCreateMessage = useCallback(
    (message: FetchTaskMessagesQueryResponse) => {
      handleAfterCreateMessage();
      addMessageCache(message);
    },
    [handleAfterCreateMessage, addMessageCache]
  );

  const {
    createMessageInTaskLoading,
    showClosedTaskMessageForm,
    sendingMessages,
    handleCreateMessageInTask,
    handleRemoveSendingMessage
  } = useTaskMessagesContentCreateMessage({
    task,
    onAfterCreateMessage
  });

  const isTaskMember = some(task?.members, { uuid: currentUser.uuid });

  return (
    <Fragment>
      {(!pinnedMessagesView && task.project && !isTaskClosed) ||
      (!pinnedMessagesView &&
        task.project &&
        isTaskClosed &&
        showClosedTaskMessageForm) ? (
        <ItemCreateMessageForm
          key={createMessageStateKey}
          isLoading={createMessageInTaskLoading}
          projectNanoId={task.project.nanoId}
          messageInitialValues={messageInitialValues}
          messages={messages}
          withFullscreenDropzone={currentUser.hasPermissions(
            TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE
          )}
          withClientLocalTime={currentUser.hasPermissions(
            TasksPermissions.READ_TASK_CLIENT_LOCAL_TIME
          )}
          withArchivizerLocalTime={currentUser.hasPermissions(
            TasksPermissions.READ_TASK_ARCHIVIZER_TEAM_LOCAL_TIME
          )}
          withPasteFiles={currentUser.hasPermissions(
            TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_PASTE_FILES
          )}
          withVisibleForClientsButton={currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON
          )}
          withAttachProductsButton
          withAttachLifestylesButton
          withAttachMaterialsButton={currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_ATTACH_MATERIALS_BUTTON
          )}
          onChange={handleCreateMessageFormStateChange}
          onSubmit={handleCreateMessageInTask}
          teamNanoId={task.project.team?.nanoId}
          hiddenTask={
            currentUser.hasPermissions(
              TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_HIDDEN_TASK_ALERT
            ) && !task.visibleForClient
          }
          sendToTaskId={task.id}
          withLexical
          withMarkdownHelp
        />
      ) : null}

      {task.project && fetchedMessageSettings ? (
        <TaskMessagesContentMessagesList
          taskNanoId={task.nanoId}
          projectUuid={task.project.uuid}
          taskUuid={task.uuid}
          onReplyMessage={handleSetRepliedMessageId}
          sendingMessages={sendingMessages}
          onRemoveSendingMessage={handleRemoveSendingMessage}
          pinnedMessagesView={pinnedMessagesView}
          togglePinnedMessagesView={togglePinnedMessagesView}
          withWhiteboardMessages={messagesSettings.withWhiteboardMessages}
          messages={messagesWithTask}
          messagesError={messagesError}
          messagesFetched={messagesFetched}
          messagesFetchingNextPage={messagesFetchingNextPage}
          messagesFetchingPreviousPage={messagesFetchingPreviousPage}
          messagesIsPlaceholderData={messagesIsPlaceholderData}
          hasNextMessagesPage={hasNextMessagesPage}
          hasPreviousMessagesPage={hasPreviousMessagesPage}
          loadMoreMessages={loadMoreMessages}
          loadMorePreviousMessages={loadMorePreviousMessages}
          changeMessagesFilters={changeMessagesFilters}
          messagesFilters={messagesFilters}
          filterMessages={filterMessages}
        />
      ) : null}

      <DashboardActiveTaskMessageHeader
        task={task}
        isTaskMember={isTaskMember}
        onlyInvitations={onlyInvitations}
      />
    </Fragment>
  );
}

export default DashboardActiveTaskMessageContent;
