import setSeconds from 'date-fns/setSeconds';

import { TeamTimeZone } from '../../teamsTypes';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

const getTimeShift = (timeZone: TeamTimeZone): number => {
  const isNegative = timeZone.includes('-');

  const timeShift = isNegative
    ? timeZone.replace('UTC-', '')
    : timeZone.replace('UTC+', '');

  const [hours, minutes] = timeShift.replace('-', '').split(':');

  const numberHours = hours ? parseInt(hours) : 0;
  const numberMinutes = minutes ? parseInt(minutes) : 0;

  const totalSeconds =
    numberHours * SECONDS_IN_HOUR + numberMinutes * SECONDS_IN_MINUTE;

  return isNegative ? -1 * totalSeconds : totalSeconds;
};

const getTeamLocalTime = (timeZone: TeamTimeZone): Date => {
  const utcYear = new Date().getUTCFullYear();
  const utcMonth = new Date().getUTCMonth();
  const utcDay = new Date().getUTCDate();
  const utcHours = new Date().getUTCHours();
  const utcMinutes = new Date().getUTCMinutes();

  const timeShift = timeZone ? getTimeShift(timeZone) : 0;

  const dateWithTimeShift = setSeconds(
    new Date(utcYear, utcMonth, utcDay, utcHours, utcMinutes),
    timeShift
  );

  return dateWithTimeShift;
};

export default getTeamLocalTime;
