import React from 'react';

import { UserNanoID, UserUUID } from '../../../../../../../usersTypes';

import { FETCH_MESSAGES_TOTAL_COUNT_QUERY } from '../../../../../../../../messages/queries/fetchMessagesTotalCount.query';

import { useTotalsQuery } from '../../../../../../../../common/hooks/base/reactQuery/useTotalsQuery';

import { DashboardStatisticItem } from '../DashboardStatisticItem';

import { MessagePath } from '../../../../../../../../messages/MessagePath';
import { MessageCache } from '../../../../../../../../messages/MessageCache';
import { profilesKeys } from '../../../../../../../../../locales/keys';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface DashboardStatisticMyResultsProps {
  userUuid: UserUUID;
  userNanoId: UserNanoID;
  selfProfile: boolean;
}

function DashboardStatisticMyResults({
  userUuid,
  selfProfile,
  userNanoId
}: DashboardStatisticMyResultsProps) {
  const {
    data: messagesTotalCount,
    isFetched,
    isPlaceholderData
  } = useTotalsQuery({
    query: FETCH_MESSAGES_TOTAL_COUNT_QUERY,
    filters: {
      messagesFilters: {
        withoutHoursAndMinutes: true,
        userUuids: [userUuid]
      }
    },
    cacheKey: MessageCache.userTotalCountCacheKey(userUuid)
  });

  const userMessagesTotalCount =
    messagesTotalCount?.messages?.paginationInfo?.totalCount;

  return (
    <DashboardStatisticItem
      i18nTitle={profilesKeys.myMessages}
      icon={IconsEnum.CHAT_ALT}
      href={
        selfProfile
          ? MessagePath.myMessages()
          : MessagePath.userMessages(userNanoId)
      }
      loaded={isPlaceholderData || isFetched}
      value={userMessagesTotalCount || 0}
    />
  );
}

export default DashboardStatisticMyResults;
