import floor from 'lodash/floor';

import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../../../types';
import { MultiTrackTaskTimeFormFieldTask } from './MultiTrackTaskTimeFormField.types';

import { PeriodHelper } from '../../../../../../../helpers/PeriodHelper';
import { InputField } from '../../../../../../../helpers/FormFields/InputField';

import { formsFields } from '../../../../../../../locales/keys';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

interface MultiTrackTaskTimeFormFieldProps {
  task: MultiTrackTaskTimeFormFieldTask;
  isLoading?: IsLoading;
  registerHours: RegisterFormFieldType<HTMLInputElement>;
  registerMinutes: RegisterFormFieldType<HTMLInputElement>;
  registerBody: RegisterFormFieldType<HTMLInputElement>;
  hoursValidationError: ErrorMessage;
  minutesValidationError: ErrorMessage;
}

function MultiTrackTaskTimeFormField({
  hoursValidationError,
  minutesValidationError,
  registerBody,
  registerHours,
  registerMinutes,
  task,
  isLoading
}: MultiTrackTaskTimeFormFieldProps) {
  return (
    <li className="py-2">
      <div className="flex mb-2 gap-2 items-center font-semibold">
        <div className="flex-1 truncate">{task?.name}</div>
        {task?.fullTime && (
          <div className="shrink-0 text-right">
            <PeriodHelper
              hours={floor(task.fullTime / SECONDS_IN_HOUR)}
              minutes={floor(
                (task.fullTime % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE
              )}
            />
          </div>
        )}
      </div>

      <div className="flex gap-2 items-center">
        <div className="w-24">
          <InputField
            inputClassName="border-0 border-b border-gray-300 dark:border-gray-700 p-0 text-sm focus:ring-0 focus:border-blue-500 w-full dark:focus:placeholder-gray-500 placeholder-gray-400 focus:placeholder-gray-300 dark:bg-gray-850"
            className="flex-1"
            disabled={isLoading}
            error={hoursValidationError}
            showErrorIcon={false}
            i18nPlaceholder={formsFields.hours}
            name={registerHours.name}
            onChange={registerHours.onChange}
            ref={registerHours.ref}
            min={0}
            max={7}
            type="number"
          />
        </div>
        <div className="w-24">
          <InputField
            inputClassName="border-0 border-b border-gray-300 dark:border-gray-700 p-0 text-sm focus:ring-0 focus:border-blue-500 w-full dark:focus:placeholder-gray-500 placeholder-gray-400 focus:placeholder-gray-300 dark:bg-gray-850"
            className="flex-1"
            disabled={isLoading}
            error={minutesValidationError}
            showErrorIcon={false}
            i18nPlaceholder={formsFields.minutes}
            name={registerMinutes.name}
            onChange={registerMinutes.onChange}
            ref={registerMinutes.ref}
            min={0}
            max={59}
            type="number"
          />
        </div>
        {/* <div className="w-full">
          <InputField
            inputWrapperClassName="w-full"
            inputClassName="z-0 text-sm relative inline-flex items-center justify-between p-0 overflow-hidden gap-1 border-0 border-b border-gray-300 dark:border-gray-700 focus:border-blue-500 focus:ring-0 focus:ring-offset-0 w-full dark:focus:placeholder-gray-500 placeholder-gray-400 focus:placeholder-gray-300 dark:bg-gray-850"
            className="flex items-end"
            disabled={isLoading}
            showErrorIcon={false}
            i18nPlaceholder={formsFields.description}
            name={registerBody.name}
            onChange={registerBody.onChange}
            ref={registerBody.ref}
          />
        </div> */}
      </div>
    </li>
  );
}

export default MultiTrackTaskTimeFormField;
