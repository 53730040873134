import { ClassName } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { Icon } from '../../../../../helpers/Icon';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../helpers/Translate';

import { ProjectPath } from '../../../../projects/ProjectPath';
import { projectsKeys } from '../../../../../locales/keys';

interface MenuCreateProjectInTeamLinkProps {
  className?: ClassName;
}

function MenuCreateProjectInTeamLink({
  className
}: MenuCreateProjectInTeamLinkProps) {
  return (
    <div className={className}>
      <NextLinkHelper href={ProjectPath.createInTeam()} aria-hidden="true">
        <div className="relative p-2 flex items-center space-x-4 rounded-xl bg-blue-500 bg-opacity-0 hover:bg-opacity-10 focus-within:ring-base -m-2">
          <div className="shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-blue-500">
            <Icon
              className="h-8 w-8 text-white"
              icon={IconsEnum.PLUS_OUTLINE}
            />
          </div>

          <div>
            <h3 className="text-sm font-medium">
              <div className="focus:ring-0 focus:ring-offset-0">
                <Translate id={projectsKeys.create} />
                <span aria-hidden="true"> →</span>
              </div>
            </h3>
            <p className="mt-1 text-xs leading-4 text-gray-600 dark:text-gray-400">
              <Translate
                id={
                  projectsKeys.pleaseProvideTheProjectBriefFilesAndScopeOfWork
                }
              />
            </p>
          </div>
        </div>
      </NextLinkHelper>
    </div>
  );
}

export default MenuCreateProjectInTeamLink;
