import {
  FetchAccountBalancesCacheKey,
  FetchAccountBalancesGqlQuery,
  FetchAccountBalancesLimit,
  FetchAccountBalancesSort,
  FetchFinAccountBalancesFilters
} from '../../accountBalancesTypes';
import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

interface FinAccountBalancesProps {
  cacheKey: FetchAccountBalancesCacheKey;
  initialFilters?: FetchFinAccountBalancesFilters;
  initialSort?: FetchAccountBalancesSort;
  initialLimit?: FetchAccountBalancesLimit;
  query: FetchAccountBalancesGqlQuery;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

const scope = 'accountBalances';

function useFinPaginatedAccountBalances<
  FetchAccountBalancesBalanceType extends InfiniteIndexQueryBaseNodeType
>({
  query,
  cacheKey,
  initialFilters,
  initialSort,
  initialLimit,
  options = {}
}: FinAccountBalancesProps) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchAccountBalancesBalanceType>({
    cacheKey,
    scope,
    initialFilters,
    initialLimit,
    initialSort,
    query,
    options
  });

  return {
    accountBalancesData: data,
    accountBalances: items,
    accountBalancesError: itemsError,
    accountBalancesErrorMessage: itemsErrorMessage,
    accountBalancesTotalCount: itemsTotalCount,
    accountBalancesFetched: isFetched,
    accountBalancesLoading: isLoading,
    prefetchAccountBalances: prefetchItems,
    accountBalancesIsPlaceholderData: isPlaceholderData,
    accountBalancesFilters: currentFilters,
    accountBalancesSort: currentSort,
    accountBalancesPage: currentPage,
    accountBalancesLimit: currentLimit,
    paginateAccountBalances: paginateItems,
    updateAccountBalanceCache: updateItemCache,
    filterAccountBalances: filterItems,
    changeAccountBalancesFilters: changeItemsFilters,
    clearAccountBalancesFilters: clearItemsFilters,
    sortAccountBalances: sortItems,
    limitAccountBalances: limitItems
  };
}

export default useFinPaginatedAccountBalances;
