import React from 'react';
import flow from 'lodash/flow';
import replace from 'lodash/replace';

import { ClassName } from '../../types';

import { ReactMarkdown } from './helpers/ReactMarkdown';

interface MarkdownHelperProps {
  className?: ClassName;
  source: string;
  fromEmail?: boolean;
  fromMessage?: boolean;
  asPlainText?: boolean;
  linkTarget?: '_blank';
}

const stripEmailText = flow([
  (msg) => replace(msg, /&lt;/g, '<'),
  (msg) => replace(msg, /&nbsp;/g, ' '),
  (msg) => replace(msg, /(?=<!--)([\s\S]*?)-->/g, ''),
  (msg) =>
    replace(msg, /(?=-------- Original message --------)([\s\S]*?)$/g, ''),
  (msg) => replace(msg, /(?=Von meinem iPhone gesendet)([\s\S]*?)$/g, ''),
  (msg) => replace(msg, /(?=Sent from my iPhone)([\s\S]*?)$/g, ''),
  (msg) => replace(msg, /(?=Sent from my Huawei Mobile)([\s\S]*?)$/g, ''),
  (msg) =>
    replace(
      msg,
      /(?=On )([\s\S]*?)(?= CGICRM)([\s\S]*?)> wrote:([\s\S]*?)$/g,
      ''
    ),
  (msg) =>
    replace(
      msg,
      /(?=Am )([\s\S]*?)(?= um )([\s\S]*?)(?= schrieb)([\s\S]*?)$/g,
      ''
    ),
  (msg) =>
    replace(
      msg,
      /(?=Sent:)([\s\S]*?)(?=To:)([\s\S]*?)(?=Subject:)([\s\S]*?)$/g,
      ''
    ),
  (msg) =>
    replace(
      msg,
      /(?=From:)([\s\S]*?)(?=To:)([\s\S]*?)(?=Subject:)([\s\S]*?)$/g,
      ''
    )
]);

const stripMessageText = flow([
  (msg) => replace(msg, /^[?=>]{1}$/g, '&gt;'),
  (msg) => replace(msg, /^[?=+]{1}$/g, '&plus;'),
  (msg) => replace(msg, /^[?=*]{1}$/g, '&ast;'),
  (msg) => replace(msg, /^[?=#]{1}$/g, '&num;')
]);

const emptyAllowedElements: string[] = [];

function MarkdownHelper({
  className,
  source,
  fromEmail = false,
  fromMessage = false,
  asPlainText,
  linkTarget
}: MarkdownHelperProps) {
  const msg = fromMessage
    ? stripMessageText(source)
    : replace(source, /&gt;/g, '>');

  return (
    <ReactMarkdown
      className={className}
      allowedElements={asPlainText ? emptyAllowedElements : undefined}
      unwrapDisallowed={asPlainText ? true : undefined}
      linkTarget={linkTarget}
    >
      {fromEmail ? stripEmailText(msg) : msg}
    </ReactMarkdown>
  );
}

export default MarkdownHelper;
