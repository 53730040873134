import React, { useCallback } from 'react';

import { DashboardActiveTasksListTabNames } from '../../DashboardActiveTasksList.types';

import { ButtonHelper } from '../../../../../../../../../../../helpers/buttons/ButtonHelper';
import { Translate } from '../../../../../../../../../../../helpers/Translate';

import { I18nText } from '../../../../../../../../../../../types';

interface DashboardActiveTasksListTabsButtonProps {
  active: boolean;
  name: DashboardActiveTasksListTabNames;
  changeTab: (tab: DashboardActiveTasksListTabNames) => void;
  i18nText: I18nText;
  count: number;
}

function DashboardActiveTasksListTabsButton({
  active,
  name,
  i18nText,
  changeTab,
  count
}: DashboardActiveTasksListTabsButtonProps) {
  const handleChangeTab = useCallback<() => void>(
    () => changeTab(name),
    [changeTab, name]
  );

  if (
    (name === DashboardActiveTasksListTabNames.INVITATIONS ||
      name === DashboardActiveTasksListTabNames.QUALITY_CONTROL) &&
    count === 0
  ) {
    return null;
  }

  return (
    <ButtonHelper
      key={name}
      onClick={handleChangeTab}
      className={
        active
          ? 'rounded-full flex items-center space-x-2 py-1.5 px-4 text-sm font-medium border focus:ring-offset-0 m-0.5 bg-blue-500 bg-opacity-20 text-blue-600 dark:text-blue-400 border-transparent'
          : 'rounded-full flex items-center space-x-2 py-1.5 px-4 text-sm font-medium border focus:ring-offset-0 m-0.5 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800'
      }
    >
      <span>
        <Translate id={i18nText} />
      </span>

      <div className="border-l opacity-70 border-current pl-2 font-normal text-xs">
        {count}
      </div>
    </ButtonHelper>
  );
}

export default DashboardActiveTasksListTabsButton;
