import React, { Fragment } from 'react';

import { SmartContractItemItemCategoryName } from '../../../../../../../../../../smartContracts/smartContractsTypes';

import { PercentHelper } from '../../../../../../../../../../../helpers/PercentHelper';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';
import { ContentWithTooltip } from '../../../../../../../../../../../helpers/ContentWithTooltip';

interface DashboardFinanceSmartContractsItemDefaultProps {
  itemCategoryName: SmartContractItemItemCategoryName;
  percent: number;
  width: number;
}

type DashboardFinanceSmartContractsItemQuantityProps = {
  quantity: number;
  amount?: never;
};

type DashboardFinanceSmartContractsItemAmountProps = {
  quantity?: never;
  amount: number;
};

type DashboardFinanceSmartContractsItemProps =
  DashboardFinanceSmartContractsItemDefaultProps &
    (
      | DashboardFinanceSmartContractsItemQuantityProps
      | DashboardFinanceSmartContractsItemAmountProps
    );

function DashboardFinanceSmartContractsItem({
  itemCategoryName,
  quantity,
  amount,
  percent,
  width
}: DashboardFinanceSmartContractsItemProps) {
  return (
    <Fragment>
      <ContentWithTooltip
        className="dark:text-gray-400 whitespace-nowrap w-24 overflow-hidden truncate"
        tooltipChildren={itemCategoryName}
      >
        {itemCategoryName}
      </ContentWithTooltip>

      <div className="dark:text-gray-400 min-w-12 text-right">
        {amount ? <MoneyHelper value={amount} /> : quantity}
      </div>

      <div className="flex gap-2">
        <div
          className="rounded max-w-full h-4 dark:bg-gray-700 bg-gray-400"
          style={{ width: `${width}%`, minWidth: '8px' }}
        />
        <div className="dark:text-gray-400 w-12 self-end">
          <PercentHelper percent={percent} />
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardFinanceSmartContractsItem;
