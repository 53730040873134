import React, { useEffect } from 'react';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { DashboardActiveTasksListItemsListItem } from './components/DashboardActiveTasksListItemsListItem';
import { DashboardActiveTasksListItemsListActiveTask } from './DashboardActiveTasksListItemsList.types';
import { DashboardActiveTasksListSelectedItem } from '../../DashboardActiveTasksList.types';

interface DashboardActiveTasksListItemsListProps {
  activeTasks: DashboardActiveTasksListItemsListActiveTask[];
  selectedItem: DashboardActiveTasksListSelectedItem;
  onSelectedItem: (value: DashboardActiveTasksListSelectedItem) => void;
}

function DashboardActiveTasksListItemsList({
  activeTasks,
  onSelectedItem,
  selectedItem
}: DashboardActiveTasksListItemsListProps) {
  useEffect(() => {
    if (!isEmpty(activeTasks) && !selectedItem) {
      onSelectedItem({
        nanoId: activeTasks[0]?.nanoId,
        scope: activeTasks[0]?.scope
      });
    }
  }, [activeTasks, onSelectedItem, selectedItem]);

  useEffect(() => {
    if (selectedItem) {
      every(activeTasks, (item) => item.nanoId !== selectedItem.nanoId) &&
        onSelectedItem(null);
    }
  }, [activeTasks, onSelectedItem, selectedItem]);

  return (
    <ul className="divide-y divide-gray-500/10">
      {activeTasks.map((item) => (
        <DashboardActiveTasksListItemsListItem
          key={item.uuid}
          item={item}
          selected={selectedItem?.nanoId === item.nanoId}
          onSelected={onSelectedItem}
        />
      ))}
    </ul>
  );
}

export default DashboardActiveTasksListItemsList;
