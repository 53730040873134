import React from 'react';

import { TaskName, TaskNanoID } from '../../../../../../../../tasks/tasksTypes';

import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { NextPureLinkHelper } from '../../../../../../../../../helpers/links/NextPureLinkHelper';

import { words } from '../../../../../../../../../locales/keys';
import { TaskPath } from '../../../../../../../../tasks/TaskPath';

interface DashboardMissingSourceFilesTaskProps {
  taskNanoId: TaskNanoID;
  taskName: TaskName;
}

function DashboardMissingSourceFilesTask({
  taskNanoId,
  taskName
}: DashboardMissingSourceFilesTaskProps) {
  return (
    <div className="flex gap-2 text-sm w-full">
      <NextPureLinkHelper
        href={TaskPath.show(taskNanoId)}
        className="dark:text-gray-400 flex-1 truncate hover:underline"
        text={taskName}
      />

      <SubmitSourceFilesToTaskModalButton
        taskNanoId={taskNanoId}
        className="p-0 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline"
        i18nText={words.upload}
      />
    </div>
  );
}

export default DashboardMissingSourceFilesTask;
