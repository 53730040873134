import React, { ReactNode } from 'react';
import cl from 'classnames';

import { FetchItemError, ClassName } from '../../../../types';

import { MainLayout } from '../MainLayout';

import { DefaultSecondaryMenu } from '../../../common/components/menus/DefaultSecondaryMenu';
import { MainScrollWrapper } from '../../helpers/MainScrollWrapper';

interface ShowItemLayoutProps {
  action: string;
  withoutActionError?: boolean;
  i18nTitle?: string;
  title?: string;
  header?: ReactNode;
  tabs?: ReactNode;
  children: ReactNode;
  sidebar?: ReactNode;
  customSecondaryMenu?: ReactNode;
  mainAddClassName?: ClassName;
  mainClassName?: ClassName;
  headerWrapClassName?: ClassName;
  error?: FetchItemError;
  withScrollToTop?: boolean;
  withoutSecondaryMenu?: boolean;
}

function ShowItemLayout({
  action,
  withoutActionError,
  i18nTitle,
  title,
  header,
  tabs,
  children,
  sidebar,
  customSecondaryMenu,
  mainAddClassName,
  mainClassName,
  headerWrapClassName,
  error,
  withScrollToTop,
  withoutSecondaryMenu
}: ShowItemLayoutProps) {
  return (
    <MainLayout
      action={action}
      withoutActionError={withoutActionError}
      i18nTitle={i18nTitle}
      title={title}
      error={error}
    >
      <div className="flex flex-1">
        {withoutSecondaryMenu
          ? null
          : customSecondaryMenu || <DefaultSecondaryMenu />}
        <div className="flex flex-col w-full z-0">
          {header || tabs ? (
            <div
              id="page-header-wrap"
              className={cl(
                headerWrapClassName || 'relative bg-white dark:bg-gray-900'
              )}
            >
              {header}
              {tabs}
            </div>
          ) : null}

          {/* Page content wrapper */}
          <div className="relative flex-1 xl:z-0">
            <div className="absolute inset-0 flex overflow-hidden">
              {/* Page main content */}
              <MainScrollWrapper
                withScrollToTop={withScrollToTop}
                className={
                  mainClassName ||
                  cl(
                    'flex-1 relative overflow-y-auto focus:outline-none z-0 xl:z-15',
                    mainAddClassName
                  )
                }
              >
                {children}
              </MainScrollWrapper>
              {sidebar}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default ShowItemLayout;
