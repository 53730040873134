import React, { Fragment, useMemo } from 'react';
import cl from 'classnames';

import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isWeekend from 'date-fns/isWeekend';
import subDays from 'date-fns/subDays';

import floor from 'lodash/floor';
import isYesterday from 'date-fns/isYesterday';

import { DashboardTrackedTimeFilterType } from '../../DashboardTrackedTime.types';

import { Translate } from '../../../../../../../../../helpers/Translate';
import { DateHelper } from '../../../../../../../../../helpers/DateHelper';
import { PeriodHelper } from '../../../../../../../../../helpers/PeriodHelper';

import { TrackedTimeCountByPeriodsDateType } from '../../utils/getTrackedTimeByPeriodsDate';

import { words } from '../../../../../../../../../locales/keys';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

const MIN_WORK_HOURS = 6 * SECONDS_IN_HOUR;

function getNumberWorkingDays(
  startDate: Date,
  endDate: Date,
  type: DashboardTrackedTimeFilterType
) {
  const end =
    type === DashboardTrackedTimeFilterType.MONTH
      ? subDays(endDate, 1)
      : endDate;

  const days =
    type === DashboardTrackedTimeFilterType.DAY
      ? [startDate]
      : eachDayOfInterval({
          end,
          start: startDate
        });

  return days.filter((day) => !isWeekend(day)).length;
}

interface DashboardTrackedTimePeriodProps {
  trackedTimeByPeriod: TrackedTimeCountByPeriodsDateType;
  type: DashboardTrackedTimeFilterType;
}

function DashboardTrackedTimePeriod({
  trackedTimeByPeriod,
  type
}: DashboardTrackedTimePeriodProps) {
  const width = `${trackedTimeByPeriod.percent}%`;
  const minWidth = '8px';

  const numberWorkingsDays = useMemo<number>(
    () =>
      getNumberWorkingDays(
        trackedTimeByPeriod.period.startDate,
        trackedTimeByPeriod.period.endDate,
        type
      ),
    [trackedTimeByPeriod, type]
  );

  const minimumTimeWorked = useMemo<boolean>(
    () =>
      numberWorkingsDays
        ? trackedTimeByPeriod.fullTime / numberWorkingsDays >= MIN_WORK_HOURS
        : false,
    [numberWorkingsDays, trackedTimeByPeriod.fullTime]
  );

  return (
    <div className="flex gap-2 text-sm w-full">
      <div className="shrink-0 dark:text-gray-400 whitespace-nowrap w-24">
        {type === DashboardTrackedTimeFilterType.DAY &&
          isYesterday(trackedTimeByPeriod.period.startDate) && (
            <Translate id={words.yesterday} />
          )}

        {type === DashboardTrackedTimeFilterType.DAY &&
          !isYesterday(trackedTimeByPeriod.period.startDate) && (
            <DateHelper
              date={trackedTimeByPeriod.period.startDate}
              customFormat="dd MMM"
            />
          )}

        {type === DashboardTrackedTimeFilterType.MONTH && (
          <DateHelper
            date={trackedTimeByPeriod.period.startDate}
            customFormat="MMM yyyy"
          />
        )}

        {type === DashboardTrackedTimeFilterType.WEEK && (
          <Fragment>
            <DateHelper
              date={trackedTimeByPeriod.period.startDate}
              customFormat="dd MMM"
            />
            {'-'}
            <DateHelper
              date={trackedTimeByPeriod.period.endDate}
              customFormat="dd MMM"
            />
          </Fragment>
        )}
      </div>

      <div className="shrink-0 dark:text-gray-400 whitespace-nowrap w-16 text-right">
        <PeriodHelper
          hours={floor(trackedTimeByPeriod.fullTime / SECONDS_IN_HOUR)}
          minutes={floor(
            (trackedTimeByPeriod.fullTime % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE
          )}
          nullable
        />
      </div>

      <div className="grow">
        <div
          className={cl('rounded max-w-full h-4', {
            'bg-red-500 dark:opacity-25': trackedTimeByPeriod.fullTime === 0,
            'bg-green-500 dark:opacity-25': minimumTimeWorked,
            'dark:bg-gray-700 bg-gray-400':
              trackedTimeByPeriod.fullTime !== 0 && !minimumTimeWorked
          })}
          style={{ width: trackedTimeByPeriod.percent ? width : minWidth }}
        />
      </div>
    </div>
  );
}

export default DashboardTrackedTimePeriod;
