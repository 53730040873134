import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

import { TaskNanoID } from '../../../../../../../../../../tasks/tasksTypes';
import { InvoiceStatuses } from '../../../../../../../../../../invoices/invoicesTypes';

import {
  FETCH_TASK_SMART_CONTRACTS_QUERY,
  FetchTaskSmartContractsQueryResponse
} from '../../../../../../../../../../smartContracts/queries/fetchTaskSmartContracts.query';

import { useFinPaginatedSmartContracts } from '../../../../../../../../../../smartContracts/hooks/useFinPaginatedSmartContracts';
import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';

import { Progress } from '../../../../../../../../../../../helpers/Progress';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';
import { AlertMessage } from '../../../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../../../helpers/LoadingSkeleton';

import { getSmartContractIFilteredItems } from '../../../../../../../../../../smartContracts/utils/getSmartContractIFilteredItems';

import { SmartContractCache } from '../../../../../../../../../../smartContracts/SmartContractCache';
import { TasksPermissions } from '../../../../../../../../../../tasks/tasksConstants';

interface DashboardActiveTaskMessageHeaderSmartContractPaymentsProps {
  taskNanoId: TaskNanoID;
}

function DashboardActiveTaskMessageHeaderSmartContractPayments({
  taskNanoId
}: DashboardActiveTaskMessageHeaderSmartContractPaymentsProps) {
  const currentUser = useCurrentUser();

  const {
    smartContracts,
    smartContractsFetched,
    smartContractsIsPlaceholderData,
    smartContractsError
  } = useFinPaginatedSmartContracts<FetchTaskSmartContractsQueryResponse>({
    query: FETCH_TASK_SMART_CONTRACTS_QUERY,
    cacheKey: SmartContractCache.taskSmartContractsCacheKey(taskNanoId),
    initialFilters: { taskNanoId: { eq: taskNanoId } },
    options: {
      withoutPrefetch: true
    }
  });

  const smartContractsItems = useMemo(
    () =>
      getSmartContractIFilteredItems(
        compact(flatten(map(smartContracts, 'items'))),
        currentUser?.hasPermissions(
          TasksPermissions.READ_TASK_SIDEBAR_TASK_SMART_CONTRACT_PAYMENTS_BLOCK_WITH_REGULAR_NET_INVOICES
        )
      ),
    [currentUser, smartContracts]
  );

  const smartContractTotal = useMemo<number>(
    () => sumBy(smartContractsItems, 'price'),
    [smartContractsItems]
  );

  const prepaymentAmount = useMemo<number>(() => {
    const paidInvoice = map(smartContractsItems, (item) =>
      filter(
        item.invoices,
        (invoice) => invoice.status === InvoiceStatuses.PAID
      )
    );

    return sumBy(flatten(paidInvoice), (invoice) => invoice.amount);
  }, [smartContractsItems]);

  if (
    smartContractTotal === 0 &&
    (smartContractsFetched || smartContractsIsPlaceholderData)
  ) {
    return null;
  }

  return (
    <div className="w-32">
      <LoadingSkeleton
        loaded={smartContractsFetched || smartContractsIsPlaceholderData}
        count={1}
        className="w-full"
      >
        <div className="flex gap-2 items-center leading-tight">
          <div className="text-gray-900 dark:text-gray-200 font-normal text-sm">
            <span className="mt-1">
              <MoneyHelper value={smartContractTotal} />
            </span>
          </div>
          {prepaymentAmount !== 0 && (
            <div className="text-xs text-gray-500 whitespace-nowrap">
              <span>
                <MoneyHelper value={prepaymentAmount} />
              </span>
            </div>
          )}
        </div>

        <Progress min={prepaymentAmount} max={smartContractTotal} />
      </LoadingSkeleton>

      <AlertMessage message={smartContractsError} />
    </div>
  );
}

export default DashboardActiveTaskMessageHeaderSmartContractPayments;
