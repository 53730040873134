import { gql } from 'graphql-request';

import {
  TeamGeneralLedgerID,
  TeamID,
  TeamNanoID,
  TeamRevenue,
  TeamRevenueReverse,
  TeamUUID
} from '../teamsTypes';

export interface FetchTeamRevenueQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  revenue: TeamRevenue;
  revenueReverse: TeamRevenueReverse;
  generalLedgerId: TeamGeneralLedgerID;
}

export const FETCH_TEAM_REVENUE_QUERY = gql`
  query TeamRevenue($uuid: ID!) {
    team(uuid: $uuid) {
      id
      uuid
      nanoId
      revenue
      revenueReverse
      generalLedgerId
    }
  }
`;
