import {
  TaskID,
  CheckInTasksCacheKeys,
  CheckInTasksGqlError,
  CheckInTasksGqlStatus
} from '../../tasksTypes';
import { UserCompetencyID } from '../../../userCompetencies/userCompetenciesTypes';

import { BATCH_CHECK_IN_TO_TASKS } from '../../queries/batchCheckInToTasks.query';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface CheckInTasksOptions {
  cacheKeys: CheckInTasksCacheKeys;
}

export interface CheckInTasksResponse {
  batchCheckInToTasks: {
    status: CheckInTasksGqlStatus;
    errors: CheckInTasksError;
  };
}

export interface CheckInTasksMutationInput {
  contractorCompetencyId: UserCompetencyID;
  taskIds: TaskID[];
}

export interface CheckInTasksError {
  fullMessages: CheckInTasksGqlError;
}

const action = 'batchCheckInToTasks';

function useCheckInTasks({ cacheKeys }: CheckInTasksOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    CheckInTasksMutationInput,
    CheckInTasksResponse,
    CheckInTasksError,
    undefined
  >({ action, cacheKeys, query: BATCH_CHECK_IN_TO_TASKS });

  return {
    checkInTasksData: updateQueryData,
    checkInTasksError: updateQueryError,
    checkInTasksLoading: updateQueryLoading,
    checkInTasksErrorMessage: updateQueryErrorMessage,
    checkInTasks: updateQuery,
    checkInTasksReset: updateQueryReset
  };
}

export default useCheckInTasks;
