import { useCallback } from 'react';

import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';
import {
  FetchTasksGqlQuery,
  FetchTasksCacheKey,
  FetchTasksFilters,
  FetchTasksSort,
  FetchTasksPage,
  FetchTasksLimit
} from '../../tasksTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  INITIAL_TASKS_FILTERS,
  INITIAL_TASKS_LIMIT,
  INITIAL_TASKS_PAGE,
  INITIAL_TASKS_SORT
} from '../../tasksConstants';

interface TasksDefaultOptions {
  cacheKey: FetchTasksCacheKey;
  query: FetchTasksGqlQuery;
  initialFilters?: FetchTasksFilters;
  initialSort?: FetchTasksSort;
  initialPage?: FetchTasksPage;
  initialLimit?: FetchTasksLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

interface TasksWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface TasksWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type TasksOptions = TasksDefaultOptions &
  (TasksWithPrefetchItemOptions | TasksWithoutPrefetchItemOptions);

const scope = 'tasks';

function usePaginatedTasks<ItemType, MetaType = undefined>({
  cacheKey,
  query,
  initialFilters = INITIAL_TASKS_FILTERS,
  initialSort = INITIAL_TASKS_SORT,
  initialPage = INITIAL_TASKS_PAGE,
  initialLimit = INITIAL_TASKS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: TasksOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    meta,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFiltersPersistInitial,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useIndexQuery<ItemType, MetaType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    tasksData: data,
    tasks: items,
    tasksError: itemsError,
    tasksTotalCount: itemsTotalCount,
    tasksMeta: meta,
    tasksFetched: isFetched,
    tasksLoading: isLoading,
    tasksIsPlaceholderData: isPlaceholderData,
    tasksFilters: currentFilters,
    tasksSort: currentSort,
    tasksPage: currentPage,
    tasksLimit: currentLimit,
    updateTaskCache: updateItemCache,
    filterTasks: filterItems,
    changeTasksFilters: changeItemsFilters,
    clearTasksFiltersPersistInitial: clearItemsFiltersPersistInitial,
    clearTasksFilters: clearItemsFilters,
    sortTasks: sortItems,
    paginateTasks: paginateItems,
    limitTasks: limitItems,
    prefetchTasks: prefetchItems,
    prefetchTask: prefetchItem,
    handleResetFilters: useCallback<() => void>(() => {
      filterItems(initialFilters);
    }, [initialFilters, filterItems])
  };
}

export default usePaginatedTasks;
