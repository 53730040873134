import {
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  NanoIdFilterType,
  UpdatedAt
} from '../../types';

export type OpenRegular = number;
export type PaidRegular = number;
export type Price = number;
export type SentRegular = number;
export type SmartContractLastStatusChangeAt = UpdatedAt;

export type FetchTasksPriceCacheKey = FetchItemsCacheKey;

export type FetchTasksPriceGqlQuery = FetchItemsGqlQuery;

export interface FetchTasksPriceFilters {
  nanoId?: NanoIdFilterType;
  smartContractLastStatusChangeAt?: DateFilterType;
}

export type FetchTasksPriceSort = FetchItemsSort;
export type FetchTasksPricePage = FetchItemsPage;
export type FetchTasksPriceLimit = FetchItemsLimit;

export const enum FetchTasksPriceSortTypes {
  SMART_CONTRACT_LAST_STATUS_CHANGE_AT_ASC = 'SMART_CONTRACT_LAST_STATUS_CHANGE_AT_ASC',
  SMART_CONTRACT_LAST_STATUS_CHANGE_AT_DESC = 'SMART_CONTRACT_LAST_STATUS_CHANGE_AT_DESC'
}
