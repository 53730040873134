import toNumber from 'lodash/toNumber';

import { formsErrors } from '../../../../../../../locales/keys';

function useMultiTrackTaskTimeFormValidationRule() {
  const registerHoursValidationRule = {
    validate: {
      isValid: (value: string | null) => {
        const valueAsNumber = value ? toNumber(value) : 0;
        if (valueAsNumber > 7) {
          return formsErrors.hours.lessEight;
        }
        if (valueAsNumber < 0) {
          return formsErrors.hours.nonNegative;
        }
        return true;
      }
    }
  };

  const registerMinutesValidationRule = {
    validate: {
      isValid: (value: string | null) => {
        const valueAsNumber = value ? toNumber(value) : 0;
        if (valueAsNumber > 60) {
          return formsErrors.minutes.lessSixty;
        }
        if (valueAsNumber < 0) {
          return formsErrors.hours.nonNegative;
        }
        return true;
      }
    }
  };

  return {
    registerHoursValidationRule,
    registerMinutesValidationRule
  };
}

export { useMultiTrackTaskTimeFormValidationRule };
