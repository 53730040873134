import {
  FetchTaskMemberInvitesGqlQuery,
  FetchTaskMemberInvitesCacheKey,
  FetchTaskMemberInvitesSort,
  FetchTaskMemberInvitesLimit,
  FetchFinTaskMemberInvitesFilters
} from '../../taskMemberInvitesTypes';

import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

import {
  INITIAL_TASK_MEMBER_INVITES_FILTERS,
  INITIAL_TASK_MEMBER_INVITES_LIMIT,
  INITIAL_TASK_MEMBER_INVITES_SORT
} from '../../taskMemberInvitesConstants';

interface TaskMemberInvitesOptions {
  cacheKey: FetchTaskMemberInvitesCacheKey;
  query: FetchTaskMemberInvitesGqlQuery;
  initialFilters?: FetchFinTaskMemberInvitesFilters;
  initialSort?: FetchTaskMemberInvitesSort;
  initialLimit?: FetchTaskMemberInvitesLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'taskMemberInvites';

function useFinTaskMemberInvites<
  TaskMemberInviteType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_TASK_MEMBER_INVITES_FILTERS,
  initialSort = INITIAL_TASK_MEMBER_INVITES_SORT,
  initialLimit = INITIAL_TASK_MEMBER_INVITES_LIMIT,
  options = {}
}: TaskMemberInvitesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems,
    changeItemsFilters
  } = useFinInfiniteIndexQuery<TaskMemberInviteType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    taskMemberInvitesData: data,
    taskMemberInvites: items,
    taskMemberInvitesError: itemsError,
    taskMemberInvitesTotalCount: itemsTotalCount,
    taskMemberInvitesFetched: isFetched,
    taskMemberInvitesLoading: isLoading,
    taskMemberInvitesFetchingNextPage: isFetchingNextPage,
    taskMemberInvitesIsPlaceholderData: isPlaceholderData,
    taskMemberInvitesFilters: currentFilters,
    taskMemberInvitesSort: currentSort,
    taskMemberInvitesPage: currentPage,
    taskMemberInvitesLimit: currentLimit,
    hasNextTaskMemberInvitesPage: hasNextPage,
    updateTaskMemberInviteCache: updateItemCache,
    loadMoreTaskMemberInvites: loadMoreItems,
    filterTaskMemberInvites: filterItems,
    clearTaskMemberInvitesFilters: clearItemsFilters,
    sortTaskMemberInvites: sortItems,
    limitTaskMemberInvites: limitItems,
    changeTaskMemberInvitesFilters: changeItemsFilters
  };
}

export default useFinTaskMemberInvites;
