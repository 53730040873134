import { MessageBody, MessageUUID } from '../../messagesTypes';
import { UpdateMessageVersionRecordType } from '../../queries/updateMessageVersion.query';
import { UpdateItemsCacheKeys } from '../../../../types/updateItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateMessageVersionOptions {
  cacheKeys: UpdateItemsCacheKeys;
  query: string;
}

interface UpdateMessageVersionInput {
  uuid: MessageUUID;
  body: MessageBody;
}

interface UpdateMessageVersionError {
  fullMessages: string[] | null;
}

interface UpdateMessageVersionResponse<UpdateMessageVersionRecord> {
  updateMessageVersion: {
    status: string;
    record: UpdateMessageVersionRecord;
    errors: UpdateMessageVersionError;
  };
}

const action = 'updateMessageVersion';

function useUpdateMessageVersion<
  UpdateMessageVersionRecord extends UpdateMessageVersionRecordType
>({ cacheKeys, query }: UpdateMessageVersionOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateMessageVersionInput,
    UpdateMessageVersionResponse<UpdateMessageVersionRecord>,
    UpdateMessageVersionError,
    UpdateMessageVersionRecord
  >({
    action,
    cacheKeys,
    query
  });

  return {
    updateMessageVersionData: updateQueryData,
    updateMessageVersionError: updateQueryError,
    updateMessageVersionLoading: updateQueryLoading,
    updateMessageVersionErrorMessage: updateQueryErrorMessage,
    updateMessageVersion: updateQuery,
    updateMessageVersionReset: updateQueryReset
  };
}

export default useUpdateMessageVersion;
