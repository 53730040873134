import React from 'react';

import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';

import {
  FETCH_SHOW_PROJECT_QUERY,
  FetchShowProjectQueryResponse
} from '../../../../../../../../projects/queries/fetchShowProject.query';

import { useProject } from '../../../../../../../../projects/hooks/useProject';

import { DashboardActiveTaskProjectMessagesContent } from './components/DashboardActiveTaskProjectMessagesContent';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { ProjectCache } from '../../../../../../../../projects/ProjectCache';

interface DashboardActiveTaskProjectMessagesProps {
  projectNanoId?: ProjectNanoID;
}

function DashboardActiveTaskProjectMessages({
  projectNanoId
}: DashboardActiveTaskProjectMessagesProps) {
  const { project, projectError, projectFetched, projectIsPlaceholderData } =
    useProject<FetchShowProjectQueryResponse>({
      projects: [],
      cacheKey: ProjectCache.showCacheKey(),
      query: FETCH_SHOW_PROJECT_QUERY,
      uuid: projectNanoId,
      options: {
        enabled: !!projectNanoId,
        enabledPlaceholder: !!projectNanoId
      }
    });

  return (
    <div className="h-full flex-1 border-l-4 border-double dark:border-gray-800">
      <div className="h-full flex flex-col-reverse">
        <AlertMessage addClassName="mt-4" message={projectError} />
        <LoadingSkeleton
          loaded={!projectNanoId || projectFetched || projectIsPlaceholderData}
        >
          <DashboardActiveTaskProjectMessagesContent project={project} />
        </LoadingSkeleton>
      </div>
    </div>
  );
}

export default DashboardActiveTaskProjectMessages;
