import { gql } from 'graphql-request';

import {
  TaskNanoID,
  TaskOpenRegular,
  TaskPaidRegular,
  TaskPrice,
  TaskSentRegular,
  TaskSmartContractLastStatusChangeAt
} from '../tasksTypes';

export interface FetchPaginatedTasksPriceQueryResponse {
  openRegular: TaskOpenRegular;
  paidRegular: TaskPaidRegular;
  price: TaskPrice;
  sentRegular: TaskSentRegular;
  taskNanoId: TaskNanoID;
  smartContractLastStatusChangeAt: TaskSmartContractLastStatusChangeAt;
}

export const FETCH_PAGINATED_TASKS_PRICE_QUERY = gql`
  query IndexTasksPrice(
    $filters: BitTasksPriceFilters
    $sort: [BitTaskPriceSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    tasksPrice(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        openRegular
        paidRegular
        price
        sentRegular
        taskNanoId
        smartContractLastStatusChangeAt
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
