import { gql } from 'graphql-request';

import { MessageID, MessageUUID } from '../messagesTypes';

export interface CreateMessageWithProjectNanoIdQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
}

export const CREATE_MESSAGE_WITH_PROJECT_NANO_ID = gql`
  mutation CreateMessageWithProjectNanoId(
    $attachedFileAttachmentIds: [ID!]
    $body: String!
    $colors: [String!]
    $fileAttachmentId: ID
    $fileAttachmentIds: [ID!]
    $flagged: Boolean
    $mentionedUserIds: [ID!]
    $messageType: String
    $muted: Boolean
    $mutedForClientsAt: DateTime
    $projectNanoId: NanoId!
    $propagate: Boolean
    $red: Boolean
    $selectedLifestyleIds: [ID!]
    $selectedMaterialIds: [ID!]
    $selectedProductIds: [ID!]
    $threadKeyId: String
    $threadStart: Boolean
    $visibleForClient: Boolean
    $whiteboardKeyId: String
    $repliedMessageId: ID
  ) {
    createMessageWithProjectNanoId(
      input: {
        attachedFileAttachmentIds: $attachedFileAttachmentIds
        body: $body
        colors: $colors
        fileAttachmentId: $fileAttachmentId
        fileAttachmentIds: $fileAttachmentIds
        flagged: $flagged
        mentionedUserIds: $mentionedUserIds
        messageType: $messageType
        muted: $muted
        mutedForClientsAt: $mutedForClientsAt
        projectNanoId: $projectNanoId
        propagate: $propagate
        red: $red
        selectedLifestyleIds: $selectedLifestyleIds
        selectedMaterialIds: $selectedMaterialIds
        selectedProductIds: $selectedProductIds
        threadKeyId: $threadKeyId
        threadStart: $threadStart
        visibleForClient: $visibleForClient
        whiteboardKeyId: $whiteboardKeyId
        repliedMessageId: $repliedMessageId
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        projectId
        body
        visibleForClient
        red
        flagged
        propagate
        colors
        fileAttachmentIds
        attachedFileAttachmentIds
        selectedProductIds
        selectedLifestyleIds
        selectedMaterialIds
        fullMessages
      }
    }
  }
`;
