import React, { useCallback, useMemo } from 'react';
import cl from 'classnames';
import find from 'lodash/find';
import some from 'lodash/some';

import { UpdateItemsCacheKeys } from '../../../../../../../types/updateItemsTypes';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';
import {
  SmartContractSmartContractTypes,
  SmartContractStatuses
} from '../../../../../../smartContracts/smartContractsTypes';
import { SmartContractShareStatuses } from '../../../../../../smartContractShares/smartContractSharesTypes';
import { TaskMemberInvitesListItemTask } from './TaskMemberInvitesListItem.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { DeclineTaskMemberInviteButton } from '../../../../buttons/DeclineTaskMemberInviteButton';
import { AcceptInviteToTaskButton } from '../../../../../../tasks/components/buttons/AcceptInviteToTaskButton';
import { CheckInSmartContractShareButton } from '../../../../../../smartContractShares/components/buttons/CheckInSmartContractShareButton';
import { CheckInTasksSmartContractRuleModalButton } from '../../../../../../tasks/components/modalButtons/CheckInTasksSmartContractRuleModalButton';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { TaskPath } from '../../../../../../tasks/TaskPath';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { TaskMemberInvitesPermissions } from '../../../../../taskMemberInvitesConstants';
import {
  taskMemberInvitesKeys,
  tasksKeys
} from '../../../../../../../locales/keys';

interface TaskMemberInvitesListItemProps {
  taskMemberInvite: TaskMemberInvitesListItemTask;
  withActions: boolean;
  selected: boolean;
  onSelected: (value: TaskNanoID) => void;
  cacheKeys?: UpdateItemsCacheKeys;
}

function TaskMemberInvitesListItem({
  taskMemberInvite,
  withActions,
  selected,
  onSelected,
  cacheKeys
}: TaskMemberInvitesListItemProps) {
  const currentUser = useCurrentUser();

  const handleSelected = useCallback<() => void>(() => {
    onSelected(taskMemberInvite.bitTask.nanoId);
  }, [onSelected, taskMemberInvite]);

  const draftSmartContract = useMemo(
    () =>
      find(
        taskMemberInvite.bitTask?.smartContracts,
        (smartContract) => smartContract?.status === SmartContractStatuses.DRAFT
      ),
    [taskMemberInvite.bitTask?.smartContracts]
  );

  const draftSmartContractWithInvited = useMemo(
    () =>
      some(
        draftSmartContract?.smartContractShares,
        (smartContractShare) =>
          smartContractShare.status === SmartContractShareStatuses.INVITED &&
          smartContractShare.generalLedger?.company?.owner?.id ===
            currentUser.id
      )
        ? draftSmartContract
        : null,
    [currentUser.id, draftSmartContract]
  );

  const smartContractShareId = useMemo(
    () =>
      draftSmartContractWithInvited?.smartContractShares?.find(
        (smartContractShare) =>
          smartContractShare?.generalLedger?.company?.owner?.id ===
          currentUser.id
      )?.id,
    [draftSmartContractWithInvited, currentUser.id]
  );

  return (
    <li
      onClick={handleSelected}
      className={cl(
        'cursor-pointer flex items-center space-x-4 py-2 px-3 focus-within:ring-2 ring-inset focus-within:z-5 focus-within:ring-gray-850 dark:focus-within:ring-gray-100',
        selected
          ? 'bg-gray-100 dark:bg-gray-500/30'
          : 'hover:bg-gray-50 bg-white dark:bg-gray-850 dark:hover:bg-gray-800'
      )}
    >
      <div className="leading-5 flex-1 relative text-xs truncate">
        <NextPureLinkHelper
          text={taskMemberInvite.bitTask.name}
          href={TaskPath.show(taskMemberInvite.bitTask.nanoId)}
          className="focus:outline-none text-sm font-medium text-gray-800 dark:text-gray-200 hover:underline"
        />

        <div className="truncate font-medium text-gray-600 dark:text-gray-400">
          {taskMemberInvite.bitTask.project.name}
        </div>
      </div>
      {withActions && (
        <div className="shrink-0 flex gap-x-px">
          {draftSmartContract?.smartContractType ===
            SmartContractSmartContractTypes.RULES && (
            <CheckPermissions
              action={
                TaskMemberInvitesPermissions.READ_CHECK_IN_TASK_MEMBER_INVITE_BUTTON
              }
            >
              <CheckInTasksSmartContractRuleModalButton
                cacheKeys={cacheKeys}
                className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                tasks={[taskMemberInvite.bitTask]}
                tooltipI18nText={tasksKeys.buttons.checkIn}
                icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
                iconClassName="h-5 w-5"
              />
            </CheckPermissions>
          )}

          {draftSmartContractWithInvited?.smartContractType ===
            SmartContractSmartContractTypes.SHARES &&
            smartContractShareId && (
              <CheckPermissions
                action={
                  TaskMemberInvitesPermissions.READ_CHECK_IN_SMART_CONTRACT_SHARE_TASK_MEMBER_INVITE_BUTTON
                }
              >
                <CheckInSmartContractShareButton
                  smartContractShareId={smartContractShareId}
                  cacheKeys={cacheKeys}
                  className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  tooltipI18nText={tasksKeys.buttons.checkIn}
                  icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
                  iconClassName="h-5 w-5"
                />
              </CheckPermissions>
            )}

          <CheckPermissions
            action={
              TaskMemberInvitesPermissions.READ_ACCEPT_TASK_MEMBER_INVITE_BUTTON
            }
          >
            <AcceptInviteToTaskButton
              taskNanoId={taskMemberInvite.bitTask.nanoId}
              className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              icon={IconsEnum.CHECK}
              cacheKeys={cacheKeys}
              tooltipI18nText={taskMemberInvitesKeys.accept}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              TaskMemberInvitesPermissions.READ_DECLINE_TASK_MEMBER_INVITE_BUTTON
            }
          >
            <DeclineTaskMemberInviteButton
              className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              uuid={taskMemberInvite.uuid}
              cacheKeys={cacheKeys}
              tooltipI18nText={taskMemberInvitesKeys.decline}
            />
          </CheckPermissions>
        </div>
      )}
    </li>
  );
}

export default TaskMemberInvitesListItem;
