import React, { useMemo } from 'react';
import round from 'lodash/round';
import toNumber from 'lodash/toNumber';

import {
  UserClient,
  UserCurrentTeamNanoID,
  UserID,
  UserNanoID,
  UserUUID,
  UserWorkExperience
} from '../../../../../usersTypes';
import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import {
  FETCH_USER_PROFILE_SLOW_FIELDS,
  FetchUserProfileSlowFieldsQueryResponse
} from '../../../../../queries/fetchUserProfileSlowFields.query';
import {
  FETCH_TEAM_REVENUE_QUERY,
  FetchTeamRevenueQueryResponse
} from '../../../../../../teams/queries/fetchTeamRevenue.query';

import { useUser } from '../../../../../hooks/useUser';
import { useFinTeam } from '../../../../../../teams/hooks/useFinTeam';

import { DashboardStatisticMyProjects } from './components/DashboardStatisticMyProjects';
import { DashboardStatisticMyTasks } from './components/DashboardStatisticMyTasks';
import { DashboardStatisticMyResults } from './components/DashboardStatisticMyResults';
import { DashboardStatisticMyMessages } from './components/DashboardStatisticMyMessages';
import { DashboardStatisticItem } from './components/DashboardStatisticItem';
import { DashboardStatisticTotalHoursDay } from './components/DashboardStatisticTotalHoursDay';
import { DashboardStatisticRevenue } from './components/DashboardStatisticRevenue';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../../../helpers/Translate';

import { UsersPermissions } from '../../../../../usersConstants';

import { UserCache } from '../../../../../UserCache';
import { TeamCache } from '../../../../../../teams/TeamCache';

import { profilesKeys } from '../../../../../../../locales/keys';

import { IconsEnum } from '../../../../../../../assets/icons/types';

interface DashboardStatisticUser {
  id: UserID;
  nanoId: UserNanoID;
  uuid: UserUUID;
  client: UserClient;
  workExperience: UserWorkExperience;
  currentTeam: {
    nanoId: UserCurrentTeamNanoID;
  };
}

interface DashboardStatisticProps {
  user: DashboardStatisticUser;
  selfProfile: boolean;
  teamNanoId?: TeamNanoID;
}

function DashboardStatistic({
  selfProfile,
  user,
  teamNanoId
}: DashboardStatisticProps) {
  const { user: userSlowFields, userFetched: userSlowFieldsFetched } =
    useUser<FetchUserProfileSlowFieldsQueryResponse>({
      cacheKey: UserCache.showProfileSlowFieldsCacheKey(user.nanoId),
      query: FETCH_USER_PROFILE_SLOW_FIELDS,
      uuid: user.nanoId
    });

  const { team, teamFetched } = useFinTeam<FetchTeamRevenueQueryResponse>({
    cacheKey: TeamCache.showCompanyRevenueCacheKey(),
    query: FETCH_TEAM_REVENUE_QUERY,
    uuid: teamNanoId ? teamNanoId : user.currentTeam?.nanoId
  });

  const mar = useMemo<number>(
    () =>
      team?.revenue
        ? round(
            (team?.revenue - team?.revenueReverse) /
              (toNumber(user.workExperience) || 1)
          )
        : 0,
    [team, user]
  );

  return (
    <div>
      <div className="flex gap-2 mb-4">
        <div className="uppercase dark:text-gray-300">
          <Translate id={profilesKeys.statistic} />
        </div>

        <div className="flex-1 relative flex items-center">
          <hr className="w-full dark:border-gray-800" />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_RESULTS_COUNT_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_RESULTS_COUNT_FIELD
          }
        >
          <DashboardStatisticMyResults
            selfProfile={selfProfile}
            userId={user.id}
            userNanoId={user.nanoId}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_EVALUATIONS_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_EVALUATIONS_FIELD
          }
        >
          <DashboardStatisticItem
            i18nTitle={profilesKeys.evaluations}
            icon={IconsEnum.EMPTY_STAR}
            loaded={userSlowFieldsFetched}
            value={userSlowFields?.averageTasksMark || 0}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_COUNT_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_TASKS_COUNT_FIELD
          }
        >
          <DashboardStatisticMyTasks
            selfProfile={selfProfile}
            userId={user.id}
            userNanoId={user.nanoId}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_PROJECTS_COUNT_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_PROJECTS_COUNT_FIELD
          }
        >
          <DashboardStatisticMyProjects
            selfProfile={selfProfile}
            userId={user.id}
            userNanoId={user.nanoId}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_MESSAGES_COUNT_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_MESSAGES_COUNT_FIELD
          }
        >
          <DashboardStatisticMyMessages
            selfProfile={selfProfile}
            userNanoId={user.nanoId}
            userUuid={user.uuid}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_TOTAL_HOURS_DAY_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_TOTAL_HOURS_DAY_FIELD
          }
        >
          <DashboardStatisticTotalHoursDay
            selfProfile={selfProfile}
            userNanoId={user.nanoId}
            userUuid={user.uuid}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_MONTHLY_AVERAGE_REVENUE_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_MONTHLY_AVERAGE_REVENUE_FIELD
          }
        >
          <DashboardStatisticItem
            i18nTitle={profilesKeys.mar}
            loaded={teamFetched}
            value={mar}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_REVENUE_FIELD
              : UsersPermissions.READ_USER_DASHBOARD_REVENUE_FIELD
          }
        >
          <DashboardStatisticRevenue
            selfProfile={selfProfile}
            team={team}
            teamFetched={teamFetched}
          />
        </CheckPermissions>
      </div>
    </div>
  );
}

export default DashboardStatistic;
