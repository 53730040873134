import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import {
  SmartContractItemID,
  SmartContractItemItemCategoryName
} from '../../../../../../../../../../smartContracts/smartContractsTypes';

import { DashboardFinanceSmartContractsItem } from '../DashboardFinanceSmartContractsItem';

import { NextPureLinkHelper } from '../../../../../../../../../../../helpers/links/NextPureLinkHelper';
import { LoadingSkeleton } from '../../../../../../../../../../../helpers/LoadingSkeleton';

import { words } from '../../../../../../../../../../../locales/keys';

interface DashboardFinanceSmartContractsQuantityItemType {
  id: SmartContractItemID;
  itemCategoryName: SmartContractItemItemCategoryName;
}

interface DashboardFinanceSmartContractsQuantityProps {
  items: DashboardFinanceSmartContractsQuantityItemType[];
  loaded: boolean;
  href: string;
}

function DashboardFinanceSmartContractsQuantity({
  items,
  loaded,
  href
}: DashboardFinanceSmartContractsQuantityProps) {
  const itemsQuantity = useMemo<number>(() => size(items), [items]);

  const itemsGroupedByCategory = useMemo<
    DashboardFinanceSmartContractsQuantityItemType[][]
  >(() => reverse(sortBy(groupBy(items, 'itemCategoryName'))), [items]);

  const allMultiplier = useMemo<number>(() => {
    const maxGroupQuantity = size(itemsGroupedByCategory?.[0]);
    return maxGroupQuantity / itemsQuantity;
  }, [itemsGroupedByCategory, itemsQuantity]);

  return (
    <div className="grow flex flex-col-reverse 2xl:flex-row justify-between gap-2 2xl:gap-6">
      <LoadingSkeleton loaded={loaded} addClass="w-full" count={3}>
        <div className="flex 2xl:flex-col justify-between gap-4">
          <div>
            <div className="text-3xl font-medium">{itemsQuantity}</div>
          </div>

          <div className="self-end 2xl:self-start">
            <NextPureLinkHelper
              className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-gray-500 hover:bg-gray-600 shadow-sm hover:shadow-md"
              i18nText={words.viewAll}
              href={href}
            />
          </div>
        </div>

        <div className="flex-1 max-w-96">
          <div className="grid grid-cols-[auto_auto_1fr] gap-y-0.5 gap-x-2">
            {itemsGroupedByCategory.map((groupItems) => {
              const groupItemsQuantity = size(groupItems);
              const groupItemsMultiplier = groupItemsQuantity / itemsQuantity;

              return (
                <DashboardFinanceSmartContractsItem
                  itemCategoryName={groupItems[0].itemCategoryName}
                  quantity={groupItemsQuantity}
                  percent={round(groupItemsMultiplier * 100)}
                  width={round((groupItemsMultiplier / allMultiplier) * 100)}
                  key={groupItems[0].itemCategoryName}
                />
              );
            })}
          </div>
        </div>
      </LoadingSkeleton>
    </div>
  );
}

export default DashboardFinanceSmartContractsQuantity;
