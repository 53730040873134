import { ItemStatus } from '../../types';

function statusColor(status: ItemStatus | null): string {
  switch (status) {
    case 'acceptance':
      return 'bg-green-100 text-green-800 dark:text-green-50 dark:bg-green-900 dark:bg-opacity-50';
    case 'canceled':
      return 'bg-red-100 text-red-800 dark:text-red-50 dark:bg-red-900 dark:bg-opacity-50';
    case 'definition':
      return 'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700 dark:bg-opacity-50';
    case 'implementation':
      return 'bg-blue-100 text-blue-800 dark:text-blue-50 dark:bg-blue-900 dark:bg-opacity-50';
    case 'incomplete_specification':
      return 'bg-yellow-100 text-yellow-800 dark:text-yellow-50 dark:bg-yellow-900 dark:bg-opacity-50';
    case 'paused':
      return 'bg-pink-100 text-pink-800 dark:text-pink-50 dark:bg-pink-900 dark:bg-opacity-50';
    case 'performed':
      return 'bg-transparent text-gray-800 dark:text-gray-50';
    case 'qa':
      return 'bg-purple-100 text-purple-800 dark:text-purple-50 dark:bg-purple-900 dark:bg-opacity-50';
    default:
      return 'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700 dark:bg-opacity-50';
  }
}

export default statusColor;
