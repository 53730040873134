import { gql } from 'graphql-request';

import {
  TaskReportUUID,
  TaskReportCreatedAt,
  TaskReportID,
  TaskReportTaskNanoID,
  TaskReportTaskUuid,
  TaskReportTaskID,
  TaskReportUpdatedAt,
  TaskReportType,
  TaskReportTaskStatus,
  TaskReportTaskType
} from '../taskReportsTypes';

export interface FetchTaskReportsQueryResponse {
  id: TaskReportID;
  uuid: TaskReportUUID;
  createdAt: TaskReportCreatedAt;
  updatedAt: TaskReportUpdatedAt;
  type: TaskReportType;
  task: {
    id: TaskReportTaskID;
    uuid: TaskReportTaskUuid;
    nanoId: TaskReportTaskNanoID;
    status: TaskReportTaskStatus;
    type: TaskReportTaskType;
  };
}

export const FETCH_TASK_REPORTS_QUERY = gql`
  query TaskReports(
    $filters: TaskReportsFilters
    $sort: [TaskReportsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    taskReports(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        createdAt
        id
        uuid
        type
        updatedAt
        task {
          id
          uuid
          nanoId
          status
          type
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
