import {
  FetchTaskReportsFilters,
  FetchTaskReportsSort,
  FetchTaskReportsPage,
  FetchTaskReportsLimit,
  FetchTaskReportsGqlQuery,
  FetchTaskReportsCacheKey
} from '../../taskReportsTypes';

import {
  INITIAL_TASK_REPORTS_FILTERS,
  INITIAL_TASK_REPORTS_SORT,
  INITIAL_TASK_REPORTS_PAGE,
  INITIAL_TASK_REPORTS_LIMIT
} from '../../taskReportsConstants';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

interface TaskReportsOptions {
  cacheKey: FetchTaskReportsCacheKey;
  query: FetchTaskReportsGqlQuery;
  initialFilters?: FetchTaskReportsFilters;
  initialSort?: FetchTaskReportsSort;
  initialPage?: FetchTaskReportsPage;
  initialLimit?: FetchTaskReportsLimit;
  options?: {
    cacheTime?: number;
    withoutPrefetch?: boolean;
  };
}

const scope = 'taskReports';

function useTaskReports<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TASK_REPORTS_FILTERS,
  initialSort = INITIAL_TASK_REPORTS_SORT,
  initialPage = INITIAL_TASK_REPORTS_PAGE,
  initialLimit = INITIAL_TASK_REPORTS_LIMIT,
  options = {}
}: TaskReportsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    changeItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope
  });

  return {
    taskReportsData: data,
    taskReports: items,
    taskReportsError: itemsError,
    taskReportsTotalCount: itemsTotalCount,
    taskReportsFetched: isFetched,
    taskReportsLoading: isLoading,
    taskReportsIsPlaceholderData: isPlaceholderData,
    taskReportsFilters: currentFilters,
    taskReportsSort: currentSort,
    taskReportsPage: currentPage,
    taskReportsLimit: currentLimit,
    filterTaskReports: filterItems,
    changeTaskReportsFilters: changeItemsFilters,
    clearTaskReportsFilters: clearItemsFilters,
    sortTaskReports: sortItems,
    paginateTaskReports: paginateItems,
    limitTaskReports: limitItems,
    prefetchTaskReports: prefetchItems
  };
}

export default useTaskReports;
