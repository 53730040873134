import { ProjectNanoID } from '../../../projects/projectsTypes';

import {
  CreateMessageWithProjectNanoIdGqlQuery,
  CreateMessageWithProjectNanoIdGqlStatus,
  MessageGqlError,
  MessageUUID,
  MessageBody,
  MessageProjectNanoID,
  MessageVisibleForClient,
  MessageFileAttachmentIDs,
  MessageFileAttachmentID,
  MessageSelectedProductIDs,
  MessageSelectedLifestyleIDs,
  MessageSelectedMaterialIDs,
  MessageColors,
  CreateMessageWithProjectNanoIdCacheKeys,
  MessageWhiteboardKeyID,
  MessageThreadKeyID,
  MessageThreadStart,
  MessageMentionedUserIDs,
  MessageRed,
  MessageMuted,
  MessageMutedForClientsAt,
  MessageID,
  MessageMessageType
} from '../../messagesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { ProjectCache } from '../../../projects/ProjectCache';

interface CreateMessageWithProjectNanoIdInput {
  body: MessageBody;
  colors?: MessageColors;
  fileAttachmentId?: MessageFileAttachmentID;
  fileAttachmentIds?: MessageFileAttachmentIDs;
  mentionedUserIds?: MessageMentionedUserIDs;
  messageType?: MessageMessageType;
  muted?: MessageMuted;
  mutedForClientsAt?: MessageMutedForClientsAt | null;
  projectNanoId: MessageProjectNanoID;
  red?: MessageRed;
  selectedLifestyleIds?: MessageSelectedLifestyleIDs;
  selectedProductIds?: MessageSelectedProductIDs;
  selectedMaterialIds?: MessageSelectedMaterialIDs;
  threadKeyId?: MessageThreadKeyID;
  threadStart?: MessageThreadStart;
  visibleForClient: MessageVisibleForClient;
  whiteboardKeyId?: MessageWhiteboardKeyID;
  repliedMessageId?: MessageID;
}

interface CreateMessageWithProjectNanoIdResponse<
  CreateMessageWithProjectNanoIdRecordType
> {
  createMessageWithProjectNanoId: {
    status: CreateMessageWithProjectNanoIdGqlStatus;
    recordUuid: MessageUUID;
    record: CreateMessageWithProjectNanoIdRecordType;
    errors: CreateMessageWithProjectNanoIdErrors;
  };
}

interface CreateMessageWithProjectNanoIdErrors {
  done: MessageGqlError;
  fullMessages: MessageGqlError;
  name: MessageGqlError;
  projectNanoId: MessageGqlError;
  visibleForClients: MessageGqlError;
}

interface CreateMessageWithProjectNanoIdOptions {
  cacheKeys?: CreateMessageWithProjectNanoIdCacheKeys;
  projectNanoId?: ProjectNanoID;
  query: CreateMessageWithProjectNanoIdGqlQuery;
}

const action = 'createMessageWithProjectNanoId';

function useCreateMessageWithProjectNanoId<
  CreateMessageWithProjectNanoIdRecordType
>({ cacheKeys, projectNanoId, query }: CreateMessageWithProjectNanoIdOptions) {
  const defaultCacheKeys = projectNanoId
    ? [
        ProjectCache.messagesCacheKey(projectNanoId),
        ProjectCache.sidebarMediaCacheKey(projectNanoId),
        ProjectCache.imagesCacheKey(projectNanoId)
      ]
    : undefined;

  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateMessageWithProjectNanoIdInput,
    CreateMessageWithProjectNanoIdResponse<CreateMessageWithProjectNanoIdRecordType>,
    CreateMessageWithProjectNanoIdErrors,
    CreateMessageWithProjectNanoIdRecordType
  >({
    action,
    cacheKeys: cacheKeys || defaultCacheKeys,
    query
  });

  return {
    createMessageWithProjectNanoIdData: createQueryData,
    createMessageWithProjectNanoIdError: createQueryError,
    createMessageWithProjectNanoIdLoading: createQueryLoading,
    createMessageWithProjectNanoIdErrorMessage: createQueryErrorMessage,
    createMessageWithProjectNanoId: createQuery,
    createMessageWithProjectNanoIdReset: createQueryReset
  };
}

export default useCreateMessageWithProjectNanoId;
