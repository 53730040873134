import React from 'react';

import {
  InvoiceReturnReasons,
  InvoiceStatuses
} from '../../../../invoicesTypes';

interface InvoicesPaymentsPartsProps {
  status?: InvoiceStatuses;
  width: number;
}

const getInvoiceStatusColor = (status?: string) => {
  switch (status) {
    case InvoiceStatuses.CANCELED:
      return 'bg-red-50';
    case InvoiceStatuses.DRAFT:
      return 'bg-gray-500';
    case InvoiceStatuses.OPEN:
      return 'bg-yellow-500';
    case InvoiceStatuses.PAID:
      return 'bg-green-500';
    case InvoiceStatuses.SENT:
      return 'bg-blue-400';
    case InvoiceStatuses.UNCOLLECTIBLE:
      return 'bg-red-500';
    case InvoiceStatuses.VOID:
      return 'bg-gray-500';
    case InvoiceStatuses.NONE:
      return 'bg-gray-500';
    case InvoiceReturnReasons.REFUND:
      return 'bg-red-500';
    case InvoiceReturnReasons.CHARGEBACK:
      return 'bg-red-500';
    default:
      return 'bg-gray-500 opacity-50';
  }
};

function InvoicesPaymentsParts({ status, width }: InvoicesPaymentsPartsProps) {
  return (
    <div
      className={`h-full box-border ${getInvoiceStatusColor(status)}`}
      style={{ width: `${width}%` }}
    />
  );
}

export default InvoicesPaymentsParts;
