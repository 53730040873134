import { gql } from 'graphql-request';

import {
  AccountBalanceAccountGeneralLedgerId,
  AccountBalanceAccountId,
  AccountBalanceAccountIncreaseSide,
  AccountBalanceAccountNanoId,
  AccountBalanceAccountTypeName,
  AccountBalanceAmount,
  AccountBalanceBalance,
  AccountBalanceCreatedAt,
  AccountBalanceDate,
  AccountBalanceID,
  AccountBalanceNanoID,
  AccountBalancePrevBalance,
  AccountBalanceUUID
} from '../accountBalancesTypes';
import {
  AccountAccountTypeChartNumber,
  AccountAccountTypeNanoID,
  AccountBalance
} from '../../accounts/accountsTypes';

export interface FetchAccountBalancesQueryResponse {
  id: AccountBalanceID;
  uuid: AccountBalanceUUID;
  nanoId: AccountBalanceNanoID;
  amount: AccountBalanceAmount;
  date: AccountBalanceDate;
  createdAt: AccountBalanceCreatedAt;
  balance: AccountBalanceBalance;
  prevBalance: AccountBalancePrevBalance;
  type: AccountBalanceAccountTypeName;
  accountId: AccountBalanceAccountId;
  account: {
    id: AccountBalanceAccountId;
    accountTypeId: AccountAccountTypeNanoID;
    nanoId: AccountBalanceAccountNanoId;
    balance: AccountBalance;
    increaseSide: AccountBalanceAccountIncreaseSide;
    generalLedgerId: AccountBalanceAccountGeneralLedgerId;
    accountType: {
      chartNumber: AccountAccountTypeChartNumber;
    };
  };
}

export const FETCH_ACCOUNT_BALANCES_QUERY = gql`
  query AccountBalances(
    $filters: AccountBalancesFilters
    $limit: Int
    $offset: Int
    $sort: [AccountBalancesSortEnum!]
  ) {
    accountBalances(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        amount
        date
        balance
        prevBalance
        type
        accountId
        account {
          accountTypeId
          id
          nanoId
          balance
          increaseSide
          generalLedgerId
          accountType {
            chartNumber
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
