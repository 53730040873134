import { ProjectNanoID } from '../projects/projectsTypes';
import { TaskNanoID } from '../tasks/tasksTypes';

export class TaskProcessingJobCache {
  static projectTaskProcessingJobCacheKey(projectNanoId: ProjectNanoID) {
    return `project_task_processing_job_${projectNanoId}`;
  }

  static taskTaskProcessingJobCacheKey(taskNanoId: TaskNanoID) {
    return `task_task_processing_job_${taskNanoId}`;
  }
}
