import React from 'react';

import { I18nText, ClassName } from '../../../../types';

import { UserLink, UserLinkLinkType } from '../UserLink';

import { Translate } from '../../../../helpers/Translate';

import { UserPureLinkUser } from './UserPureLink.types';

interface UserPureLinkProps {
  user: UserPureLinkUser;
  className?: ClassName;
  linkType?: UserLinkLinkType;
  i18nText: I18nText;
}

function UserPureLink({
  className,
  user,
  linkType,
  i18nText
}: UserPureLinkProps) {
  return (
    <UserLink
      user={user}
      className={className}
      linkType={linkType}
      withoutTitle
    >
      <Translate id={i18nText} />
    </UserLink>
  );
}

export default UserPureLink;
