import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import differenceInDays from 'date-fns/differenceInDays';
import isSameDay from 'date-fns/isSameDay';
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import isSameMonth from 'date-fns/isSameMonth';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';

import {
  AccountBalanceBalance,
  AccountBalanceDate,
  AccountBalanceID,
  AccountBalancePrevBalance
} from '../../../../../../../../../../accountBalances/accountBalancesTypes';
import {
  AccountAccountTypeChartNumber,
  AccountBalance
} from '../../../../../../../../../../accounts/accountsTypes';

import { DashboardFinanceBalancesAccountTypeChartNumber } from '../../DashboardFinanceBalancesChart.types';

import { dateFnsConvert } from '../../../../../../../../../../../utils/dateFnsConvert';

type Account = {
  balance: AccountBalance;
  accountType: {
    chartNumber: AccountAccountTypeChartNumber;
  };
};

export interface AccountBalanceDataType {
  id: AccountBalanceID;
  date: AccountBalanceDate;
  balance: AccountBalanceBalance;
  prevBalance: AccountBalancePrevBalance;
  account: Account;
}

type DataType = {
  x: string;
  y: number;
};

interface GetAccountBalancesData {
  accounts: Account[];
  accountBalances: AccountBalanceDataType[];
  chartNumbers: DashboardFinanceBalancesAccountTypeChartNumber[];
  startDate: Date;
}

function getAccountBalanceClosestData(
  accountBalances: AccountBalanceDataType[],
  date: Date,
  prevBalance = 0
): number {
  const filteredAccountBalances = filter(accountBalances, (accountBalance) =>
    isSameMonth(date, new Date(accountBalance.date))
  );

  if (isEmpty(filteredAccountBalances)) {
    return prevBalance;
  }

  const nearestDate = head(
    sortBy(filteredAccountBalances, (accountBalance) =>
      Math.abs(differenceInDays(date, new Date(accountBalance.date)))
    )
  );

  return new Date(nearestDate.date).getDate() > date.getDate()
    ? nearestDate.prevBalance
    : nearestDate.balance;
}

function getAccountBalancesData({
  accounts,
  accountBalances,
  chartNumbers,
  startDate
}: GetAccountBalancesData): DataType[] {
  const period = eachDayOfInterval({ start: startDate, end: new Date() });
  const defaultBalance = sumBy(accounts, (account) =>
    some(
      chartNumbers,
      (chartNumber) =>
        account.accountType.chartNumber === chartNumber &&
        account.balance !== null
    )
      ? account.balance
      : 0
  );

  if (isEmpty(accountBalances)) {
    return map(period, (date) => ({
      x: dateFnsConvert.toCustomFormatDate('d MMM', date.toISOString()),
      y: defaultBalance
    }));
  }

  return map(period, (date) => {
    const chartNumberAccountBalance = filter(
      accountBalances,
      (accountBalance) =>
        some(
          chartNumbers,
          (chartNumber) =>
            accountBalance.account.accountType.chartNumber === chartNumber
        )
    );

    const accountBalance = find(chartNumberAccountBalance, (accountBalance) =>
      isSameDay(date, new Date(accountBalance.date))
    );

    const balance = accountBalance
      ? accountBalance.balance
      : getAccountBalanceClosestData(
          chartNumberAccountBalance,
          date,
          defaultBalance
        );

    return {
      x: dateFnsConvert.toCustomFormatDate('d MMM', date.toISOString()),
      y: balance
    };
  });
}

export default getAccountBalancesData;
