import { TaskNanoID } from '../tasks/tasksTypes';
import { TeamNanoID } from '../teams/teamsTypes';
import { ProjectNanoID } from '../projects/projectsTypes';
import { InvoiceID } from '../invoices/invoicesTypes';
import { SmartContractNanoID } from './smartContractsTypes';

export class SmartContractCache {
  static showCacheKey() {
    return `smart-contract`;
  }

  static smartContractSharesInvoicesAmountsCacheKey() {
    return `smart-contract-shares-invoices-amounts`;
  }

  static showTypeCacheKey() {
    return `smart-contracts-type`;
  }

  static showQueryKey(smartContractNanoId: SmartContractNanoID) {
    return ['smart-contract', smartContractNanoId];
  }

  static taskSmartContractsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-smart-contracts`;
  }

  static taskPaymentsSmartContractsCacheKey(taskNanoId: TaskNanoID) {
    return `task-payments-${taskNanoId}-smart-contracts`;
  }

  static taskSmartContractsSharesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-smart-contracts-shares`;
  }

  static projectSmartContractsSharesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-smart-contracts-shares`;
  }

  static paymentsSmartContractsCacheKey() {
    return `payments-smart-contracts`;
  }

  static paymentsDraftSmartContractsCacheKey() {
    return `payments-draft-smart-contracts`;
  }

  static paymentsCanceledSmartContractsCacheKey() {
    return `payments-canceled-smart-contracts`;
  }

  static companyPaymentsSmartContractsCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-payments-smart-contracts`;
  }

  static companyPaymentsDraftSmartContractsCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-payments-draft-smart-contracts`;
  }

  static companyPaymentsCanceledSmartContractsCacheKey(
    companyNanoId: TeamNanoID
  ) {
    return `company-${companyNanoId}-payments-canceled-smart-contracts`;
  }

  static proformaInvoiceSmartContractsCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-proforma-smart-contracts`;
  }

  static projectProformaInvoiceSmartContractsCacheKey(
    companyNanoId: TeamNanoID,
    projectNanoId: ProjectNanoID
  ) {
    return `project-${projectNanoId}-proforma-${companyNanoId}-smart-contracts`;
  }

  static projectSmartContractsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-smart-contracts`;
  }

  static invoiceSmartContractsCacheKey(invoiceId: InvoiceID) {
    return `invoice-${invoiceId}-smart-contracts`;
  }

  static invoiceMySmartContractsCacheKey(invoiceId: InvoiceID) {
    return `invoice-${invoiceId}-my-smart-contracts`;
  }

  static dashboardSmartContractsCacheKey() {
    return `dashboard-smart-contracts`;
  }

  static checkInTasksSmartContractsCacheKey() {
    return `check-in-tasks-smart-contracts`;
  }
}
