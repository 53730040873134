import { IconsEnum } from '../../assets/icons/types';
import { itemsKeys } from '../../locales/keys';
import {
  FetchGroupedItemsSortTypes,
  GroupedItemBindingStatuses,
  GroupedItemsGroupBy
} from './groupedItemsTypes';

export const INITIAL_GROUPED_ITEMS_FILTERS = {};
export const INITIAL_GROUPED_ITEMS_PAGE = 1;
export const INITIAL_GROUPED_ITEMS_SORT = [
  FetchGroupedItemsSortTypes.ITEM_CREATED_AT_DESC
];
export const INITIAL_GROUPED_ITEMS_LIMIT = 100;

export const groupedItemsGroupByLocalStorageKey = 'grouped-items-group-by';

export const defaultGroupedItemsGroupByState = {
  [GroupedItemsGroupBy.PROJECT]: false,
  [GroupedItemsGroupBy.TASK]: false,
  [GroupedItemsGroupBy.ITEM]: false,
  // [GroupedItemsGroupBy.ITEM_TAG]: false,
  [GroupedItemsGroupBy.ITEM_TAGS]: false,
  [GroupedItemsGroupBy.DATE]: false,
  [GroupedItemsGroupBy.SPLIT]: false
} as Record<GroupedItemsGroupBy, boolean>;

export const noToggleSameTime = [
  GroupedItemsGroupBy.ITEM,
  GroupedItemsGroupBy.ITEM_TAGS
  // GroupedItemsGroupBy.ITEM_TAG
];

export const bindingStatusI18nTexts = {
  [GroupedItemBindingStatuses.BINDED]: undefined,
  [GroupedItemBindingStatuses.UNBINDED]: itemsKeys.bindingStatuses.postponed,
  [GroupedItemBindingStatuses.MIXED]: itemsKeys.bindingStatuses.mixed
};

export const bindingStatusIcons = {
  [GroupedItemBindingStatuses.BINDED]: undefined,
  [GroupedItemBindingStatuses.UNBINDED]: IconsEnum.CIRCLE_SOLID,
  [GroupedItemBindingStatuses.MIXED]: IconsEnum.CIRCLE_OUTLINE
};
