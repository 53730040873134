import sumBy from 'lodash/sumBy';
import floor from 'lodash/floor';

import { TrackedTimeIntervalFullTime } from '../../trackedTimeIntervalsTypes';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

interface TrackedTimeInterval {
  fullTime: TrackedTimeIntervalFullTime;
}

type TrackedTimeIntervals = TrackedTimeInterval[];

function getTotalTime(trackedTimeIntervals: TrackedTimeIntervals) {
  const totalFullTime = sumBy(trackedTimeIntervals, 'fullTime');

  return {
    hours: floor(totalFullTime / SECONDS_IN_HOUR),
    minutes: floor((totalFullTime % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE)
  };
}

export default getTotalTime;
