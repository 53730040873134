import React, { useMemo } from 'react';

import {
  I18nText,
  UpdateItemCacheKeys
} from '../../../../../../../../../../../types';
import { DashboardActiveTasksListTabNames } from '../../DashboardActiveTasksList.types';

import { DashboardActiveTasksListTabsButton } from '../DashboardActiveTasksListTabsButton';
import { AcceptAllTaskMemberInvitesButton } from '../../../../../../../../../../taskMemberInvites/components/buttons/AcceptAllTaskMemberInvitesButton';
import { DeclineAllTaskMemberInvitesButton } from '../../../../../../../../../../taskMemberInvites/components/buttons/DeclineAllTaskMemberInvitesButton';
import {
  CheckInTasksModalButton,
  CheckInTasksModalButtonTask
} from '../../../../../../../../../../tasks/components/modalButtons/CheckInTasksModalButton';

import { CheckPermissions } from '../../../../../../../../../../../helpers/CheckPermissions';

import { UsersPermissions } from '../../../../../../../../../usersConstants';
import { TaskMemberInvitesPermissions } from '../../../../../../../../../../taskMemberInvites/taskMemberInvitesConstants';
import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import {
  profilesKeys,
  taskMemberInvitesKeys
} from '../../../../../../../../../../../locales/keys';

interface DashboardActiveTasksListTabsProps {
  activeTab: DashboardActiveTasksListTabNames;
  changeTab: (tab: DashboardActiveTasksListTabNames) => void;
  activeTasksCount?: number;
  invitationsCount?: number;
  invitationsTasks: CheckInTasksModalButtonTask[];
  qualityControlTasksTotalCount?: number;
  selfProfile: boolean;
  cacheKeys?: UpdateItemCacheKeys;
}

type DashboardActiveTasksListTab = {
  name: DashboardActiveTasksListTabNames;
  i18nText: I18nText;
  action: UsersPermissions;
  count: number;
};

function DashboardActiveTasksListTabs({
  activeTab,
  changeTab,
  activeTasksCount,
  invitationsCount,
  invitationsTasks,
  qualityControlTasksTotalCount,
  selfProfile,
  cacheKeys
}: DashboardActiveTasksListTabsProps) {
  const tabs = useMemo<DashboardActiveTasksListTab[]>(
    () => [
      {
        i18nText: profilesKeys.activeTasks,
        name: DashboardActiveTasksListTabNames.ACTIVE_TASKS,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_ACTIVE_TASKS_BLOCK_ACTIVE_TASKS_TAB
          : UsersPermissions.READ_USER_DASHBOARD_ACTIVE_TASKS_BLOCK_ACTIVE_TASKS_TAB,
        count: activeTasksCount || 0
      },
      {
        i18nText: profilesKeys.invitations,
        name: DashboardActiveTasksListTabNames.INVITATIONS,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_ACTIVE_TASKS_BLOCK_INVITATION_TAB
          : UsersPermissions.READ_USER_DASHBOARD_ACTIVE_TASKS_BLOCK_INVITATION_TAB,
        count: invitationsCount || 0
      },
      {
        i18nText: profilesKeys.qualityControl,
        name: DashboardActiveTasksListTabNames.QUALITY_CONTROL,
        action: selfProfile
          ? UsersPermissions.READ_SELF_DASHBOARD_ACTIVE_TASKS_BLOCK_QUALITY_CONTROL_TAB
          : UsersPermissions.READ_USER_DASHBOARD_ACTIVE_TASKS_BLOCK_QUALITY_CONTROL_TAB,
        count: qualityControlTasksTotalCount || 0
      }
    ],
    [
      activeTasksCount,
      invitationsCount,
      qualityControlTasksTotalCount,
      selfProfile
    ]
  );

  return (
    <div className="flex justify-between items-start">
      <div className="flex flex-wrap -m-1">
        {tabs.map((tab) => (
          <CheckPermissions action={tab.action} key={tab.name}>
            <DashboardActiveTasksListTabsButton
              active={tab.name === activeTab}
              name={tab.name}
              i18nText={tab.i18nText}
              changeTab={changeTab}
              count={tab.count}
            />
          </CheckPermissions>
        ))}
      </div>

      {selfProfile &&
        invitationsCount > 0 &&
        activeTab === DashboardActiveTasksListTabNames.INVITATIONS && (
          <div className="flex shrink-0 items-center gap-x-px">
            <CheckPermissions
              action={
                TaskMemberInvitesPermissions.READ_CHECK_IN_TASK_MEMBER_INVITE_BUTTON
              }
            >
              <CheckInTasksModalButton
                cacheKeys={cacheKeys}
                className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                tasks={invitationsTasks}
                tooltipI18nText={taskMemberInvitesKeys.checkInAll}
                icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
                iconClassName="h-6 w-6"
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                TaskMemberInvitesPermissions.READ_ACCEPT_ALL_TASK_MEMBER_INVITES_BUTTON
              }
            >
              <AcceptAllTaskMemberInvitesButton
                className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.CHECK}
                cacheKeys={cacheKeys}
                tooltipI18nText={taskMemberInvitesKeys.acceptAll}
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                TaskMemberInvitesPermissions.READ_DECLINE_ALL_TASK_MEMBER_INVITES_BUTTON
              }
            >
              <DeclineAllTaskMemberInvitesButton
                className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.CROSS}
                iconClassName="h-5 w-5"
                cacheKeys={cacheKeys}
                tooltipI18nText={taskMemberInvitesKeys.declineAll}
              />
            </CheckPermissions>
          </div>
        )}
    </div>
  );
}

export default DashboardActiveTasksListTabs;
