import {
  UUID,
  CreatedAt,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  ID,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  ItemGqlError,
  UpdateItemCacheKeys,
  UpdatedAt,
  DateFilterType,
  TextFilterType,
  IdFilterType
} from '../../types';

import { TaskID, TaskNanoID, TaskStatus, TaskUUID } from '../tasks/tasksTypes';

export type TaskReportID = ID;
export type TaskReportUUID = UUID;
export type TaskReportTrackedTime = string | null;
export type TaskReportCreatedAt = CreatedAt;
export type TaskReportUpdatedAt = UpdatedAt;
export type TaskReportType = string;

export type TaskReportTaskID = TaskID;
export type TaskReportTaskUuid = TaskUUID;
export type TaskReportTaskNanoID = TaskNanoID;
export type TaskReportTaskStatus = TaskStatus;
export type TaskReportTaskType = string;

export interface FetchTaskReportsFilters {
  createdAt?: DateFilterType;
  performerId?: IdFilterType;
  status?: TextFilterType;
  taskId?: IdFilterType;
}

export enum FetchTaskReportsSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export enum FetchTaskReportsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchTaskReportsPage = FetchItemsPage;
export type FetchTaskReportsLimit = FetchItemsLimit;
export type FetchTaskReportsSort = FetchItemsSort;

export type FetchTaskReportsGqlQuery = FetchItemsGqlQuery;
export type UpdateTaskReportsGqlQuery = UpdateItemGqlQuery;
export type UpdateTaskReportsGqlStatus = UpdateItemGqlStatus;
export type UpdateTaskReportsTaskCacheKeys = UpdateItemCacheKeys;

export type TaskReportsGqlError = ItemGqlError;

export type FetchTaskReportsCacheKey = FetchItemsCacheKey;
